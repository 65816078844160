// @ts-nocheck
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5rem;
  height: 1.4rem;
  transition: all 200ms ease;
  z-index: -1;
  
  > div {
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: var(--principal-50);
    transition: all 200ms ease;

    ${({ open }) =>
      open &&
      css`
        display: none;

        &:first-child {
          display: block;
          transform: translateY(9px) rotate(-45deg);
          width: 120%;
        }

        &:last-child {
          display: block;
          transform: translateY(-9px) rotate(45deg);
          width: 120%;
        }
      `}
  }

  @media screen and (max-width: 720px) {
    height: 1.5rem;
  }
`;