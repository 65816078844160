// @ts-nocheck
import { useRef, useState, useCallback, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from 'yup';

import {
    TabContent
} from "styles/components";

import {
    PageHeader,
    Container,
    PageContent,
    Content,
    ContainerInput,
    Text,
    Title,
    Accordion,
    AccordionHeader,
    AccordionContent,
    Questions,
    ContactBoxes,
    Box
} from "./styles";

import { Input } from "../../components/Form";

import Button from "../../components/Button";

import Background from "../../assets/perguntas-frequentes/background.svg";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Header from "../../components/Header";

import { dataPerguntas } from '../../data/dataPerguntas';

import { useAuth } from '../../hooks/auth';

export function PerguntasFrequentes() {
    const formRef = useRef(null);
    const [active, setActive] = useState(null);
    const [search, setSearch] = useState('');
    const [perguntas, setPerguntas] = useState([]);

    const { user } = useAuth();

    function handleAccordion(index) {
        setActive(index);
    }

    function handleChange(e) {
        const { value } = e.target;
        setSearch(value);
    }

    let listaFiltrada = dataPerguntas?.filter(function (value) {
        function removerAcentos(newStringComAcento) {
            var string = newStringComAcento;
            var mapaAcentosHex = {
                a: /[\xE0-\xE6]/g,
                e: /[\xE8-\xEB]/g,
                i: /[\xEC-\xEF]/g,
                o: /[\xF2-\xF6]/g,
                u: /[\xF9-\xFC]/g,
                c: /\xE7/g,
                n: /\xF1/g,
            };
    
            for (var letra in mapaAcentosHex) {
                var expressaoRegular = mapaAcentosHex[letra];
                string = string?.replace(expressaoRegular, letra);
            }
    
            return string;
        }
    
        let semAcento = removerAcentos(value.text);
    
        if (search.match(/^.*[^a-zA-Z 0-9]+.*$/g)) {
            return value.text
                ?.toUpperCase()
                .includes(search?.toUpperCase());
        } else {
            return semAcento
                ?.toUpperCase()
                .includes(search?.toUpperCase());
        }
    });

    const handleSubmit = useCallback(async (data) => {
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                search: Yup.string().required('Busca não preenchida!'),
            });

            await schema.validate(data, {
                abortEarly: false,
            });
        } catch(err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    }, []);

    useEffect(() => {
        if(search) {
            setPerguntas(listaFiltrada);
        } else {
            setPerguntas(dataPerguntas);
        }

        window.Appcues.identify(user.id, user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, user]);

    return (
        <>
            <Container>
                <Header />
                <PageHeader>
                    <img src={Background} alt="Background"/>
                    <Content>
                        <Text>
                            <Title>Como podemos ajudar?</Title>
                        </Text>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <ContainerInput>
                                <Input
                                    type="text"
                                    name="search"
                                    icon={<SearchIcon />}
                                    placeholder="Digite sua dúvida aqui..."
                                    onChange={handleChange}
                                />
                                <hr/>
                                <Button
                                    type="submit"
                                    btType="var(--principal-40)"
                                    color="var(--cinza-100)"
                                    label="Pesquisar"
                                    // margin="0.5rem 0 0 0"
                                    full
                                    width="114px"
                                    padding="10px 0"
                                />
                            </ContainerInput>
                        </Form>
                    </Content>
                </PageHeader>
                <PageContent>
                    <h3>Todas as perguntas</h3>
                    <p>Veja a seguir se alguma das perguntas mais comuns que recebemos responde a sua dúvida.</p>
                    <Questions>
                        { perguntas && perguntas.map((item) => (
                            <Accordion key={item?.id} onClick={() => item?.id === active ? setActive(null) : handleAccordion(item?.id)}>
                                <AccordionHeader active={item?.id === active}>
                                    <p>
                                        {item?.title}
                                    </p>
                                    {item?.id === active ? (
                                        <span onClick={() => setActive(null)}>
                                            <RemoveIcon />
                                        </span>
                                    ) : (
                                        <AddIcon />
                                    )}
                                </AccordionHeader>
                                <AccordionContent active={item?.id === active} dangerouslySetInnerHTML={{ __html: item?.text }} />
                            </Accordion>
                        ))}
                    </Questions>
                    <h3>Ainda com dúvidas?</h3>
                    <p>Você sempre pode entrar em contato com a gente. Vamos entrar em contato o mais rápido possível!</p>
                    <ContactBoxes>
                        <Box>
                            <a href="https://api.whatsapp.com/send?phone=5521990257203&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Vim%20da%20Central%20do%20Cliente%20mas%20continuo%20com%20d%C3%BAvida!%20Poderia%20me%20ajudar%3F"
                            target="_blank"
                            referrer="no-referrer" rel="noreferrer">
                                <WhatsAppIcon />
                                <p>(21) 99025-7203</p>
                                <span>
                                    (WhatsApp)
                                </span>
                            </a>
                        </Box>
                        <Box>
                            <MailOutlineIcon />
                            <p>atendimento.servicos@cidadaniaja.com.br</p>
                        </Box>
                    </ContactBoxes>
                </PageContent>
            </Container>
        </>
    );
}