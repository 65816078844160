// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  PageHeader,
  TabContent,
  TabBody,
  TabHeader,
  Options,
  OptionInfo,
  Option,
} from "styles/components";

import {
  PageContainer,
  WhiteBox,
  CloseButton,
  BoxContent,
  CautionBox,
} from "../../components/Modal/ModalWelcome/styles";

import {
  Container,
  Content,
  Text,
  Title,
  Welcome,
  ContentRequests,
  CardRequests,
  AccordionDescription,
  AccordionStatus,
  AccordionButtons,
  Arrow,
  AccordionDetailHeader,
  AccordionDetailBody,
} from "./styles";

import Header from "../../components/Header";
// import Accordion from "../../components/Accordion";
import Card from "../../components/Card";
import {
  ModalWelcome,
  ModalRequirements,
  ModalAnalysis,
  ModalExigencia,
  ModalFMD,
} from "../../components/Modal";
import Button from "../../components/Button";

import Frame from "../../assets/header/Frame.svg";
import ArrowLongLeft from "../../assets/icons/arrow-long-left.svg";
import ArrowLongRight from "../../assets/icons/arrow-long-right.svg";

import { useAuth } from "../../hooks/auth";
import { useRequirement } from "../../hooks/requirement";
import api from "services/api";
import { useToast } from "hooks/toast";

/* inicio estilos acordeon */
const Accordion = withStyles({
  root: {
    borderTop: "1px solid var(--cinza-50)",
    boxShadow: "none",
    width: "100%",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "var(--white)",
    borderBottom: "1px solid var(--cinza-50)",
    marginBottom: -1,
    padding: "0 24px",
    minHeight: 80,
    "&$expanded": {
      minHeight: 80,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
    padding: "24px",
  },
}))(MuiAccordionDetails);
/* fim estilos acordeon */

export function Home() {
  const [item, setItem] = useState(0);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [expanded, setExpanded] = React.useState("");
  const [checkList, setCheckList] = useState({});
  const [tutorialModal, setTutorialModal] = useState(false);
  const [requirementModal, setRequirementModal] = useState(false);
  const [analysisModal, setAnalysisModal] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [fmdModal, setFMDModal] = useState(false);

  const { user } = useAuth();
  const { addToast } = useToast();
  const { addRequirement } = useRequirement();

  let color = "";
  let icon = null;

  switch (processes?.status) {
    case "Concluído":
      color = "var(--neutro-50)";
      // icon = <CheckCircleIcon />;
      break;
    case "Completar dados":
      color = "var(--atencao-50)";
      // icon = <CreateIcon />;
      break;
    case "Concluir passo anterior":
      color = "var(--cinza-70)";
      // icon = <AccessTimeIcon />;
      break;
    default:
      break;
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleOpenModal(contentList) {
    setTimeout(() => {
      setVisible(true);
    }, 300);
    setCheckList(contentList);
  }

  function handleCloseModal(e) {
    // (e).preventDefault();
    setOpen(false);
    setTimeout(() => {
      setVisible(false);
    }, 600);
  }

  const handleRequest = () => {
    window.open("https://cidadaniaja.beeviral.app/cadastro/indique");
  };

  useEffect(() => {
    api.get("central/processes").then((response) => {
      setProcesses(response?.data?.data);
    });

    let modalWellcome = localStorage.getItem("@Central:wellcome");

    if (!modalWellcome) {
      localStorage.setItem("@Central:wellcome", `${true}`);
    }
  }, []);

  const handleProcess = (data) => {
    const selectedArray = processes.filter((phase) => phase.step.name !== data);

    const stepAtual = Math.min.apply(
      Math,
      selectedArray.map((item) => item.phase)
    );

    return selectedArray.filter((item) => item.phase === stepAtual);
  };

  // deve ser menor fase que não tenha processo com step = conclusion
  // const currentProcess = handleProcess("conclusion");
  const currentProcess = processes.filter(
    (phase) =>
      phase.step.name !== "waiting_completion" &&
      phase.step.name !== "conclusion"
  );

  // processos aguardando conclusão das etapas anteriores
  const waitingProcess = processes.filter(
    (phase) => phase.step.name === "waiting_completion"
  );

  // processos concluídos
  const completeProcess = processes.filter(
    (phase) => phase.step.name === "conclusion"
  );

  // processos em documentação
  const processosDocumentacao = currentProcess.filter(
    (phase) =>
      phase.step.name === "documentation" ||
      phase.step.name === "incomplete_documentation"
  );

  // processos em análise
  const processosAnalise = currentProcess.filter(
    (phase) =>
      phase.step.name === "blocked" ||
      phase.step.name === "complete_documentation"
  );

  // processos em apostilamento
  const processosApostilamento = currentProcess.filter(
    (phase) =>
      phase.step.name === "hague" ||
      phase.step.name === "docs_ok" ||
      phase.step.name === "unblocked"
  );

  // processos em despacho
  const processosDespacho = currentProcess.filter(
    (phase) => phase.step.name === "post" || phase.step.name === "blocked_post"
  );

  // processos em acompanhamento
  const processosAcompanhamento = currentProcess.filter(
    (phase) =>
      phase.step.name === "monitoring" ||
      phase.step.name === "requirement" ||
      phase.step.name === "approved" ||
      phase.step.name === "dispatched"
  );

  useEffect(() => {
    window.Appcues.identify(user.id, user);

    if (!user?.password_updated_at) {
      window.location.href = "/troca-de-senha";
    }
  }, [user]);

  return (
    <>
      <Header />
      <Container>
        <PageHeader>
          <Content>
            <Text>
              <Title>Meus pedidos</Title>
              <Welcome className="desktop">
                <h2>
                  Olá, <span>{user?.name}!</span>
                </h2>
                <p>
                  Bem-vindo(a) à central do Cidadania Já. Aqui, você pode
                  acompanhar o andamento de cada um dos seus pedidos e tirar as
                  suas dúvidas!
                </p>
              </Welcome>
            </Text>
            <img className="imgBanner" src={Frame} alt="Frame" />
            <Welcome className="mobile">
              <h2>
                Olá, <span>{user?.name}!</span>
              </h2>
              <p>Seja bem-vindo(a) à central do Cliente.</p>
            </Welcome>
          </Content>
        </PageHeader>
        <TabContent className="customTab">
          <OptionInfo>
            <img src={ArrowLongLeft} alt="Arrow Left" />
            Passe o dedo para ver mais
            <img src={ArrowLongRight} alt="Arrow Right" />
          </OptionInfo>
          <Options>
            <Option active={item === 0} onClick={() => setItem(0)}>
              <MailOutlineIcon />
              Em andamento
              <span>{currentProcess.length}</span>
              <hr active={item === 0} />
            </Option>
            <Option active={item === 1} onClick={() => setItem(1)}>
              <MailOutlineIcon />
              Próximos
              <span>{waitingProcess.length}</span>
              <hr active={item === 1} />
            </Option>
            <Option active={item === 2} onClick={() => setItem(2)}>
              <MailOutlineIcon />
              Concluídos
              <span>{completeProcess.length}</span>
              <hr active={item === 2} />
            </Option>
            <Option active={item === 3} onClick={() => setItem(3)}>
              Indique e ganhe
              <hr active={item === 3} />
            </Option>
          </Options>
          <TabBody>
            <ContentRequests active={item === 0} border={expanded === "panel6"}>
              <TabHeader>
                <p>
                  A seguir, você pode acompanhar os seus pedidos que estão
                  <span> em andamento</span>
                </p>
              </TabHeader>
              <Accordion
                square
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">1</p>
                      <p className="passo">Passo 1</p>
                      <p className="contadorMobile">
                        {processosDocumentacao.length !== 0
                          ? `${processosDocumentacao.length}
                                ${
                                  processosDocumentacao.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Tutorial</p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    <Arrow active={expanded === "panel1"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  <AccordionDetailHeader>
                    <p>
                      Caso queira realizar o Alinhamento Virtual novamente,{" "}
                      <span
                        className="activeModal"
                        onClick={() => setTutorialModal(true)}
                      >
                        clique aqui.
                      </span>
                    </p>
                  </AccordionDetailHeader>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">2</p>
                      <p className="passo">Passo 2</p>
                      <p className="contadorMobile">
                        {processosDocumentacao.length !== 0
                          ? `${processosDocumentacao.length}${
                              processosDocumentacao.length > 1
                                ? "pedidos"
                                : "pedido"
                            }`
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Documentação</p>
                      <p className="contador">
                        {processosDocumentacao.length !== 0
                          ? `
                                ${processosDocumentacao.length}
                                ${
                                  processosDocumentacao.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    {/* { props.status === "Completar dados" && */}
                    {processosDocumentacao.length > 0 && (
                      <Button
                        btType="var(--principal-40)"
                        color="var(--cinza-100)"
                        label="Conferir dados"
                        full
                        padding="0.625rem 1.5rem"
                      />
                    )}
                    <Arrow active={expanded === "panel2"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  {processosDocumentacao.length > 0 ? (
                    <>
                      <AccordionDetailHeader>
                        <p>
                          Aguardamos a documentação necessária do seu pedidos
                          para prosseguir.
                        </p>
                        <p className="print">
                          Você pode imprimir a listagem de documentos por pedido
                          no ícone de impressora ou imprimir a listagem de todas
                          as pessoas no botão abaixo
                        </p>
                        <a
                          target="_blank"
                          href="/impressao"
                          onClick={() => {
                            addRequirement(processosDocumentacao);
                            setRequirementModal(false);
                          }}
                        >
                          <PrintOutlinedIcon />
                          Imprimir listagem de documentos
                        </a>
                      </AccordionDetailHeader>
                      <AccordionDetailBody>
                        {processosDocumentacao &&
                          processosDocumentacao.map((process, index) => (
                            <>
                              <Card
                                key={index}
                                data={process}
                                documents={() => handleOpenModal(process)}
                                controllerModal={() =>
                                  setRequirementModal(true)
                                }
                                FMDModal={() => setFMDModal(true)}
                              />
                            </>
                          ))}
                      </AccordionDetailBody>
                    </>
                  ) : (
                    <AccordionDetailHeader>
                      No momento, não há nenhum pedido nessa etapa.
                    </AccordionDetailHeader>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">3</p>
                      <p className="passo">Passo 3</p>
                      <p className="contadorMobile">
                        {processosAnalise.length !== 0
                          ? `
                                ${processosAnalise.length}
                                ${
                                  processosAnalise.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Análise física da documentação</p>
                      <p className="contador">
                        {processosAnalise.length !== 0
                          ? `
                                ${processosAnalise.length}
                                ${
                                  processosAnalise.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    {/* { props.status === "Completar dados" && */}
                    <Arrow active={expanded === "panel3"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  {processosAnalise.length > 0 ? (
                    <>
                      <AccordionDetailBody>
                        {processosAnalise &&
                          processosAnalise.map((process, index) => (
                            <Card
                              key={index}
                              data={process}
                              modalAnalysis={() => setAnalysisModal(true)}
                            />
                          ))}
                      </AccordionDetailBody>
                    </>
                  ) : (
                    <AccordionDetailHeader>
                      No momento, não há nenhum pedido nessa etapa.
                    </AccordionDetailHeader>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">4</p>
                      <p className="passo">Passo 4</p>
                      <p className="contadorMobile">
                        {processosApostilamento.length !== 0
                          ? `
                                ${processosApostilamento.length}
                                ${
                                  processosApostilamento.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Apostilamento</p>
                      <p className="contador">
                        {processosApostilamento.length !== 0
                          ? `
                                ${processosApostilamento.length}
                                ${
                                  processosApostilamento.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    {/* { props.status === "Completar dados" && */}
                    <Arrow active={expanded === "panel4"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  {processosApostilamento.length > 0 ? (
                    <>
                      <AccordionDetailBody>
                        {processosApostilamento &&
                          processosApostilamento.map((process, index) => (
                            <Card key={index} data={process} />
                          ))}
                      </AccordionDetailBody>
                    </>
                  ) : (
                    <AccordionDetailHeader>
                      No momento, não há nenhum pedido nessa etapa.
                    </AccordionDetailHeader>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">5</p>
                      <p className="passo">Passo 5</p>
                      <p className="contadorMobile">
                        {processosDespacho.length !== 0
                          ? `
                                ${processosDespacho.length}
                                ${
                                  processosDespacho.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Despacho</p>
                      <p className="contador">
                        {processosDespacho.length !== 0
                          ? `
                                ${processosDespacho.length}
                                ${
                                  processosDespacho.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    {/* { props.status === "Completar dados" && */}
                    <Arrow active={expanded === "panel5"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  {processosDespacho.length > 0 ? (
                    <>
                      <AccordionDetailBody>
                        {processosDespacho &&
                          processosDespacho.map((process, index) => (
                            <Card key={index} data={process} />
                          ))}
                      </AccordionDetailBody>
                    </>
                  ) : (
                    <AccordionDetailHeader>
                      No momento, não há nenhum pedido nessa etapa.
                    </AccordionDetailHeader>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  className="borderNone"
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                >
                  <AccordionDescription>
                    <span>
                      <p className="countPasso">6</p>
                      <p className="passo">Passo 6</p>
                      <p className="contadorMobile">
                        {processosAcompanhamento.length !== 0
                          ? `
                                ${processosAcompanhamento.length}
                                ${
                                  processosAcompanhamento.length > 1
                                    ? "pedidos"
                                    : "pedido"
                                }
                            `
                          : ""}
                      </p>
                    </span>
                    <span>
                      <p className="title">Acompanhamento</p>
                      <p className="contador">
                        {processosAcompanhamento.length !== 0
                          ? `${processosAcompanhamento.length}${
                              processosAcompanhamento.length > 1
                                ? "pedidos"
                                : "pedido"
                            }`
                          : ""}
                      </p>
                    </span>
                  </AccordionDescription>
                  <AccordionStatus color={color}>
                    {/* { icon }
                                        <p>{ props.status }</p> */}
                  </AccordionStatus>
                  <AccordionButtons>
                    {/* { props.status === "Completar dados" && */}
                    <Arrow active={expanded === "panel6"}>
                      <ExpandMoreIcon />
                    </Arrow>
                  </AccordionButtons>
                </AccordionSummary>
                <AccordionDetails>
                  {processosAcompanhamento.length > 0 ? (
                    <>
                      <AccordionDetailHeader>
                        Agora que foi enviado, é só aguardar o seu procedimento
                        correr em Portugal. Na medida que existir alguma
                        atualização você será informado via e-mail.
                      </AccordionDetailHeader>
                      <AccordionDetailBody>
                        {processosAcompanhamento &&
                          processosAcompanhamento.map((process, index) => (
                            <Card
                              key={index}
                              data={process}
                              documents={() => handleOpenModal()}
                              modalFollowUp={() => setFollowUpModal(true)}
                            />
                          ))}
                      </AccordionDetailBody>
                    </>
                  ) : (
                    <AccordionDetailHeader>
                      No momento, não há nenhum pedido nessa etapa.
                    </AccordionDetailHeader>
                  )}
                </AccordionDetails>
              </Accordion>
            </ContentRequests>
            <ContentRequests active={item === 1} className="no-accordion">
              <TabHeader>
                <p>
                  Aqui serão listados seus pedidos que estão
                  <span>
                    {" "}
                    aguardando a conclusão de um procedimento anterior{" "}
                  </span>
                  para iniciarem
                </p>
              </TabHeader>
              <CardRequests>
                {waitingProcess &&
                  waitingProcess.map((process, index) => (
                    <Card key={index} next={process} />
                  ))}
              </CardRequests>
            </ContentRequests>
            <ContentRequests active={item === 2} className="no-accordion">
              <TabHeader>
                <p>
                  Aqui serão listados seus <span>pedidos concluídos!</span>
                </p>
              </TabHeader>
              <CardRequests>
                {completeProcess &&
                  completeProcess.map((process, index) => (
                    <Card key={index} complete={process} />
                  ))}
              </CardRequests>
            </ContentRequests>
            <ContentRequests active={item === 3} className="no-accordion">
              <TabHeader style={{ padding: "1.5rem 0" }}>
                <div>
                  <h3>{"Conheça o nosso programa de indicação!"}</h3>

                  <p>
                    {
                      "Já pensou em ganhar até R$1.000 ou 4.000 pontos Livelo? Além disso, poder presentear seus amigos com um super desconto no sonho da Cidadania Europeia? Faça o seu cadastro com a gente e comece a indicar!"
                    }
                  </p>
                </div>
              </TabHeader>
              <Button
                btType="var(--cinza-80)"
                color="var(--white)"
                label="Indique aqui"
                padding="0.625rem 1.5rem"
                action={handleRequest}
              />
            </ContentRequests>
          </TabBody>
        </TabContent>
      </Container>
      <ModalWelcome
        data={user}
        openModal={tutorialModal}
        closeModal={() => setTutorialModal(false)}
      />
      <ModalRequirements controllerModal={requirementModal} />
      <ModalAnalysis
        openModal={analysisModal}
        closeModal={() => setAnalysisModal(false)}
      />
      <ModalExigencia
        openModal={followUpModal}
        closeModal={() => setFollowUpModal(false)}
      />
      <ModalFMD openModal={fmdModal} closeModal={() => setFMDModal(false)} />
      <PageContainer open={open} visible={visible}>
        <WhiteBox>
          <CloseButton onClick={() => handleCloseModal()}>
            Fechar
            <CancelIcon />
          </CloseButton>
          <BoxContent active>
            <h4 style={{ marginTop: "40px" }}>
              Veja os documentos que você já nos enviou
            </h4>
            <p className="body" style={{ textAlign: "left" }}>
              Aqui você pode ver o andamento dos seus documentos e se eles já
              chegaram aqui no nosso escritório.
              <br />
              <br />
              <strong>DOCUMENTOS</strong>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: checkList?.documents_message,
                }}
              />
            </p>
            <CautionBox>
              <span>
                <WarningIcon />
                <p className="caution">Atenção</p>
              </span>
              <p className="caution-body">
                É possível que, mesmo com o documento entregue, ele não esteja
                como recebido aqui. Se esse for o seu caso, não se preocupe! O
                setor responsável irá confirmar o recebimento assim que
                possível. Caso tenha alguma dúvida, por favor entre em contato
                com o nosso atendimento.
              </p>
            </CautionBox>
          </BoxContent>
        </WhiteBox>
      </PageContainer>
    </>
  );
}
