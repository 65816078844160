// @ts-nocheck
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from "react-router-dom";
import { format, parseISO } from 'date-fns';

import {
    PageHeader,
    TabContent,
    TabBody,
    Options,
    OptionInfo,
    Option
} from "../../styles/components";

import {
    Container,
    Content,
    Text,
    Title,
    Aside,
    AsideBox,
    Activities,
    ActivitiesContent,
    AsideMobile,
    Sub,
    SubItem
} from "./styles";

import { Checkbox } from "../../components/Form";

import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import ArrowLongLeft from "../../assets/icons/arrow-long-left.svg";
import ArrowLongRight from "../../assets/icons/arrow-long-right.svg";

import Header from "../../components/Header";
import ActivityBox from "../../components/ActivityBox";

import { useAuth } from '../../hooks/auth';
import api from "../../services/api";

export function Atividades() {
    const { state } = useLocation();
    const [item, setItem] = useState(0);
    const [processes, setProcesses] = useState([]);
    const [history, setHistory] = useState([]);
    const [loadingProcesses, setLoadingProcesses] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [form, setForm] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);

    const { user } = useAuth();

    useEffect(() => {
        window.Appcues.identify(user.id, user)
    }, [user]);

    const handleOpenSelect = useCallback(async() => {
        setOpenSelect(!openSelect);
    }, [openSelect]);

    const handleProcess = (data) => {
        const selectedArray = processes.filter((phase) => phase.step.name !== (data));
        
        const stepAtual = Math.min.apply(Math, selectedArray.map((item) => item.phase));
        
        return selectedArray.filter((item) => item.phase === stepAtual);
    }

    // deve ser menor fase que não tenha processo com step = conclusion
    // const currentProcess = handleProcess("conclusion");
    const currentProcess = processes.filter((phase) => phase.step.name !== "waiting_completion" && phase.step.name !== "conclusion");

    // processos aguardando conclusão das etapas anteriores
    const waitingProcess = processes.filter((phase) => phase.step.name === "waiting_completion");

    // processos concluídos
    const completeProcess = processes.filter((phase) => phase.step.name === "conclusion");

    const handleChange = useCallback((event) => {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        if (value) {
            if (name === 'all') {
                setForm(currentProcess.map((item) => `${item.id}`));
            } else {
                setForm(state => ([ ...state, name ]));
            }
        } else {
            const newList = name === 'all' ? [] : form.filter((item) => item !== name);

            setForm(newList);
        }
    }, [form, currentProcess]);

    const handleCategory = useCallback(async(stage) => {
        try {
            setLoadingHistory(true);
            if (stage === 0) {
                await api
                    .get(`central/processes/history?processes=${currentProcess.map((activity) => activity.id)}`)
                    .then(response => {
                        setHistory(response.data.data);
                    });
            }
            if (stage === 1) {
                await api
                    .get(`central/processes/history?processes=${waitingProcess.map((activity) => activity.id)}`)
                    .then(response => {
                        setHistory(response.data.data);
                    });
            }
            if (stage === 2) {
                await api
                    .get(`central/processes/history?processes=${completeProcess.map((activity) => activity.id)}`)
                    .then(response => {
                        setHistory(response.data.data);
                    });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingHistory(false);
        }
    }, [currentProcess, waitingProcess, completeProcess]);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoadingProcesses(true);
                await api
                    .get('central/processes')
                    .then(response => {
                        setProcesses(response.data.data);
                    });
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingProcesses(false);
            }
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             setLoadingHistory(true);
    //             await api
    //                 .get(`central/processes/history?processes=${currentProcess.map((activity) => activity.id)}`)
    //                 .then(response => {
    //                     setHistory(response.data.data);
    //                 });
    //         } catch (err) {
    //             console.log(err);
    //         } finally {
    //             setLoadingHistory(false);
    //         }
    //     }
    //     fetchData();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [processes]);
    
    useEffect(() => {
        async function fetchData() {
            try {
                setLoadingHistory(true);
                if (form?.length > 0) {
                    await api
                        .get(`central/processes/history?processes=${form?.map((item) => item)}`)
                        .then(response => {
                            setHistory(response.data.data);
                        });
                } else {
                    await api
                        .get(`central/processes/history?processes=${currentProcess.map((activity) => activity.id)}`)
                        .then(response => {
                            setHistory(response.data.data);
                        });
                } 
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingHistory(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, processes]);

    return (
        <>
            <Container>
                <Header />
                <PageHeader>
                    <Content>
                        <Text>
                            <Title>Registro de atividades</Title>
                        </Text>
                    </Content>
                </PageHeader>
                <h3>Aqui você pode acompanhar todas as atividades do seu pedido</h3>
                <TabContent>
                    <OptionInfo>
                        <img src={ArrowLongLeft} alt="Arrow Left"/>
                        Passe o dedo para ver mais
                        <img src={ArrowLongRight} alt="Arrow Right"/>
                    </OptionInfo>
                    <Options>
                        <Option active={item === 0} onClick={() => { setItem(0); handleCategory(0) }}>
                            {/* <PersonOutlineOutlinedIcon /> */}
                            Em andamento
                            <span>{currentProcess.length}</span>
                            <hr active={item === 0} />
                        </Option>
                        <Option active={item === 1} onClick={() => { setItem(1); handleCategory(1) }}>
                            {/* <CreditCardOutlinedIcon /> */}
                            Próximos
                            <span>{waitingProcess.length}</span>
                            <hr active={item === 1} />
                        </Option>
                        <Option active={item === 2} onClick={() => { setItem(2); handleCategory(2) }}>
                            {/* <CreditCardOutlinedIcon /> */}
                            Concluídos
                            <span>{completeProcess.length}</span>
                            <hr active={item === 2} />
                        </Option>
                    </Options>
                    <TabBody className={item !== 0 && "hide"}>
                        <p>A seguir, você pode ver o registro de atividades dos seus pedidos que estão <strong>em andamento!</strong></p>
                        <AsideMobile>
                            {item === 0 && form?.length === 0 && (
                                <span>
                                    Selecione...
                                </span>
                            )}
                            {item === 0 && form?.length === 1 && (
                                <span>
                                    1 familiar selecionado
                                </span>
                            )}
                            {item === 0 && form?.length > 1 && form?.length !== currentProcess.length && (
                                <span>
                                    {form?.length} familiares selecionados
                                </span>
                            )}
                            {item === 0 && form?.length === currentProcess.length && (
                                <span>
                                    Todos os familiares
                                </span>
                            )}
                            <KeyboardArrowDownOutlinedIcon onClick={() => handleOpenSelect()} />
                            <Sub open={openSelect}>
                                <div>
                                    {currentProcess && currentProcess.map((process) => (
                                        <AsideBox key={process.id}>
                                            <div>
                                                <p>{process.applicant.name}</p>
                                                <p className="type">{process.type}</p>
                                            </div>
                                            <Checkbox name={process.id} checked={!!form?.find((item) => item === `${process.id}`)} onChange={(e) => handleChange(e)} />
                                        </AsideBox>
                                    ))}
                                </div>
                            </Sub>
                        </AsideMobile>
                        <div>
                            <Aside>
                                <p>Filtrar por familiar:</p>
                                <AsideBox>
                                    <div>
                                        <p>Todos os familiares</p>
                                    </div>
                                    <Checkbox name="all" checked={form?.length === currentProcess.length} onChange={(e) => handleChange(e)} />
                                </AsideBox>
                                {currentProcess && currentProcess.map((process) => (
                                    <AsideBox key={process.id}>
                                        <div>
                                            <p>{process.applicant.name}</p>
                                            <p className="type">{process.type}</p>
                                        </div>
                                        <Checkbox name={process.id} checked={!!form?.find((item) => item === `${process.id}`)} onChange={(e) => handleChange(e)} />
                                    </AsideBox>
                                ))}
                            </Aside>
                            <Activities>
                                {loadingHistory ? (
                                    <RotateLeftOutlinedIcon className="icon-spin" />
                                ) : (
                                    <>
                                        {history && history.map((item) => (
                                            <ActivitiesContent>
                                                <p>{format(new Date(item.date), "dd/MM/yyyy")}</p>
                                                {item.data.map((comment) => (
                                                    <ActivityBox data={comment} />
                                                ))}
                                            </ActivitiesContent>
                                        ))}
                                    </>
                                )}
                            </Activities>
                        </div>
                    </TabBody>
                    <TabBody className={item !== 1 && "hide"}>
                        <p>A seguir, você pode ver o registro de atividades dos seus pedidos que ainda <strong>não iniciaram!</strong></p>
                        <div>
                            {/* <Aside>
                                <p>Filtrar por familiar:</p>
                                <AsideBox>
                                    <div>
                                        <p>Todos os familiares</p>
                                    </div>
                                </AsideBox>
                                {waitingProcess && waitingProcess.map((process) => (
                                    <AsideBox key={process.id}>
                                        <div>
                                            <p>{process.applicant.name}</p>
                                            <p className="type">{process.type}</p>
                                        </div>
                                    </AsideBox>
                                ))}
                            </Aside> */}
                            <Activities>
                                {loadingHistory ? (
                                    <RotateLeftOutlinedIcon className="icon-spin" />
                                ) : (
                                    <>
                                        {history && history.map((item) => (
                                            <ActivitiesContent>
                                                <p>{format(new Date(item.date), "dd/MM/yyyy")}</p>
                                                {item.data.map((comment) => (
                                                    <ActivityBox data={comment} />
                                                ))}
                                            </ActivitiesContent>
                                        ))}
                                    </>
                                )}
                            </Activities>
                        </div>
                    </TabBody>
                    <TabBody className={item !== 2 && "hide"}>
                        <p>A seguir, você pode ver o registro de atividades dos seus pedidos que já <strong>concluíram!</strong></p>
                        <div>
                            {/* <Aside>
                                <p>Filtrar por familiar:</p>
                                <AsideBox>
                                    <div>
                                        <p>Todos os familiares</p>
                                    </div>
                                </AsideBox>
                                {waitingProcess && waitingProcess.map((process) => (
                                    <AsideBox key={process.id}>
                                        <div>
                                            <p>{process.applicant.name}</p>
                                            <p className="type">{process.type}</p>
                                        </div>
                                    </AsideBox>
                                ))}
                            </Aside> */}
                            <Activities>
                                {loadingHistory ? (
                                    <RotateLeftOutlinedIcon className="icon-spin" />
                                ) : (
                                    <>
                                        {history && history.map((item) => (
                                            <ActivitiesContent>
                                                <p>{format(new Date(item.date), "dd/MM/yyyy")}</p>
                                                {item.data.map((comment) => (
                                                    <ActivityBox data={comment} />
                                                ))}
                                            </ActivitiesContent>
                                        ))}
                                    </>
                                )}
                            </Activities>
                        </div>
                    </TabBody>
                </TabContent>
            </Container>
        </>
    );
}