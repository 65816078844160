// @ts-nocheck
import styled, { css } from "styled-components";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Items = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    display: flex;
    margin-right: 24px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Item = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.71875rem;
  font-size: var(--font-17);
  line-height: 1.75rem;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  
  ${({ efeitoHover }) => efeitoHover ? css`
    font-weight: 700;
    color: var(--principal-50);
  ` : css`
    color: var(--cinza-100);
  `};

  ${({ green }) => 
  green &&
    css`
      border: none;
      background: var(--principal-40);
    `}

  &:hover {
    color: var(--principal-50);
  }

  &.contactButton {
    border: 0;
    border-radius: 0;
    min-width: 120px;
    padding: 0.75rem 0;

    &:hover {
      &:after {
        display: none;
      }
    }
  }

  &.accordionButton {
    transition: all 0.5s ease-in;
    border: ${({ green }) => green ? "none" : "1px solid var(--principal-50)"};;
    color: ${({ green }) => green ? "var(--cinza-100)" : "var(--principal-50)"};
    border-radius: ${({ green }) => green ? "0.5rem 0.5rem 0 0" : "0.5rem"};
    padding: 0.75rem;

    &:hover {
      &:after {
        display: none;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: var(--principal-50);
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    opacity: ${({ efeitoHover }) => efeitoHover ? 1 : 0};
    left: auto;
    bottom: -1rem;
    transition: 0.5s;
  }
`;

export const Sub = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  transition: all 0.5s ease-in;
  width: 100%;
  max-height: 0;
  top: 3.5rem;
  left: auto;
  overflow: hidden;
  z-index: 99;

  ${({ open }) => 
    open && 
    css `
      max-height: 18rem;
      border-radius: 0 0 0.5rem 0.5rem;
    `}

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -6px;
    left: auto;
  }
`;

export const SubTitle = styled.h5`
  font-size: 14px;
  color: #fdd51d;
  margin-bottom: 8px;
  margin-top: ${({ last }) => (last ? "16px" : 0)};
  text-transform: uppercase;
  cursor: default;
`;

export const SubItem = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  background: var(--white);
  color: var(--principal-50);
  padding: 0.75rem 0.5rem;
  transition: 300ms;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--principal-50);

  &:last-child {
    margin-bottom: 0;
    border: none;
  }

  /* &:hover {
    color: #fdd51d;
  } */
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all 0.5s ease-in-out;
  background: var(--white);
  width: 392px;
  max-height: 0;
  overflow: hidden;
  top: 135%;
  left: calc((-392px + 52px) / 2);
  z-index: 99;
  border-radius: 16px;
  box-shadow: 0 0 25px -5px rgba(20, 20, 42, 0.1), 0 0 50px -5px rgba(20, 20, 42, 0.2);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 0 1px rgba(20, 20, 42, 0.06));

  ${({ active }) => 
    active && 
    css `
      max-height: 700px;
    `}

  /* p {
    font-size: 14px;
    color: var(--white);
  } */

  > p {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 15px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: var(--cinza-90);
    text-transform: uppercase;
    padding: 24px 0 8px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 20px 32px 20px 16px;
    cursor: pointer;

    svg {
      &.icon-spin {
        animation: iconSpin 0.8s infinite linear;
      }

      @keyframes iconSpin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-359deg);
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      > span {
        display: flex;
        gap: 4px;

        > span {
          font-size: 13px;
          line-height: 20px;
          color: var(--cinza-60);
          letter-spacing: 0.2px;
        }

        &.header {
          justify-content: space-between;
          gap: 0;

          > span {
            font-weight: 700;
          }
        }
      }

      p {
        &.title {
          font-size: 17px;
          line-height: 28px;
          color: var(--cinza-90);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 250px;
        }

        &.body {
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--cinza-70);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 240px;
          /* display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; */
          margin-bottom: 4px;
        }
      }
    }
  }

  > hr {
    border: 0;
    border-bottom: 1px solid var(--cinza-30);
    margin: 0 16px;
  }

  > a {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 17px;
    line-height: 28px;
    font-weight: 700;
    color: var(--principal-50);
    padding: 16px 0;
    text-decoration: none;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;

    ${({ active }) =>
      active ? 
      css `
        top: 160%;
        right: 4px;

        &:after {
          left: 87%;
        }
      ` :
      css `
        display: none;
      `
    }
  }
`;

export const ArrowNotification = styled.div`
  position: absolute;
  top: 88.5%;
  left: 50%;
  margin-left: -12px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent var(--white) transparent;
  z-index: 120;
`;
