// @ts-nocheck
import styled from 'styled-components';
import { shade } from 'polished';

export const PageContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  top: 0;
  transition: all 0.5s ease-in-out;
  opacity: ${({ open }) => open ? 1 : 0};

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--white);
  width: 100%;
  max-width: 600px;
  max-height: 95%;
  padding: 1rem 1.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-baixo-grande);

  @media screen and (max-width: 950px) {
    width: 95%;
  }

  @media screen and (max-width: 767px) {
    /* height: 95vh; */
    top: 16px;
  }
`;

export const BoxContent = styled.div`
  transition: all 0.5s ease-in-out;
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  h4 {
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    width: 100%;
    color: var(--cinza-100);
    margin-bottom: 4px;
  }

  p {
    font-size: 17px;
    line-height: 28px;
    color: var(--cinza-90);

    span {
      color: var(--principal-50);
      font-weight: 700;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0;

    &.formGray {
      background: var(--cinza-20);
      padding: 1.5rem 8.25rem;
      margin-top: 2rem;
      border-radius: 0.5rem;
    }

    &.fullFormGray {
      flex-direction: column;
      background: var(--cinza-20);
      padding: 1rem;
      margin-top: 2rem;
      border-radius: 0.5rem;
    }

    div {
      display: flex;
      width: 100%;
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.body,
      &.description {
        font-size: 17px;
        line-height: 28px;
        text-align: left;
      }
    }
  }
`;

export const BoxImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  .slick-list,
  .slick-slider {
    width: 100%;
  }
  
  ul.slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 8px;
    bottom: 0;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0;
      background: var(--cinza-30);

      button {
        width: 100%;
        height: 4px;
        padding: 0;

        &:before {
          width: 100%;
          height: 4px;
          font-size: 0;
          line-height: 0;
        }
      }

      &.slick-active {
        background: var(--principal-50);
      }
    }
  }
`;

export const CloseButton = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--principal-50);
  top: 1rem;
  right: 1.5rem;
  z-index: 10;
  cursor: pointer;

  svg {
    color: var(--principal-40);
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  position: relative;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
`;

export const CautionBox = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--atencao-10);
  width: 100%;
  padding: 16px;
  border-radius: 0.25rem;
  border-left: 4px solid var(--atencao-30);

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    svg {
      width: 1.5rem;
      color: var(--atencao-50);
      margin-right: 0.5rem;
    }
  }

  p {
    font-size: var(--font-15);
    line-height: 1.5rem;

    &.caution {
      font-weight: 700;
      color: var(--cinza-100);
    }

    &.caution-body {
      color: var(--cinza-90);
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.caution,
      &.caution-body {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  background: transparent;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 1px;

  &.marginButton {
    margin-top: 2rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: var(--font-17);
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  outline: none;

  &.midButton {
    border: none;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  outline: none;
  margin-top: 32px;
  margin-bottom: 32px;

  > p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--cinza-70);

    strong {
      color: var(--cinza-90);
    }
  }

  > img {
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1250px) {
    width: 1100px;
  }

  @media screen and (max-width: 1100px) {
    width: 100vw;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export const ArrowSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(55.73deg, #32C050 19.37%, #67D67E 77.71%);
  color: var(--white);
  border-radius: 8px;
  z-index: 90;
  width: 40px;
  height: 40px;
  ${({ right }) => right ? 'right: 10px;' : "left: 10px;"}

  > svg {
    position: relative;
    top: 8px;
    left: 8px;
    color: #fff;

    &.left {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: ${shade(0.1, "#32C050")};
  }

  &::before {
    content: '';
  }
`;
