// @ts-nocheck
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import { ReactComponent as DisableBell } from '../../../assets/icons/sino-sem-notificacao.svg';
import { ReactComponent as ActiveBell } from '../../../assets/icons/sino-com-notificacao.svg';
import { ReactComponent as NotificationCheck } from '../../../assets/icons/notification-check.svg';
import { ReactComponent as NotificationAlert } from '../../../assets/icons/notification-alert.svg';
import { ReactComponent as NotificationUser } from '../../../assets/icons/notification-user.svg';
import { ReactComponent as NoNotification } from '../../../assets/icons/no-notification.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon-notification.svg';

import {
  Container,
  Items,
  Item,
  NotificationContainer,
  TopContent,
  MenuButton
} from "./styles";

import api from "../../../services/api";

export default function Notifications() {
  let history = useHistory();
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNotification = useCallback(async() => {
    setOpenNotification(!openNotification);
  }, [openNotification]);

  async function handleMarkRead(data) {
    try {
      await api.patch(`central/notifications/${data?.id}/mark-as-read`);
      
      setLoading(true);
      
      await api.get('central/notifications')
      .then((response) => setNotifications(response.data.data.filter((read) => 
      read.read_at === null
      )));
      
      history.push({ pathname: '/registro-de-atividades', state: data });
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await api.get('central/notifications')
                  .then((response) => setNotifications(response.data.data.filter((read) =>
                    read.read_at === null
                  )));
      } catch(err) {
        console.log(err)
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Items>
        <Item onClick={() => handleNotification()}>
          {notifications.length > 0 ? (
            <ActiveBell />
          ) : (
            <DisableBell />
          )}
        </Item>
        <NotificationContainer active={openNotification}>
          <>
            <TopContent>
              <p>Notificações</p>
              <MenuButton type="button" onClick={() => setOpenNotification(false)}>
                Fechar
                <CloseIcon />
              </MenuButton>
            </TopContent>
            {loading ? (
                <div>
                  <RotateLeftOutlinedIcon className="icon-spin" />
                </div>
            ) : (
              notifications.length > 0 ? notifications.slice(0, 5).map((notification) => 
                notification?.read_at === null && (
                  <>
                    <div onClick={() => handleMarkRead(notification)}>
                      <NotificationCheck />
                      <div>
                        <span className="header">
                          <p className="title">
                            {notification?.title}
                          </p>
                          <span>10/07</span>
                        </span>
                        <p className="body">{notification?.body}</p>
                        <span>
                          <NotificationUser />
                          <span>{notification?.meta?.info}</span>
                        </span>
                      </div>
                    </div>
                    <hr />
                  </>
                )
              ) : (
                <div>
                  <div style={{ alignItems: "center", gap: "8px" }}>
                    <NoNotification />
                    <p className="body">Não há novas notificações!</p>
                  </div>
                </div>
              )
            )}
            <a href="/registro-de-atividades">Ver tudo</a>
          </>
        </NotificationContainer>
      </Items>
    </Container>
  );
}