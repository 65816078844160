import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--cinza-80);
  letter-spacing: 0.2px;

  > span {
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
  }

  > p {
    font-size: 15px;
    line-height: 24px;
    max-width: 300px;
  }
`;
