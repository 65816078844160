// @ts-nocheck
import { useEffect } from "react";
import LogoImpressao from "../../assets/logo-impressao.png";

import {
  Container,
  PageContent,
  PageHeader,
  Content,
  Services,
} from "./styles";

import { useRequirement } from "../../hooks/requirement";

export function Impressao() {
  const { requirement, removeRequirement } = useRequirement();

  window.onload = function () {
    window.print();
    window.close();
    removeRequirement();
  };

  return (
    <Container id="print">
      <PageContent>
        <PageHeader>
          <img src={LogoImpressao} alt="Logo Cidadania" />
          <p>Documentação necessária</p>
        </PageHeader>
        <p className="infos">
          Após reunir todos os documentos listados abaixo e de acordo com as
          especificações, você deverá enviar para o nosso escritório,
          pessoalmente ou pelos Correios.
          <br />O endereço é: Praia de Botafogo 518, 10°andar, frente. CEP:
          22250-040. Rio de Janeiro/RJ
        </p>
        {requirement.length &&
          requirement.map((req) => (
            <Content key={req.id}>
              <p className="title">{req.applicant.name}</p>
              <p className="service">Tipo do serviço: {req.type}</p>
              {req.service_documents.map((document) => (
                <Services>
                  <p className="title">
                    <div />
                    {document.name}
                  </p>
                  <p>
                    {document?.format} {document?.name}
                    {document?.from_who && " "}
                    {document?.from_who &&
                      document?.from_who.replace(
                        "applicant",
                        req?.applicant?.name
                      )}
                    {document.specification && ", "}
                    {document?.specification}
                  </p>
                </Services>
              ))}
            </Content>
          ))}
      </PageContent>
    </Container>
  );
}
