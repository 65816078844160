// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
    TabContent,
    TabBody,
    TabHeader,
    Options,
    OptionInfo,
    Option
} from "styles/components";

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxContent,
    CautionBox
} from "../../components/Modal/ModalWelcome/styles";

import {
    Container,
    PageHeader,
    Content,
    Text,
    Info,
    MobileInfo,
    TooltipInfo,
    Title,
    Welcome,
    ContentRequests,
    CardRequests,
    AccordionDescription,
    AccordionStatus,
    AccordionButtons,
    Arrow,
    AccordionDetailHeader,
    AccordionDetailBody,
    InfoProcuracao
} from "./styles";

import Header from "../../components/Header";
// import Accordion from "../../components/Accordion";
import Button from "../../components/Button";

import Frame from "../../assets/header/frame-procuracao.png";
import FrameMobile from "../../assets/header/frame-procuracao-mobile.png";
import ArrowLongLeft from "../../assets/icons/arrow-long-left.svg";
import ArrowLongRight from "../../assets/icons/arrow-long-right.svg";

import { useRequirement } from '../../hooks/requirement';
import api from "services/api";

/* inicio estilos acordeon */
const Accordion = withStyles({
    root: {
        borderTop: '1px solid var(--cinza-50)',
        boxShadow: 'none',
        width: '100%',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
  
const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'var(--white)',
        borderBottom: '1px solid var(--cinza-50)',
        marginBottom: -1,
        padding: '0 24px',
        minHeight: 80,
        '&$expanded': {
            minHeight: 80,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flexDirection: 'column',
        padding: '24px',
    },
}))(MuiAccordionDetails);
/* fim estilos acordeon */

export function Procuracao() {
    const [tooltip, setTooltip] = useState(false);
    const [expanded, setExpanded] = React.useState('');
    
    const { requirement } = useRequirement();

    function handleSubMenu(item, toggle) {
        setTooltip({ [item]: toggle });
    }

    useEffect(() => {
        // inicio typeform
        var qs,js,q,s,d= document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/";
        
        if(!gi.call(d,id)) { 
            js=ce.call(d,"script");
            js.id=id; js.src=b+"embed.js";
            q=gt.call(d,"script")[0];
            q.parentNode.insertBefore(js,q)
        }
        // fim typeform

        // inicio jotform
        var ifr = document.getElementById("JotFormIFrame-202413682721045");

        if (ifr) {
            var src = ifr.src;
            var iframeParams = [];
            if (window.location.href && window.location.href.indexOf("?") > -1) {
            iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
            }
            if (src && src.indexOf("?") > -1) {
            iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
            src = src.substr(0, src.indexOf("?"))
            }
            iframeParams.push("isIframeEmbed=1");
            ifr.src = src + "?" + iframeParams.join('&');
        }

        const handleIFrameMessage = function(e) {
            if(typeof e.data === 'object') { 
                return;
            }
            var args = e.data.split(":");
            let iframe = '';
            if(args.length > 2) {
                iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]);
            } else {
                iframe = document.getElementById("JotFormIFrame");
            }
            if(!iframe) {
                return;
            }
            switch (args[0]) {
                case "scrollIntoView":
                    iframe.scrollIntoView();
                    break;
                case "setHeight":
                    iframe.style.height = args[1] + "px";
                    break;
                case "collapseErrorPage":
                    if (iframe.clientHeight > window.innerHeight) {
                    iframe.style.height = window.innerHeight + "px";
                    }
                    break;
                case "reloadPage":
                    window.location.reload();
                    break;
                case "loadScript":
                    if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
                    var src = args[1];
                    if (args.length > 3) {
                        src = args[1] + ':' + args[2];
                    }
                    var script = document.createElement('script');
                    script.src = src;
                    script.type = 'text/javascript';
                    document.body.appendChild(script);
                    break;
                case "exitFullscreen":
                    if      (window.document.exitFullscreen)        window.document.exitFullscreen();
                    else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
                    else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
                    else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
                    else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
                    break;
                default:
                    break;
                
            }
            var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
            if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
                var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
                iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
            }
        };

        const isPermitted = function(originUrl, whitelisted_domains) {
            var url = document.createElement('a');
            url.href = originUrl;
            var hostname = url.hostname;
            var result = false;
            if( typeof hostname !== 'undefined' ) {
            whitelisted_domains.forEach(function(element) {
                if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                    result = true;
                }
            });
            return result;
            }
        }

        if (window.addEventListener) {
            window.addEventListener("message", handleIFrameMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", handleIFrameMessage);
        }
        // fim jotform
    }, []);

    const verifyForm = requirement?.proxy_form_path?.split('.');

    return (
        <>
            <Header />
            <Container>
                <PageHeader>
                    <Content>
                        <Text>
                            <p className="title">{requirement?.type}</p>
                            <h2>
                                Formulário de procuração de
                                <span> {requirement?.applicant}</span>
                            </h2>
                            <p className="description">
                                Preencha esses dados para gerar sua procuração e o cadastro do pedido na conservatória
                            </p>
                            <Info>
                                <MobileInfo
                                    onClick={() => handleSubMenu("tooltip1", !tooltip?.tooltip1)}
                                    onMouseEnter={() => handleSubMenu("tooltip1", !tooltip?.tooltip1)}
                                    onMouseLeave={() => handleSubMenu(false)}
                                >
                                    <InfoOutlinedIcon />
                                    <p>Entenda o que é <strong>conservatória</strong></p>
                                </MobileInfo>
                                <TooltipInfo active={tooltip?.tooltip1}>
                                    <p>
                                        <strong>Conservatórias são repartições públicas portuguesas.</strong> Nelas que 
                                        são feitos registos de diversas naturezas, tais como o registo civil.
                                    </p>
                                    <p>
                                        Fazendo uma comparação com o Brasil, são como os cartórios de registro civil, 
                                        onde são registrados os nascimentos, casamentos, óbitos… 
                                    </p>
                                </TooltipInfo>
                            </Info>
                        </Text>
                        <img className="frameDesktop" src={Frame} alt="Frame"/>
                        <img className="frameMobile" src={FrameMobile} alt="Frame"/>
                    </Content>
                </PageHeader>
                <TabContent className="customTab">
                    {requirement && requirement.proxy_form_path && (
                        <>
                            {verifyForm[1] === "typeform" ?
                                <div
                                    class="typeform-widget"
                                    data-url={`${requirement.proxy_form_path}&typeform-medium=embed-snippet`}
                                    style={{ width: "100%", height: "500px" }}
                                >
                                </div>
                            :
                                <iframe
                                    id="JotFormIFrame-202413682721045"
                                    title="Dados para gerar a procuração para Homologação"
                                    onload="window.parent.scrollTo(0,0)"
                                    allowtransparency="true"
                                    allowfullscreen="true"
                                    allow="geolocation; microphone; camera"
                                    src={requirement.proxy_form_path}
                                    frameborder="0"
                                    style={{ minWidth: '100%', height: '539px', border: 'none' }}
                                    scrolling="no"
                                >
                                </iframe>
                            }
                        </>
                    )}
                </TabContent>
                {requirement && !requirement.proxy_form_path && (
                    <Text className="defaultInfo">
                        <p className="description">
                            No momento não há nenhum formulário de procuração a ser preenchido/alterado para esse cliente!
                        </p>
                    </Text>
                )}
                <InfoProcuracao>
                    <h3>Já preencheu os dados do formulário?</h3>
                    <p>Sua procuração estará disponível para download dentro de 5 a 10 minutos. Enquanto isso, que tal 
                    acompanhar o andamento dos seus pedidos na Central? Clique no botão abaixo para voltar para a página 
                    principal.
                    </p>
                    <a href="/">
                        <ChevronLeftIcon />
                        Voltar para meus pedidos
                    </a>
                </InfoProcuracao>
            </Container>
        </>
    );
}