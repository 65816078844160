// @ts-nocheck
import { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Container, Label, Content } from "./styles"

export const Input = ({ name, ...props }) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container className="customContainerInput">
            {props.label &&
                <Label disabled={props.disabled} htmlFor={props.name}>{props.label}</Label>
            }
            <Content icon={props.icon} className="customInput" isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
                {props.icon &&
                    props.icon
                }
                <input
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...props}
                />
                {props.iconPassword &&
                    <button
                        type="button"
                        onClick={props.changePassword}>
                        {props.visible === true ? (
                            <VisibilityIcon size={24} />
                        ) : (
                            <VisibilityOffIcon size={24} />
                        )}
                    </button>
                }
            </Content>
            {error && <span className="error">{error}</span>}
        </Container>
    );
}