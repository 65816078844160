// @ts-nocheck
import { format, differenceInDays , parseISO, addDays, subDays } from 'date-fns';

import {
    Container,
    CardHeader,
    CardService,
    CardStats,
    CardPercent,
    LinePercent,
    StatsInfo,
    Prediction,
    Contact,
    StatsDispatch,
    Delay,
    Bars,
    Bar,
    Button
} from "./styles";

import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import Check from '../../assets/boas-vindas/check-grande.png';
import BasicLoading from '../../assets/icons/basic-loading.svg';
import { ReactComponent as Chat } from '../../assets/icons/chat.svg';
import { ReactComponent as RedChat } from '../../assets/icons/red-chat.svg';
import { ReactComponent as CalendarEvent } from '../../assets/icons/calendar_event.svg';
import { ReactComponent as ExternalLink } from '../../assets/icons/external_link.svg';

import CardDispatch from "./CardDispatch";
import CardFollowUp from "./CardFollowUp";
import CardAnalysis from "./CardAnalysis";

import DocumentationDocuments from "./DocumentationDocuments";
import AnalysisDocuments from "./CardAnalysis/AnalysisDocuments";

import { useRequirement } from '../../hooks/requirement';

export default function ComponentCard({ data, documents, next, complete, controllerModal, modalAnalysis, modalFollowUp, FMDModal }) {
    const { addRequirement } = useRequirement();

    const startHaguePredictionDate = data?.start_hague_prediction_date && new Date(data.start_hague_prediction_date);
    const endHaguePredictionDate = data?.end_hague_prediction_date && new Date(data.end_hague_prediction_date);

    let percentHague = parseInt(((new Date() - startHaguePredictionDate) / (endHaguePredictionDate - startHaguePredictionDate)) * 100);
    if(percentHague < 0) {
        percentHague = 0;
    }
    
    const countHagueDaysRemain = differenceInDays(addDays(new Date(data?.end_hague_prediction_date), 1), new Date());

    return (
        <Container key={data?.id}>
            <CardHeader>
                <h3>{ (data || next || complete) && (data?.applicant?.name || next?.applicant?.name || complete?.applicant?.name) }</h3>
                {data && (data?.step?.name === "documentation" || data?.step?.name === "incomplete_documentation") && (
                    <a target="_blank" href="/impressao" onClick={() => addRequirement([ data ])}>
                        <PrintOutlinedIcon />
                    </a>
                )}
            </CardHeader>
            <CardService>
                <p className="service">tipo de serviço</p>
                <p>{ (data || next || complete) && (data?.type || next?.type || complete?.type) }</p>
                <hr/>
            </CardService>
            {data && (data?.step?.name === "documentation" || data?.step?.name === "incomplete_documentation") &&
                <>
                    <CardStats>
                        <p>documentação necessária</p>
                        <CardPercent color="var(--principal-50)">
                            <span className="bar">
                                <LinePercent size={data?.documents_completed} color="var(--principal-50)" />
                            </span>
                            <span className="percent">{data?.documents_completed}%</span>
                        </CardPercent>
                        {/* <StatsInfo color="var(--atencao-50)">
                            <AccessTimeIcon />
                            <span>Documentação em pré-análise</span>
                        </StatsInfo> */}
                    </CardStats>
                    {data?.service_documents.map((req) => (
                        <DocumentationDocuments 
                            data={{
                                ...req,
                                applicant: data.applicant.name,
                                identifier: data?.identifier,
                                is_fmd: data?.sale?.is_fmd,
                                open: true,
                                proxy_form_path: data?.proxy_form_path,
                                proxy_path: data?.proxy_path,
                                service_code: data?.service_code,
                                type: data?.type
                            }}
                            controllerModal={controllerModal}
                            FMDModal={FMDModal}
                        />
                    ))}
                </>
            }
            {data && (data?.step?.name === "blocked" || data?.step?.name === "complete_documentation") && (
                <CardAnalysis
                    data={{
                        ...data,
                        open: true,
                        proxy_form_path: data?.proxy_form_path,
                        proxy_path: data?.proxy_path
                    }}
                    action={modalAnalysis}
                />
            )}
            {data && (data?.step?.name === "hague" || data?.step?.name === "docs_ok" || data?.step?.name === "unblocked") && (
                <>
                    <CardStats style={{ marginTop: 0 }}>
                        <p>documentação em apostilamento</p>
                        <Prediction>
                            <CalendarTodayOutlinedIcon style={percentHague > 100 ? {color: "var(--atencao-50)"} : {color: "var(--principal-50)"}} />
                            <p>Previsão para conclusão: <strong>{data?.end_hague_prediction_date && format(parseISO(data?.end_hague_prediction_date), "dd/MM")}</strong></p>
                        </Prediction>
                        <div className="indicators">
                            <p>Início do apostilamento</p>
                            <p>Docs apostilados</p>
                        </div>
                        <CardPercent className="m4" color="var(--principal-50)">
                            <span className="bar">
                                <LinePercent
                                    size={percentHague > 100 ? 100 : percentHague}
                                    color={percentHague > 100 ? "var(--atencao-50)" : "var(--principal-50)"}
                                />
                            </span>
                        </CardPercent>
                        <div className="indicators">
                            <p>{data?.start_hague_prediction_date && format(parseISO(data?.start_hague_prediction_date), "dd/MM")}</p>
                            <p>{data?.end_hague_prediction_date && format(parseISO(data?.end_hague_prediction_date), "dd/MM")}</p>
                        </div>
                        {percentHague > 100 ? (
                            <>
                                <StatsInfo className="analysis">
                                    <AccessTimeIcon style={{ color: "var(--atencao-50)" }} />
                                    <span>O apostilamento demorará mais que o esperado</span>
                                </StatsInfo>
                                <p className="analysisText">Pedimos desculpas, mas o apostilamento está levando um tempo 
                                maior que o inicialmente previsto. Estamos trabalhando para que em no máximo 2 dias úteis 
                                essa etapa seja concluída.</p>
                            </>
                        ) : (
                            <>
                                <StatsInfo className="analysis">
                                    <img src={BasicLoading} alt="Icon" />
                                    <span>Apostilamento em andamento</span>
                                </StatsInfo>
                                <p className="analysisText">Em {countHagueDaysRemain > 1 ? `${countHagueDaysRemain} dias` : `${countHagueDaysRemain} dia`} o cartório concluirá o apostilamentos dos documentos necessários.</p>
                            </>
                        )}
                    </CardStats>
                    <CardService className="analysis">
                        <p>Documentos em apostilamento:</p>
                        {data?.service_documents.map((req) => (
                            req.hague && (
                                <AnalysisDocuments data={{
                                    ...req,
                                    applicant: data.applicant.name,
                                    type: data?.type,
                                    proxy_form_path: data?.proxy_form_path,
                                    proxy_path: data?.proxy_path
                                }} controllerModal={controllerModal} />
                            )
                        ))}
                    </CardService>
                </>
            )}
            {data && (data?.step?.name === "post" || data?.step?.name === "blocked_post") && (
                <CardDispatch data={data} />
            )}
            {data && (
                data?.step?.name === "monitoring" ||
                data?.step?.name === "requirement" ||
                data?.step?.name === "approved" ||
                data?.step?.name === "dispatched"
            ) && (
                <CardFollowUp data={data} action={modalFollowUp} />
            )}
            {/* {data && (data?.step?.name !== "documentation" && data?.step?.name !== "incomplete_documentation") &&
                <>
                        {data?.step?.name === "complete_documentation" && (
                            <CardService className="others">
                                <AccessTimeIcon />
                                <p className="history">
                                    Preparado para análise dia:
                                </p>
                                <p className="date">
                                    {data?.docks_ok_date && format(parseISO(data?.docks_ok_date), "dd/MM/yyyy")}
                                </p>
                                <p className="history">
                                    Previsão de conclusão de análise:
                                </p>
                                <p className="date">
                                    (próxima terça)
                                </p>
                            </CardService>
                        )}
                        {data?.step?.name === "hague" && (
                            <CardService className="others">
                                <AccessTimeIcon />
                                <p className="history">
                                    Enviado para apostilamento dia:
                                </p>
                                <p className="date">
                                    {data?.apostilate_date && format(parseISO(data?.apostilate_date), "dd/MM/yyyy")}
                                </p>
                                <p className="history">
                                    Previsão de retorno dia:
                                </p>
                                <p className="date">
                                    {data?.apostilate_date && format(addDays(parseISO(data?.apostilate_date), 14), "dd/MM/yyyy")}
                                </p>
                            </CardService>
                        )}
                        {data?.access_password && (
                            <CardService className="others">
                                <p className="history">
                                    Senha de acesso: {data?.access_password}
                                </p>
                            </CardService>
                        )}
                        {(
                            data?.step?.name !== "blocked" &&
                            data?.step?.name !== "hague" &&
                            data?.step?.name !== "complete_documentation"
                        ) &&
                        data?.comments.slice(1, data?.comments.length).map((comments) => (
                            <>
                                <p className="history">
                                    { comments.text }
                                </p>
                                <p className="date">dia {comments?.updated_at && format(parseISO(comments?.updated_at), "dd/MM/yyyy")}</p>
                            </>
                        ))}
                </>
            } */}
            {next &&
                <>
                    <CardService className="next">
                        <AccessTimeIcon />
                        <p className="service">procedimento não iniciado</p>
                        <p className="history">
                            Para dar andamento ao processo de {next?.applicant?.name}
                            {" "}
                            <strong>
                                será necessário concluir os pedidos anteriores!
                            </strong>
                        </p>
                    </CardService>
                </>
            }
            {complete &&
                <>
                    <CardService className="next">
                        <img src={Check} alt="Procedimento Finalizado"/>
                        <p className="service">procedimento finalizado</p>
                        <p className="history">Oba! Esse procedimento foi concluído.</p>
                    </CardService>
                </>
            }
        </Container>
    );
}