// @ts-nocheck
import { useRef, useState, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";

import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import {
  Container,
  Content,
  WhiteBox,
  CloseButton,
  BoxContent,
  BoxImage,
  CardButtons,
  ButtonCard,
} from "./styles";

import CancelIcon from "@material-ui/icons/Cancel";
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { Input } from "../../components/Form";
import Button from "../../components/Button";

import CheckGrande from "../../assets/boas-vindas/check-grande.png";
import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo-white.png";

export function ResetPassword() {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);

  const { addToast } = useToast();

  function handleOpenModal(e) {
    setOpen(true);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }

  function handleCloseModal(e) {
    setVisible(false);
    setTimeout(() => {
      setOpen(false);
    }, 600);
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          senha: Yup.string().required("Senha obrigatória"),
          confirmar_senha: Yup.string().oneOf(
            [Yup.ref("senha"), null],
            "Senhas não coincidem"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const getToken = await api
          .post("oauth/token", {
            grant_type: "client_credentials",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
          })
          .then((response) => {
            return response.data;
          });

        const token = new URLSearchParams(location.search).get("token");

        if (!token) {
          throw new Error();
        }

        await api.post(
          "central/reset-password",
          {
            password: data?.senha,
            password_confirmation: data?.confirmar_senha,
            token,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken?.access_token}`,
            },
          }
        );

        handleOpenModal(true);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        addToast({
          type: "error",
          title: "Erro",
          description: "Não possível atualizar sua senha!",
        });
      } finally {
        setLoading(false);
      }
    },
    [location.search, addToast]
  );

  return (
    <Container>
      <img className="mobile" src={logoWhite} alt="Cidadania Já" />
      <Content>
        <img
          className="desktop"
          src={logo}
          alt="Cidadania Já"
          style={{ maxWidth: "184px" }}
        />
        <h3>Esqueci minha senha</h3>
        <p className="description">
          Preencha os campos abaixo para redefinir a sua senha.
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* <Input
                        type="text"
                        label="E-mail cadastrado"
                        icon={<MailOutlineIcon />}
                        name="email"
                        placeholder="seuemail@email.com"
                    /> */}
          <Input
            type={revealPassword ? "text" : "password"}
            label="Nova senha"
            icon={<LockOpenIcon />}
            name="senha"
            placeholder="Insira nova senha aqui"
            iconPassword
            changePassword={() => setRevealPassword(!revealPassword)}
            visible={revealPassword}
          />
          <Input
            type={revealConfirmPassword ? "text" : "password"}
            label="Confirmar nova senha"
            icon={<LockOpenIcon />}
            name="confirmar_senha"
            placeholder="Confirmar nova senha"
            iconPassword
            changePassword={() =>
              setRevealConfirmPassword(!revealConfirmPassword)
            }
            visible={revealConfirmPassword}
          />
          <Button
            type="submit"
            btType="var(--principal-40)"
            color="var(--cinza-100)"
            label="Redefinir senha"
            margin="1.5rem 0 0 0"
            full
            padding="1rem 0"
            loading={loading}
            disabled={loading}
          />
          <span className="resetPassword">
            <p>Lembrou sua senha antiga?</p>
            <Link to="/login">Voltar para login</Link>
          </span>
        </Form>
      </Content>
      <WhiteBox open={open} visible={visible}>
        <CloseButton onClick={() => handleCloseModal()}>
          Fechar
          <CancelIcon />
        </CloseButton>
        <BoxContent>
          <BoxImage>
            <img src={CheckGrande} alt="Check" />
          </BoxImage>
          <h4>Senha alterada com sucesso</h4>
          <p className="body">
            Pronto, agora você já pode acessar a Central e acompanhar os seus
            pedidos
          </p>
          <CardButtons className="marginButton">
            <ButtonCard
              bgColor="var(--principal-40)"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              Fazer login
            </ButtonCard>
          </CardButtons>
        </BoxContent>
      </WhiteBox>
    </Container>
  );
}
