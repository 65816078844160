// @ts-nocheck
// @ts-ignore
import { withRouter } from 'react-router-dom';

const componentDidUpdate = (prevProps) => {
  const { location: { pathname } } = this.props;
  const previousLocation = prevProps.location.pathname;

  if (pathname !== previousLocation) {
    window.Appcues.page();
  }
}

// @ts-ignore
export default withRouter(componentDidUpdate);
