import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  > svg {
    font-size: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  > div {
    display: flex;
    flex-direction: column;
    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-weight: 700;
      color: var(--principal-50);
      margin: 0;
      &.title {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0;
        color: var(--cinza-90);
      }
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  font-size: 15px;
  line-height: 24px;
  line-height: 0.2px;
  font-weight: 700;
  border: 0;
  color: var(--principal-50);
  outline: none;
`;
