// @ts-nocheck
import { useEffect, useCallback, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { Container, Body, Arrow } from "./styles";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ReactComponent as ActivityCheck } from "../../assets/icons/activity-check.svg";
import { ReactComponent as ActivityAlert } from "../../assets/icons/activity-alert.svg";
import { ReactComponent as ActivityError } from "../../assets/icons/activity-error.svg";
import { ReactComponent as ActivityWaiting } from "../../assets/icons/activity-waiting.svg";
import { ReactComponent as ActivityUser } from "../../assets/icons/notification-user.svg";
import { ReactComponent as ActivityFileBlank } from "../../assets/icons/activity-file-blank.svg";
import { ReactComponent as ActivityNote } from "../../assets/icons/activity-note.svg";

export default function ActivityBox({ data }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpand = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    let stats;
    switch (data?.current_step) {
        case "documentation":
            stats = "Documentação";
            break;
        case "incomplete_documentation":
            stats = "Documentação";
            break;
        case "blocked":
            stats = "Análise";
            break;
        case "complete_documentation":
            stats = "Análise";
            break;
        case "hague":
            stats = "Apostilamento";
            break;
        case "docs_ok":
            stats = "Apostilamento";
            break;
        case "unblocked":
            stats = "Apostilamento";
            break;
        case "post":
            stats = "Despacho";
            break;
        case "blocked_post":
            stats = "Despacho";
            break;
        case "monitoring":
            stats = "Acompanhamento";
            break;
        case "requirement":
            stats = "Acompanhamento";
            break;
        case "approved":
            stats = "Acompanhamento";
            break;
        case "dispatched":
            stats = "Acompanhamento";
            break;
        default:
            break;
    }

    let icon;
    switch (data?.category) {
        case "GENERAL":
            icon = <ActivityCheck />;
            break;
        case "GENERAL_WITH_ACTIONS":
            icon = <ActivityAlert />;
            break;
        case "WARNING":
            icon = <ActivityWaiting />;
            break;
        case "ATTENTION":
            icon = <ActivityError />;
            break;
        case "ATTENTION_WITH_ACTIONS":
            icon = <ActivityError />;
            break;
        default:
            break;
    }

    return (
        <Container key={data?.id}>
            {icon}
            <div>
                <p className="title">{!data?.title ? data?.body : data?.title}</p>
                <Body active={expanded || data?.category === "GENERAL_WITH_ACTIONS" || data?.category === "GENERAL_WITH_ACTIONS"}>
                    {/* <p className={text.length > 70 && !expanded && "hidden"}>{text}</p> */}
                    <p>{data?.title ? data?.body : ''}</p>
                    {(data?.category === "GENERAL_WITH_ACTIONS" || data?.category === "ATTENTION_WITH_ACTIONS") && (
                        <div>
                            {data?.category === "GENERAL_WITH_ACTIONS" && 
                                (data?.current_step === "documentation" ||
                                data?.current_step === "incomplete_documentation") && (
                                    <a href="/">
                                        Ir para Meus Pedidos
                                    </a>
                            )}
                            {data?.category === "ATTENTION_WITH_ACTIONS" && 
                                (data?.current_step === "requirement" ||
                                data?.current_step === "reject" ||
                                data?.current_step === "blocked" ||
                                data?.current_step === "blocked_post") && (
                                    <a
                                        className="no-background"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://api.whatsapp.com/send?phone=5521990257203&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Vim%20da%20Central%20do%20Cliente%20mas%20continuo%20com%20d%C3%BAvida!%20Poderia%20me%20ajudar%3F"
                                    >
                                        Falar com atendimento
                                    </a>
                            )}
                            {data?.category === "GENERAL_WITH_ACTIONS" && 
                                (data?.current_step === "monitoring" ||
                                data?.current_step === "approved" ||
                                data?.current_step === "dispatched" ||
                                data?.current_step === "requirement") && 
                                data?.title === "Senha recebida" && (
                                    <a
                                        className="no-background"
                                        target="_blank" 
                                        rel="noreferrer"
                                        href="https://nacionalidade.justica.gov.pt"
                                    >
                                        Falar com atendimento
                                    </a>
                            )}
                        </div>
                    )}
                </Body>
                <div>
                    <div>
                        <ActivityUser />
                        <p>{data?.applicant}</p>
                    </div>
                    <div>
                        <ActivityFileBlank />
                        <p>{data?.process}</p>
                    </div>
                    <div>
                        <ActivityNote />
                        <p>{stats}</p>
                    </div>
                </div>
            </div>
            {(data?.body?.length > 70 &&
                (data?.category !== "GENERAL_WITH_ACTIONS" || data?.category !== "ATTENTION_WITH_ACTIONS")) && (
                <Arrow active={expanded} onClick={() => handleExpand()}>
                    <ExpandMoreIcon />
                </Arrow>
            )}
        </Container>
    );
}