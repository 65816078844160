import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  > h4 {
    font-size: 17px !important;
    line-height: 28px !important;
    color: var(--cinza-80) !important;
    margin: 0 !important;
  }

  > div {
    display: flex;
    gap: 8px;

    > svg {
      color: var(--principal-40);
    }

    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-80);
      margin: 0;
    }
  }

  > hr { 
    height: 1px;
    background: var(--cinza-40);
    border: 0;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;
