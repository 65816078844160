// @ts-nocheck
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Items = styled.div`
  display: flex;
  margin-right: 100px;
`;

export const Item = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.71875rem;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  
  ${({ efeitoHover }) => efeitoHover ? css`
    font-weight: 700;
    color: var(--principal-50);
  ` : css`
    color: var(--cinza-100);
  `};

  ${({ green }) => 
  green &&
    css`
      border: none;
      background: var(--principal-40);
    `}

  &:hover {
    color: var(--principal-50);
  }

  &:after {
    content: "";
    position: absolute;
    background-color: var(--principal-50);
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    opacity: ${({ efeitoHover }) => efeitoHover ? 1 : 0};
    left: auto;
    bottom: -1rem;
    transition: 0.5s;
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all 0.5s ease-in-out;
  background: var(--white);
  width: 360px;
  max-height: 0;
  overflow-y: hidden;
  top: 100% !important;
  left: 0;
  z-index: 10;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 0 25px -5px rgba(20, 20, 42, 0.1), 0 0 50px -5px rgba(20, 20, 42, 0.2);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 0 1px rgba(20, 20, 42, 0.06));

  ${({ active }) => 
    active && 
    css `
      max-height: 700px;
    `}

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 20px 32px 20px 16px;
    cursor: pointer;

    svg {
      &.icon-spin {
        animation: iconSpin 0.8s infinite linear;
      }

      @keyframes iconSpin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-359deg);
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      > span {
        display: flex;
        gap: 4px;

        > span {
          font-size: 13px;
          line-height: 20px;
          color: var(--cinza-60);
          letter-spacing: 0.2px;
        }

        &.header {
          justify-content: space-between;
          gap: 0;

          > span {
            font-weight: 700;
          }
        }
      }

      p {
        &.title {
          font-size: 17px;
          line-height: 28px;
          color: var(--cinza-90);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 220px;
        }

        &.body {
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--cinza-70);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 240px;
          /* display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; */
          margin-bottom: 4px;
          padding: 0 !important;
        }
      }
    }
  }

  > hr {
    border: 0;
    border-bottom: 1px solid var(--cinza-30);
    margin: 0 16px;
  }

  > a {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 17px;
    line-height: 28px;
    font-weight: 700;
    color: var(--principal-50);
    padding: 16px 0;
    text-decoration: none;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;

    ${({ active }) =>
      active ? 
      css `
        top: 160%;
        right: 4px;

        &:after {
          left: 87%;
        }
      ` :
      css `
        display: none;
      `
    }
  }
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;

  > p {
    font-size: 15px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: var(--cinza-90);
    text-transform: uppercase;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--cinza-90);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0ch.2px;
  font-weight: 700;
  border: none;
  outline: none;
  background: transparent;
`;
