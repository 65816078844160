/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useRef, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from "@unform/web";
import * as Yup from 'yup';

import {
    Container,
    PageContent,
    Text,
    Title,
    Wrap,
    Button
} from "./styles";

import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';

import { Input, InputMask, Textarea } from "../../components/Form";

import Header from "../../components/Header";

import { useAuth } from '../../hooks/auth';
import { useToast } from "../../hooks/toast";
import api from "../../services/api";

export function ContatoSugestoes() {
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const { user } = useAuth();
    const { addToast } = useToast();

    const handleSubmit = useCallback(async (data) => {
        try {
            setLoading(true);

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                subject: Yup.string().required('Assunto obrigatório'),
                phone: Yup.string().required('Telefone obrigatório'),
                text: Yup.string().required('Descrição obrigatória')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.post('central/common/send-support-email', {
                ...data,
                type: 'SERVICES'
            });

            addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Sugestão enviada com sucesso!'
            });

            history.push('/');
        } catch(err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não possível enviar sua sugestão!'
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        window.Appcues.identify(user.id, user)
    }, [user]);

    return (
        <>
            <Container>
                <Header />
                <PageContent>
                    <Text>
                        <p>Tem alguma sugestão para compartilhar conosco?</p>
                        <Title>Envie sua sugestão para o Cidadania Já</Title>
                    </Text>
                    <Form ref={formRef} onSubmit={(data) => handleSubmit(data)}>
                        <Wrap>
                            <Input
                                name="subject"
                                label="Assunto"
                                required
                            />
                            <InputMask
                                type="telefone"
                                name="phone"
                                label="Telefone"
                                required
                            />
                        </Wrap>
                        <Wrap>
                            <Textarea
                                name="text"
                                label="Descreva aqui..."
                                rows="5"
                                required
                            />
                        </Wrap>
                        <Button type="submit" disabled={loading}>
                            {loading ? (
                                <RotateLeftOutlinedIcon className="icon-spin" />
                            ) : (
                                <>
                                    Enviar
                                </>
                            )}
                        </Button>
                    </Form>
                </PageContent>
            </Container>
        </>
    );
}