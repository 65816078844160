import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (error.response.data?.code === 'token expired') {

        } else {
            localStorage.removeItem('@Central:token');
            localStorage.removeItem('@Central:user');
            localStorage.removeItem('apc_ann_errors');
            localStorage.removeItem('apc_user_id');
            localStorage.removeItem('apc_debug_url');
            localStorage.removeItem('apc_local_id');
            localStorage.removeItem('@Central:requirement');
            localStorage.removeItem('@Central:notariOffice');
    
            window.location.href='/login';
        }
    }

    return Promise.reject(error);
});


export default api;