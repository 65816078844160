export const IDs = [
    {
        id: 28
    },
    {
        id: 30
    },
    {
        id: 43
    },
    {
        id: 44
    },
    {
        id: 55
    },
    {
        id: 56
    },
    {
        id: 59
    },
    {
        id: 62
    },
    {
        id: 63
    },
    {
        id: 67
    },
    {
        id: 123
    },
    {
        id: 127
    },
    {
        id: 128
    },
    {
        id: 133
    },
    {
        id: 134
    },
    {
        id: 137
    },
    {
        id: 140
    },
    {
        id: 141
    },
    {
        id: 145
    },
    {
        id: 146
    },
    {
        id: 157
    },
    {
        id: 183
    },
    {
        id: 200
    },
    {
        id: 214
    },
    {
        id: 218
    },
    {
        id: 223
    },
    {
        id: 229
    },
    {
        id: 233
    },
    {
        id: 234
    },
    {
        id: 235
    },
    {
        id: 260
    },
    {
        id: 268
    },
    {
        id: 270
    },
    {
        id: 271
    },
    {
        id: 273
    },
    {
        id: 274
    },
    {
        id: 275
    }
];