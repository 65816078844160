// @ts-nocheck
import React, { useEffect } from 'react';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useToast } from '../../../hooks/toast';

import { Container } from './styles';

const icons = {
  success: <CheckCircleOutlinedIcon />,
  error: <ErrorOutlineIcon />
};

const Toast = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <Container
      type={message.type}
      hasDescription={!!message.description}
      style={style}
    >
      {icons[message.type]}
      <div>
        <strong>{message.title}</strong>
        {message.description && (
          <p>{message.description}</p>
        )}
      </div>
      <button type="button" onClick={() => removeToast(message.id)}>
        <HighlightOffIcon />
      </button>
    </Container>
  );
};

export default Toast;
