// @ts-nocheck
import React, { createContext, useCallback, useContext, useState } from 'react';

const RequirementContext = createContext({});

export const RequirementProvider = ({ children }) => {
  const [requirement, setRequirement] = useState(() => {
    const getRequirement = localStorage.getItem('@Central:requirement');

    if(getRequirement) {
      return JSON.parse(getRequirement);
    }

    return {};
  });

  const addRequirement = useCallback((object) => {
    localStorage.setItem('@Central:requirement', JSON.stringify(object));

    setRequirement(object);
  }, []);

  const removeRequirement = useCallback(() => {
    localStorage.removeItem('@Central:requirement');

    setRequirement({});
  }, []);

  return (
    <RequirementContext.Provider value={{ addRequirement, removeRequirement, requirement }}>
      {children}
    </RequirementContext.Provider>
  );
}

export const useRequirement = () => {
  const context = useContext(RequirementContext);

  if(!context) {
    throw new Error('useRequirement só pode ser usado com o RequirementProvider');
  }

  return context;
}
