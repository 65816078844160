import styled, { css } from "styled-components";
import { shade } from 'polished';

export const CardService = styled.a`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
  text-decoration: none;
  cursor: pointer;

  & + div {
    margin-top: 32px;
  }

  > p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: 700;
    color: var(--cinza-100);
    margin-bottom: 0;
  }
`;

export const CardStats = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > p {
    &:first-child {
      font-size: 0.9375rem;
      line-height: 1.75rem;
      letter-spacing: 1.5px;
      font-weight: 700;
      color: var(--cinza-100);
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    &.followUp {
      font-size: 15;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-70);
    }
  }

  > div.indicators {
    display: flex;
    justify-content: space-between;

    > p {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: var(--cinza-100);
    }
  }

  > div.blocks {
    display: flex;
    flex-direction: column;

    > hr {
      width: 0;
      border: 0;
    }

    > strong {
      font-size: 17px;
      line-height: 28px;
      color: var(--cinza-90);
      margin-bottom: 8px;
    }

    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-80);
    }

    & + div {
      > hr {
        width: 100%;
        background: var(--cinza-30);
        height: 1px;
        margin: 24px 0;
        border: 0;
      }
    }
  }

  > div.historic {
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease-in-out;
    overflow: hidden;
    max-height: 120px;

    &.expand {
      max-height: 790px;
    }
  }

  > button {
    display: flex;
    align-items: center;
    background: transparent;
    gap: 8px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: 700;
    margin-top: 24px;
    border: 0;
    color: var(--principal-50);
    outline: none;

    > svg {
      overflow: hidden;
      transition-duration: 1s;
      transition-property: transform;

      &.rotate {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
`;

export const Historic = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.8s ease-in-out;
  overflow: hidden;
  max-height: ${({ height }) => [height]};

  &.expand {
    max-height: 1280px;
  }
`;

export const Prediction = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  margin-top: 16px;

  > svg {
    color: var(--principal-50);
  }

  > p {
    font-size: 15px;
    line-height: 24px;
    color: var(--cinza-90);
  }

  > div {
    display: flex;
    flex-direction: column;

    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-90);
    }
  }
`;

export const Contact = styled.div`
    display: flex;
    gap: 8px;

    > div, a {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
      background: var(--white);
      border: 1px solid var(--cinza-40);
      border-radius: 8px;
      padding: 16px 8px;
      text-decoration: none;

      > img {
        width: fit-content;
      }

      > p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        color: var(--cinza-90);
        margin: 0;
      }
    }
`;

export const CautionBox = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--atencao-10);
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  border-radius: 4px;
  border-left: 4px solid var(--atencao-30);

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    svg {
      width: 24px;
      color: var(--atencao-50);
    }
  }

  p {
    font-size: 15px;
    line-height: 24px;

    &.caution {
      font-weight: 700;
      color: var(--cinza-100);
    }

    &.caution-body {
      color: var(--cinza-90);
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.caution,
      &.caution-body {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;

export const StatsInfo = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    border-radius: 8px;
    padding: 4px 12px 4px 8px;
    margin-bottom: 16px;
    gap: 10px;
    background: ${({ bgColor }) => [bgColor]};
    color: ${({ color }) => [color]};

    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-weight: 700;
    }    

    > svg {
      color: ${({ iconColor }) => [iconColor]};
    }
`;

export const Delay = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ color }) => color ? [color] : ''};
    margin-top: 8px;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
            font-size: 17px;
            line-height: 28px;
            font-weight: 700;
        }
    }

    > p {
        font-size: 15px;
        line-height: 24px;
        color: var(--cinza-80);
        letter-spacing: 0.2px;
    }
`;

export const Bars = styled.div`
    display: flex;
    gap: 2px;
    height: 40px;
    margin-top: 16px;
`;

export const Bar = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    background: var(--cinza-30);
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  padding: 12px 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  outline: none;
  text-decoration: none;

  &.midButton {
    border: none;
  }
`;

export const ButtonBlock = styled.button`
  display: ${props => props.disabled ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-between;
  background: var(--cinza-80);
  border-radius: 8px;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 16px;
  padding: 12px 16px 12px 24px;
  border: 0;
  color: var(--white);
  outline: none;

  &:hover {
    opacity: 0.85;
  }

  &[disabled] {
      cursor: not-allowed;
    }

  .icon-spin {
    animation: iconSpin 0.8s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }
`;
