// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

import { Container, Content } from "./styles";

export const selectCustomStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: `1px #ddd solid`,
        color: '#14142A',
        background: state.isSelected ? '#32C050' : 'transparent',
        padding: 16,
        fontSize: 17,
        boxSizing: 'border-box',
        outline: 'none',
    }),
    input: provided => ({
        ...provided,
        padding: 16,
        fontSize: 17,
        margin: 0,
    }),
    container: provided => ({
        ...provided,
        width: '100%',
        padding: 0,
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
    }),
    placeholder: provided => ({
        ...provided,
        marginLeft: 16,
        fontSize: 17,
        padding: 0,
    }),
    control: provided => ({
        ...provided,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#d0d0d0',
        borderRadius: 2,
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
        height: 'auto',
        outline: 'none',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        marginLeft: 16,
        fontSize: 17,
    }),
    indicatorsContainer: provided => ({
        ...provided,
        fontSize: 10,
        color: '#14142A',
        position: 'relative',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        height: 'auto'
    }),
    menuList: provided => ({
        ...provided,
        height: "165px",
    }),
  };

export function Select({ label, icon, name, ...rest }) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'state.value',
            setValue: (ref, value) => {
                ref.select.setValue(value);
            },
            getValue: (ref) => {
            if (rest.isMulti) {
                if (!ref.state.value) {
                return [];
                }
                return ref.state.value.map(option => option.value);
            }
            if (!ref.state.value) {
                return '';
            }
            return ref.state.value.value;
            },
        });
      }, [name, fieldName, registerField, rest.isMulti]);

    return (
        <Container className='customContainerSelect'>
            {label &&
                <label htmlFor={name}>{label}</label>
            }
            <Content icon={icon}>
                {icon &&
                    <span className="icon">{icon}</span>
                }
                <ReactSelect
                    name={fieldName}
                    styles={rest.style || selectCustomStyles}
                    defaultValue={defaultValue}
                    ref={selectRef}
                    classNamePrefix="react-select"
                    {...rest}
                />
            </Content>
            {error && <span className="error">{error}</span>}
        </Container>
    );
}