// @ts-nocheck
import React, { createContext, useCallback, useContext, useState } from 'react';

const NotaryOfficeContext = createContext({});

export const NotaryOfficeProvider = ({ children }) => {
  const [notaryOffice, setNotaryOffice] = useState(() => {
    const getNotaryOffice = localStorage.getItem('@Central:notariOffice');

    if(getNotaryOffice) {
      return JSON.parse(getNotaryOffice);
    }

    return {};
  });

  const addNotaryOffice = useCallback((notaryOffices) => {
    localStorage.setItem('@Central:notariOffice', JSON.stringify(notaryOffices));

    setNotaryOffice(notaryOffices);
  }, []);

  const removeNotaryOffice = useCallback(() => {
    localStorage.removeItem('@Central:notariOffice');

    setNotaryOffice({});
  }, []);

  return (
    <NotaryOfficeContext.Provider value={{ addNotaryOffice, removeNotaryOffice, notaryOffice }}>
      {children}
    </NotaryOfficeContext.Provider>
  );
}

export const useNotaryOffice = () => {
  const context = useContext(NotaryOfficeContext);

  if(!context) {
    throw new Error('useNotaryOffice só pode ser usado com o NotaryOfficeProvider');
  }

  return context;
}
