import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 6.6875rem;
  width: 100vw;
  height: auto;

  @media screen and (max-width: 950px) {
    padding-bottom: 32px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50.5rem;
  margin: 0 auto;

  > h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.75rem;
    color: var(--cinza-100);
  }

  > p {
    font-size: var(--font-17);
    line-height: 1.75rem;
    color: var(--cinza-80);
    text-align: center;
    max-width: 37.5rem;
  }

  @media screen and (max-width: 950px) {
    align-items: flex-start;
    padding: 0 16px;

    h3 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 17px;
      line-height: 28px;
      text-align: left;
    }
  }
`;

export const PageHeader = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;

  img {
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    min-height: 318px;

    img {
      width: 100%;
      height: 318px;
      object-fit: cover;
      object-position: center;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  max-width: 50.5rem;

  @media screen and (max-width: 950px) {
    padding: 0 16px;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  hr {
    display: none;
  }

  .customContainerInput {
    margin: 0;
  }

  .customInput {
    border: none;
    box-shadow: var(--shadow-baixo-grande);
    height: 3.75rem;
    margin: 0;

    svg {
        color: var(--principal-40);
    }
  }

  button {
    position: absolute;
    max-height: 2.75rem;
    top: 0.5rem;
    right: 0.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    background: var(--white);
    box-shadow: var(--shadow-baixo-pequena);
    border-radius: 0.5rem;
    padding: 16px;

    hr {
      display: flex;
      width: 100%;
      border: 1px solid var(--cinza-30);
      margin-bottom: 24px;
    }

    .customInput {
      box-shadow: none;
    }

    button {
      position: relative;
      font-size: 15px;
      line-height: 24px;
      max-width: 100%;
      padding: 10px 0;
      top: auto;
      right: auto;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--cinza-100);

  @media screen and (max-width: 950px) {
    font-size: 32px;
    line-height: 36px;
    text-align: left;
    max-width: 328px;
  }
`;

export const Questions = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;
`;

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  transition: 400ms ease;
  padding: 1.5rem;
  width: 100%;
  box-shadow: var(--shadow-baixo-pequena);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background: var(--principal-10);
  }

  &:last-child {
      margin: 0;
  }

  @media screen and (max-width: 950px) {
    border: 1px solid var(--cinza-30);
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    font-size: var(--font-19);
    line-height: 1.75rem;
    color: var(--principal-50);
    font-weight: 700;
    margin: 0;
  }

  svg {
    color: var(--principal-50);
  }

  @media screen and (max-width: 950px) {
    p {
      font-size: 19px;
      line-height: 28px;
      max-width: 210px;
      text-align: left;
    }
  }
`;

export const AccordionContent = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease-in-out;
    overflow: hidden;
    width: 100%;
    max-height: 0;

    p {
        font-size: 17px;
        line-height: 1.75rem;
        color: var(--cinza-80);
        width: 100%;
        margin-bottom: 0.5rem;
        text-align: left;        

        &:last-child {
            margin-bottom: 0;
        }
    }

    ${({ active }) => active
        ? `max-height: 1280px;
        padding-top: 1rem;`
        : 0
    }
`;

export const ContactBoxes = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  margin-top: 2rem;

  svg {
    font-size: 2.5rem;
    color: var(--principal-40);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: var(--font-17);
    line-height: 1.75rem;
    text-align: center;
    color: var(--cinza-90);
    max-width: 10rem;
  }

  span {
    font-size: var(--font-15);
    line-height: 1.5rem;
    text-align: center;
    color: var(--cinza-60);
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--cinza-40);

  p {
    word-break: break-all;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-decoration: none;
  }
`;
