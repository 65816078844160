// @ts-nocheck
import { useState, useRef, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import { Container, Content } from "./styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { Input } from "../../components/Form";
import Button from "../../components/Button";

import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo-white.png";

export function RecoveryPassword() {
  const formRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required("E-mail obrigatório")
          .email("Digite um e-mail válido"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const getToken = await api
        .post("oauth/token", {
          grant_type: "client_credentials",
          client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        })
        .then((response) => {
          return response.data;
        });

      await api.post("central/recovery-password", data, {
        headers: {
          Authorization: `Bearer ${getToken?.access_token}`,
        },
      });

      addToast({
        type: "success",
        title: "Sucesso",
        description: "Confira seu e-mail para criar uma nova senha!",
      });

      setTimeout(() => {
        history.push("/login");
      }, 3000);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      addToast({
        type: "error",
        title: "Erro",
        description: "Não foi possível enviar e-mail de recuperação!",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <img className="mobile" src={logoWhite} alt="Cidadania Já" />
      <Content>
        <img
          className="desktop"
          src={logo}
          alt="Cidadania Já"
          style={{ maxWidth: "184px" }}
        />
        <h3>Esqueci minha senha</h3>
        <p className="description">
          Insira seu e-mail no campo abaixo para enviarmos um link para você
          redefinir sua senha.
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            type="text"
            label="E-mail cadastrado"
            icon={<MailOutlineIcon />}
            name="email"
            placeholder="seuemail@email.com"
          />
          <Button
            type="submit"
            btType="var(--principal-40)"
            color="var(--cinza-100)"
            label="Redefinir a senha por e-mail"
            margin="1.5rem 0 0 0"
            full
            padding="1rem 0"
            loading={loading}
            disabled={loading}
          />
          <span className="resetPassword">
            <p>Lembrou a senha?</p>
            <Link to="/login">Voltar para login</Link>
          </span>
        </Form>
      </Content>
    </Container>
  );
}
