// @ts-nocheck
import { useState, useEffect } from 'react';

import {
  Container,
  PageContent,
  PageHeader,
  Content
} from "./styles";

import LogoImpressao from '../../assets/logo-impressao.png';

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';

import { useNotaryOffice } from "../../hooks/notaryOffice";
import api from "../../services/api";

export function ImpressaoCartorios() {
  const { notaryOffice } = useNotaryOffice();

  const [notaryOffices, setNotaryOffices] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await api.get(`central/notary-offices?city=${notaryOffice?.city?.value}&district=${notaryOffice?.neighborhood}`)
          .then(response => {
          setNotaryOffices(response.data.data);
          setTimeout(() => {    
            window.print();
          }, 600);
      });
    }
    fetchData();
    setTimeout(() => {
      window.close();
    }, 1500);
  }, [notaryOffice]);

  return (
    <Container id="print">
      <PageContent>
        <PageHeader>
          <img src={LogoImpressao} alt="Logo Cidadania" />
          <div>
            <p>Listagem dos cartórios</p>
            <div>
              <p>{notaryOffice?.state?.label}</p>
              <span />
              <p>{notaryOffice?.city?.label}</p>
              <span />
              <p>{notaryOffice?.neighborhood}</p>
            </div>
          </div>
        </PageHeader>
        {notaryOffices && notaryOffices?.map((item) => (
          <Content key={item.id}>
            <h4>{item.official_name}</h4>
            <div>
              <LocationOnOutlinedIcon />
              <p>
                {item.address}{" - "}
                {item.district}{" - "}
                {item.zip_code}{" - "}
                {item.coverage}
              </p>
            </div>
            <div>
              <PhoneOutlinedIcon />
              <p>{item.phone}</p>
            </div>
            <div>
              <MailOutlinedIcon />
              <p>{item.email}</p>
            </div>
          </Content>
        ))}
      </PageContent>
    </Container>
  );
}
