// @ts-nocheck
import React, { useState } from "react";

import useWindowSize from "../../hooks/useWindowSize";

import { MenuDesktop as Menu, MenuMobile, MenuBars, MenuNotifications } from "../../components/Menu";

import {
  Ghost,
  Container,
  Logo,
  Content,
  Wrap,
  MenuButton,
} from "./styles";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const window = useWindowSize();

  // useEffect(() => {
  //   if (menuOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style = null;
  //   }
  // }, [menuOpen]);

  return (
    <>
      <Container>
        <Content>
          <a href="/">
            <Logo />
          </a>
            {window.width < 950 && (
              <>
                <MenuButton type="button" onClick={() => setMenuOpen(!menuOpen)}>
                  {menuOpen ? "Fechar" : "Menu"}
                  <MenuBars open={menuOpen} size={50} onClick={() => setMenuOpen(!menuOpen)} />
                </MenuButton>
              </>
            )}
            {window.width > 950 ? (
              <Menu />
            ) : (
              <>
                <MenuNotifications />
                <MenuMobile open={menuOpen} setOpen={setMenuOpen} />
              </>
            )}
        </Content>
      </Container>
    </>
  );
}
