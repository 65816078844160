// @ts-nocheck
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../../../hooks/auth";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import Pedidos from '../../../assets/menu/mobile/pedidos.svg';
import Portugueses from '../../../assets/menu/mobile/portugueses.svg';
import Pagamentos from '../../../assets/menu/mobile/pagamentos.svg';
import Perguntas from '../../../assets/menu/mobile/perguntas.svg';
import Contato from '../../../assets/menu/mobile/contato.svg';

import {
  Container,
  Items,
  Item,
  Sub,
  SubItem,
  Scroll,
} from "./styles";

const subMenus = {
  perfil: false,
  contato: false
};

export default function Menu({ open, setOpen }) {
  let history = useHistory();
  const [subMenu, setSubMenu] = useState(subMenus);

  const { user, signOut } = useAuth();

  useEffect(() => {
    if (!open) {
      setSubMenu(subMenus);
    }
  }, [open]);

  function handleSubMenu(item, toggle) {
    setSubMenu((state) => ({ ...state, [item]: toggle }));
  }

  function handleNavigate(route) {
    setOpen(false);
    history.push(route);
  }

  const handleLogout = useCallback(async () => {
    try {
      signOut();

      history.push('/login');
    } catch(err) {
      console.log("Logout falhou", err)
    }
  }, [signOut]);

  return (
    <Container open={open}>
      <Items>
        <Scroll>
          <Item onClick={() => handleNavigate("/")}>
            <span>
              <img src={Pedidos} alt="Meus Pedidos"/>
              Meus pedidos
            </span>
            <ChevronRightIcon />
          </Item>
          <Item onClick={() => handleNavigate("/portugueses")}>
            <span>
              <img src={Portugueses} alt="Meus Pedidos"/>
              Portugueses
            </span>
            <ChevronRightIcon />
          </Item>
          <Item onClick={() => handleNavigate("/pagamentos")}>
            <span>
              <img src={Pagamentos} alt="Meus Pedidos"/>
              Pagamentos
            </span>
            <ChevronRightIcon />
          </Item>
          <Item onClick={() => handleNavigate("/perguntas-frequentes")}>
            <span>
              <img src={Perguntas} alt="Meus Pedidos"/>
              Perguntas Frequentes
            </span>
            <ChevronRightIcon />
          </Item>
        </Scroll>
        <li>
          <Item
            onClick={() => handleSubMenu("contato", !subMenu.contato)}
            green={subMenu.contato}
            className="contactButton"
          >
            <span>
              <img src={Contato} alt="Contato"/>
              Contato
            </span>
            <ChevronRightIcon />
            <Sub open={subMenu.contato}>
              <SubItem onClick={() => handleNavigate("/contato")}>
                Contato
              </SubItem>
              <SubItem onClick={() => handleNavigate("/sugestoes")}>
                Sugestões
              </SubItem>
              <SubItem onClick={() => handleNavigate("/criticas")}>
                Críticas
              </SubItem>
            </Sub>
          </Item>
        </li>
        <li>
          <Item onClick={() => handleSubMenu("perfil", !subMenu.perfil)}
            green={subMenu.perfil}
            className="accordionButton">
              Meu perfil
              <KeyboardArrowDownOutlinedIcon />
              <Sub open={subMenu.perfil}>
                {user?.user_type !== 'PERSON' && (
                  <SubItem onClick={() => handleNavigate("/configuracoes")}>
                    {/* <img src={Settings} alt="Settings"/> */}
                    Configuraçõess
                  </SubItem>
                )}
                <SubItem onClick={() => handleNavigate("/registro-de-atividades")}>
                  {/* <img src={Settings} alt="Settings"/> */}
                  Registro de atividades
                </SubItem>
                <SubItem onClick={() => handleNavigate("/troca-de-senha")}>
                  {/* <img src={Settings} alt="Settings"/> */}
                  Troca de senha
                </SubItem>
                <SubItem onClick={handleLogout}>
                  {/* <img src={LogOut} alt="Logout"/> */}
                  Sair
                </SubItem>
              </Sub>
            </Item>
          </li>
      </Items>
    </Container>
  );
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};