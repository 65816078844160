import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { RequirementProvider } from './requirement';
import { NotaryOfficeProvider } from './notaryOffice';

const AppProvider = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <RequirementProvider>
        <NotaryOfficeProvider>
          {children}
        </NotaryOfficeProvider>
      </RequirementProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
