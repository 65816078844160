// @ts-nocheck
import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1.063rem;
  font-weight: 700;
  line-height: 1.5rem;
  max-height: 54px;
  outline: none;

  width: ${({ full }) => (full ? "100%" : "max-content")};
  max-width: ${({ width }) => (width ? [width] : "none")};
  padding: ${({ icon, padding }) => (icon ? "1rem 1.5remx" : [padding])};
  margin: ${({ margin }) => (margin ? [margin] : 0)};
  background-color: ${({ btType }) => (btType ? [btType] : "#f2f2f2")};
  color: ${({ btType, color }) => (btType === "disable" ? "#f2f2f2" : [color])};
  border: ${({ btBorder }) => btBorder ? [btBorder] : "none"};
  transition: 300ms ease;

  &:hover {
    opacity: 0.85;
  }

  svg {
    margin-right: 8px;
    color: ${({ btType }) => (btType === "disable" ? "#f2f2f2" : "#ffffff")};
  }

  img {
    margin-right: 8px;
  }

  &[disabled] {
      cursor: not-allowed;
    }

  .icon-spin {
    animation: iconSpin 0.8s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }
`;
