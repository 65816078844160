// @ts-nocheck
import styled from 'styled-components';
import { shade } from 'polished';

export const PageContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  top: 0;
  transition: all 0.5s ease-in-out;
  opacity: ${({ open }) => open ? 1 : 0};

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--white);
  width: 100%;
  max-width: 600px;
  max-height: 95%;
  padding: 1rem 1.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-baixo-grande);

  @media screen and (max-width: 950px) {
    width: 95%;
  }

  @media screen and (max-width: 767px) {
    /* height: 95vh; */
    top: 16px;
  }
`;

export const BoxContent = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  h4 {
    font-size: var(--font-21);
    line-height: 1.75rem;
    font-weight: 700;
    width: 100%;
    color: var(--cinza-100);
  }

  p {
    font-size: var(--font-17);
    line-height: 1.75rem;
    color: var(--cinza-90);

    span {
      color: var(--principal-50);
      font-weight: 700;
    }
  }

  > div.price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    margin-top: 16px;

    h5 {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-weight: 400;
      color: var(--cinza-60);
      text-transform: uppercase;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: bold;
      color: var(--principal-50);

      span {
        font-size: 15px;
        line-height: 28px;
        margin-right: 8px;
      }
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.body {
        font-size: 17px;
        line-height: 28px;
        text-align: left;
      }
    }
  }
`;

export const CloseButton = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--principal-50);
  top: 1rem;
  right: 1.5rem;
  z-index: 10;
  cursor: pointer;

  svg {
    color: var(--principal-40);
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  position: relative;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  background: transparent;
  width: 100%;
  padding-bottom: 1px;

  &.marginButton {
    margin-top: 2rem;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--principal-40);
    border-radius: 0.5rem;
    font-size: var(--font-17);
    line-height: 1.5rem;
    font-weight: 700;
    padding: 0.75rem 0;
    border: 0;
    color: var(--cinza-100);
    width: 100%;
    text-decoration: none;
    transition: background 0.2s;

    &.midButton {
      border: none;
    }

    &.isDisabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;
    }

    &:hover {
      background: ${shade(0.1, "#32C050")};
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: var(--font-17);
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  outline: none;

  &.midButton {
    border: none;
  }

  svg {
    margin-left: 0.5rem;
  }
`;
