// @ts-nocheck
import React, { useEffect } from 'react';
import { useHistory, BrowserRouter as Router, Route } from 'react-router-dom';
import { GlobalStyle } from 'styles/global';

import Routes from "./routes";

import AppProvider from "./hooks";

import componentDidUpdate from './services/appcues';

export function App() {
  const history = useHistory();

  const storageUser = localStorage.getItem('@Central:user');
  const user = JSON.parse(storageUser);
  
  useEffect(() => {
    if(window.location.pathname !== "/impressao" && window.location.pathname !== "/impressao-cartorios") {
      var script = document.createElement("script");
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = "var $_Huggy = { defaultCountry: '+55', uuid: 'd8ed4129-f899-4ca2-b60c-2a0dd27d1062' , company: '317059' }; (function(i,s,o,g,r,a,m){ i[r]={context:{id:'73c8311ec498371d95eaa2ab4f9f6aed'}};a=o;o=s.createElement(o); o.async=1;o.src=g;m=s.getElementsByTagName(a)[0];m.parentNode.insertBefore(o,m); })(window,document,'script','https://js.huggy.chat/widget.min.js','pwz');";
      document.body.appendChild(script);
    }

    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date());

    gtag('config', 'G-XEZYJ0C7FC', {
      'user_id': user?.id
    });
  }, [user]);

  return (
    <Router component={componentDidUpdate(App)}>
      <AppProvider>
        <Routes forceRefresh history={history} />
      </AppProvider>
      <GlobalStyle />
    </Router>
  );
}
