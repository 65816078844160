// @ts-nocheck
import { useState, useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import {
    Container,
    Content
} from "./styles";

import { ReactComponent as Check } from "../../../../assets/icons/activity-check.svg";
import { ReactComponent as Alert } from "../../../../assets/icons/activity-alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/activity-error.svg";
import { ReactComponent as Waiting } from "../../../../assets/icons/activity-waiting.svg";

export default function ComponentHistoric({ data, action }) {

    let icon;
    switch (data?.category) {
        case "GENERAL":
            icon = <Check />;
            break;
        case "GENERAL_WITH_ACTIONS":
            icon = <Alert />;
            break;
        case "WARNING":
            icon = <Waiting />;
            break;
        case "ATTENTION":
            icon = <Error />;
            break;
        case "ATTENTION_WITH_ACTIONS":
            icon = <Error />;
            break;
        default:
            break;
    }

    return (
        <Container>
            {icon}
            <Content>
                <span>{format(parseISO(data.date), "dd/MM/yyyy")}</span>
                <p>{data.body}</p>
            </Content>
        </Container>
    );
}