// @ts-nocheck
import styled from "styled-components";

import logo from "../../assets/logo.png";
// import logo from "../../assets/logo-bigode.svg";

export const Ghost = styled.div`
  width: 100%;
  display: none;

  @media screen and (max-width: 950px) {
    height: 87px;
    display: block;
  }

  @media screen and (max-width: 400px) {
    height: 72px;
    display: block;
  }
`;

export const Container = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: #fffffff6;
  z-index: 100;
  box-shadow: var(--shadow-baixo-pequena);

  @media screen and (max-width: 950px) {
    border-bottom: 1px #dbdbdb solid;
    background-color: #ffffff;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  min-height: 5.5rem;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 950px) {
    justify-content: flex-start;
    padding-left: 2rem;
  }

  @media screen and (max-width: 767px) {
    padding-left: 16px;
  }
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  object-fit: cover;
  z-index: 101;
  width: 150px;

  @media screen and (max-width: 951px) {
    width: 130px;
  }

  @media screen and (max-width: 400px) {
    width: 120px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;
`;

export const SocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
  &:last-child {
    margin-right: 0;
  }
  svg {
    color: "#074B5B";
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  color: var(--principal-50);
  font-size: var(--font-17);
  line-height: 1.75rem;
  font-weight: 700;
  z-index: 101;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;

  @media screen and (max-width: 720px) {
    font-size: 17px;
    line-height: 28px;
    right: 16px;
  }

  svg {
    color: "#074B5B";
  }
`;
