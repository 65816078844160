// @ts-nocheck
import { Container, Content } from "./styles";

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';

export default function NotaryOffice({ data }) {
  return (
    <Container key={data?.id}>
      <Content>
        <h4>{data?.official_name}</h4>
        <div>
          <LocationOnOutlinedIcon />
          <p>
            {data?.address}{" - "}
            {data?.district}{" - "}
            {data?.zip_code}{" - "}
            {data?.coverage}
          </p>
        </div>
        <div>
          <PhoneOutlinedIcon />
          <p>{data?.phone}</p>
        </div>
        <div>
          <MailOutlinedIcon />
          <p>{data?.email}</p>
        </div>
        <hr />
      </Content>
    </Container>
  );
}
