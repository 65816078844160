import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  position: relative;

  label {
    font-size: 1.063rem;
    font-weight: bold;
    color: var(--cinza-90);
    letter-spacing: 0.2px;
  }

  span.error {
    font-size: 14px;
    font-weight: 700;
    color: #c53030;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ icon }) => icon ? "0 0 0 3rem" : "0"};
  margin-right: 1.5rem;
  position: relative;
  background: var(--white);
  border: 1px solid var(--cinza-50);
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-top: 0.25rem;

  .react-select__control {
    width: 100%;
    height: auto;
  }

  /* .react-select__indicator-separator {
    width: 1px;
    right: 0 !important;
  } */

  .react-select__control {
    border: 0;
    height: auto;
    width: 100%;
  }

  span.icon {
    width: 3rem;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    left: 0;
    top: 0;
  }

  select {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    font-size: var(--font-17);
    line-height: 1.75rem;
    color: var(--cinza-90);
  }

  &:last-child {
    margin-right: 0;
  }
`;