// @ts-nocheck
import React, { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from 'yup';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import {
    PageHeader,
    TabContent,
    TabBody,
    Options,
    OptionInfo,
    Option
} from "styles/components";

import {
    Container,
    Content,
    Text,
    Title,
    FormContent,
    Wrap,
    CardButtons,
    Button
} from "./styles";

import { Input, Select, InputMask } from "../../components/Form";

import Header from "../../components/Header";
import { ModalAddRelative } from "../../components/Modal";

import ArrowLongLeft from "../../assets/icons/arrow-long-left.svg";
import ArrowLongRight from "../../assets/icons/arrow-long-right.svg";
import { ReactComponent as RelativeCircle } from "../../assets/icons/user-circle.svg";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import { dataPaises } from '../../data/dataPaises';
import { dataBancos } from '../../data/dataBancos';

const genero = [
    {
        label: "Masculino",
        value: "MALE"
    },
    {
        label: "Feminino",
        value: "FEMALE"
    }
];

export function Configuracoes() {
    const history = useHistory();
    const formRef = useRef(null);
    const [item, setItem] = useState(0);
    const [formData, setFormData] = useState({});
    const [sharedAccess, setSharedAccess] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loadingAccesses, setLoadingAccesses] = useState(false);

    const { user } = useAuth();
    const { addToast } = useToast();

    const countries = dataPaises.map(({ sigla, nome_pais }) => ({
        value: sigla,
        label: nome_pais
    }));

    const banks = dataBancos.map(({ COMPE, LongName }) => ({
        value: COMPE,
        label: LongName
    }));

    function handleSearchCep(evt) {
        const { value } = evt.target;
    
        if (!value) {
          return;
        }

        if(value.length > 6) {
            fetch(`https://viacep.com.br/ws/${value}/json/`)
            .then(res => res.json())
            .then(data => setFormData(state => ({
                ...state,
                zip_code: data.cep,
                city: data.localidade,
                state: data.uf,
                address_1: data.logradouro,
                address_4: data.bairro
            })));
        }
    }

    let defaultSelect = {
        label: '',
        value: ''
    };

    switch(formData?.gender) {
        case "MALE":
            defaultSelect.label = "Masculino";
            defaultSelect.value = "MALE";
            break;
        case "FEMALE":
            defaultSelect.label = "Feminino";
            defaultSelect.value = "FEMALE";
            break;
        default:
            break;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData(state => ({ ...state, [name]: value }));
    }

    function handleSelectChange(name, option) {
        const value = option ? option.value : null;
        setFormData(state => ({ ...state, [name]: value }));
    }

    useEffect(() => {
        api
            .get('central/profile')
            .then(response => {
                setFormData(response.data);
            });
    }, [item]);

    useEffect(() => {
        api
            .get('central/processes/shared-access')
            .then(response => {
                setSharedAccess(response.data.data);
            });
    }, [addToast, openModal, loadingAccesses]);

    useEffect(() => {
        if (user?.user_type === 'PERSON') {
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Você não tem permissão para acessar essa página!'
            });
            setTimeout(() => {                
                history.goBack();
            }, 1500);
        }
    }, [user, addToast, history]);

    const handleDeleteRelative = useCallback(async (data) => {
        try {
            setLoadingAccesses(true);

            await api.delete(`central/processes/shared-access/${data}`);

            addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Usuário deletado com sucesso!'
            });
        } catch(err) {
            console.log('Error', err);
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não foi possível deletar usuário!'
            });
        } finally {
            setLoadingAccesses(false);
        }
    }, [addToast]);

    const handleSubmit = useCallback(async (data) => {
        try {
            setLoading(true);

            formRef.current?.setErrors({});

            const dadosConta = {
                name: data?.name,
                birthdate: data?.birthdate,
                gender: formData?.gender,
                cpf: data?.cpf,
                rg: data?.rg,
                emissor: data?.emissor,
                dispatch_date: data?.dispatch_date,
                address_1: data?.address_1,
                address_2: data?.address_2,
                address_3: data?.address_3,
                address_4: data?.address_4,
                zip_code: data?.zip_code,
                city: data?.city,
                state: data?.state,
                country: formData?.country
            }

            if (item === 0) {
                await api.put('central/profile', dadosConta);
            } else {
                await api.put('central/profile', {
                    ...dadosConta,
                    bank_account_owner_name: data?.bank_account_owner_name,
                    bank_account_agency: data?.bank_account_agency,
                    bank_account_name: formData?.bank_account_name,
                    bank_account_number: data?.bank_account_number
                });
            }

            addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Perfil atualizado!'
            });
        } catch(err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não possível atualizar seus dados!'
            });
        } finally {
            setLoading(false);
        }
    }, [item, formData, addToast]);

    return (
        <>
            <Container>
                <Header />
                <PageHeader>
                    <Content>
                        <Text>
                            <Title>Configurações</Title>
                        </Text>
                    </Content>
                </PageHeader>
                <TabContent className="customTab">
                    <OptionInfo>
                        <img src={ArrowLongLeft} alt="Arrow Left"/>
                        Passe o dedo para ver mais
                        <img src={ArrowLongRight} alt="Arrow Right"/>
                    </OptionInfo>
                    <Options className="options">
                        <Option active={item === 0} onClick={() => { setItem(0); setFormData(null) }} className="option">
                            <PersonOutlineOutlinedIcon />
                            Informações da conta
                            <hr active={item === 0} />
                        </Option>
                        <Option active={item === 1} onClick={() => { setItem(1); setFormData(null) }} className="option">
                            <CreditCardOutlinedIcon />
                            Dados bancários
                            <hr active={item === 1} />
                        </Option>
                        <Option active={item === 2} onClick={() => { setItem(2); setFormData(null) }} className="option">
                            <PeopleOutlinedIcon />
                            Familiares
                            <hr active={item === 2} />
                        </Option>
                    </Options>
                    <TabBody className="body">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <FormContent active={item === 0}>
                                <h4>Edite suas informações pessoais</h4>
                                <Wrap className="first">
                                    <Input
                                        type="text"
                                        label="Nome completo"
                                        name="name"
                                        icon={<PersonOutlineOutlinedIcon />}
                                        placeholder="Insira aqui seu nome completo"
                                        defaultValue={formData?.name}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Wrap>
                                    <Input
                                        type="date"
                                        label="Data de nascimento"
                                        name="birthdate"
                                        defaultValue={formData?.birthdate}
                                        onChange={handleChange}
                                    />
                                    <Select
                                        label="Gênero" 
                                        name="gender"
                                        placeholder="Selecione"
                                        value={defaultSelect}
                                        options={genero}
                                        onChange={(value) => handleSelectChange('gender', value)}
                                    />
                                </Wrap>
                                <Wrap>
                                    <InputMask
                                        type="cpf"
                                        name="cpf"
                                        label="CPF"
                                        value={formData?.cpf}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label="RG"
                                        name="rg"
                                        defaultValue={formData?.rg}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Wrap>
                                    <Input
                                        label="Órgão emissor"
                                        name="emissor"
                                        placeholder="Nome do órgão"
                                        defaultValue={formData?.emissor}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        type="date"
                                        label="Data de emissão"
                                        name="dispatch_date"
                                        defaultValue={formData?.dispatch_date}
                                        onChange={handleChange}
                                    />
                                    
                                </Wrap>
                                <hr className="line" />
                                <h4>Edite seu endereço</h4>
                                <Wrap>
                                    <Select
                                        label="País" 
                                        name="country"
                                        placeholder="Selecione o país de origem"
                                        value={formData?.country &&
                                            countries.filter((item) => item.value === formData?.country)
                                        }
                                        options={countries}
                                        onChange={(value) => handleSelectChange('country', value)}
                                    />
                                    <Input
                                        label="CEP"
                                        name="zip_code"
                                        placeholder="Insira o cep"
                                        defaultValue={formData?.zip_code}
                                        onChange={handleSearchCep}
                                    />
                                </Wrap>
                                <Wrap>
                                    <Input
                                        type="text"
                                        label="Estado"
                                        name="state"
                                        defaultValue={formData?.state}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label="Cidade"
                                        name="city"
                                        placeholder="Nome da cidade"
                                        defaultValue={formData?.city}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Wrap className="first">
                                    <Input
                                        label="Nome da rua"
                                        name="address_1"
                                        placeholder="Insira aqui o nome da rua"
                                        defaultValue={formData?.address_1}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Wrap>
                                    <Input
                                        type="number"
                                        label="Número"
                                        name="address_2"
                                        placeholder="Número"
                                        defaultValue={formData?.address_2}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label="Complemento"
                                        name="address_3"
                                        placeholder="Insira o número"
                                        defaultValue={formData?.address_3}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label="Bairro"
                                        name="address_4"
                                        placeholder="Nome do bairro"
                                        defaultValue={formData?.address_4}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Button type="submit" disabled={loading}>
                                    {loading ? (
                                        <RotateLeftOutlinedIcon className="icon-spin" />
                                    ) : (
                                        <>
                                            <SaveOutlinedIcon />
                                            Salvar alterações
                                        </>
                                    )}
                                </Button>
                            </FormContent>
                            <FormContent active={item === 1}>
                                <h4>Edite seus dados bancários</h4>
                                <Wrap className="first">
                                    <Input
                                        type="text"
                                        label="Nome do titular da conta"
                                        name="bank_account_owner_name"
                                        icon={<PersonOutlineOutlinedIcon />}
                                        placeholder="Insira aqui seu nome completo"
                                        defaultValue={formData?.bank_account_owner_name}
                                        onChange={handleChange}
                                    />
                                </Wrap>
                                <Wrap>
                                    <Select
                                        label="Banco" 
                                        name="bank_account_name"
                                        placeholder="Selecione seu banco"
                                        value={formData?.bank_account_name &&
                                            banks.filter((item) => item.value === formData?.bank_account_name)
                                        }
                                        options={banks}
                                        onChange={value => handleSelectChange('bank_account_name', value)}
                                    />
                                    <Wrap>
                                        <Input
                                            type="number"
                                            label="Agência"
                                            name="bank_account_agency"
                                            defaultValue={formData?.bank_account_agency}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            type="number"
                                            label="Conta"
                                            name="bank_account_number"
                                            defaultValue={formData?.bank_account_number}
                                            onChange={handleChange}
                                        />
                                    </Wrap>
                                </Wrap>
                                <Button type="submit" disabled={loading}>
                                    {loading ? (
                                        <RotateLeftOutlinedIcon className="icon-spin" />
                                    ) : (
                                        <>
                                            <SaveOutlinedIcon />
                                            Salvar alterações
                                        </>
                                    )}
                                </Button>
                            </FormContent>
                            <FormContent active={item === 2}>
                                <h4>Edite seus familiares</h4>
                                <div className='relatives'>
                                    {sharedAccess && sharedAccess?.map((access) => (
                                        <div>
                                            <div className='name'>
                                                <RelativeCircle />
                                                <p>{access.name}</p>
                                            </div>
                                            <div className='infos'>
                                                <div className='email'>
                                                    <p>E-mail</p>
                                                    <span>
                                                        <MailOutlinedIcon />
                                                        {access.email}
                                                    </span>
                                                </div>
                                                <button type="button" onClick={() => handleDeleteRelative(access.id)}>
                                                    <DeleteOutlinedIcon />
                                                    Deletar familiar
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <CardButtons>
                                        {/* <Button type="submit" disabled={loading}>
                                            {loading ? (
                                                <RotateLeftOutlinedIcon className="icon-spin" />
                                            ) : (
                                                <>
                                                    <SaveOutlinedIcon />
                                                    Salvar alterações
                                                </>
                                            )}
                                        </Button> */}
                                        <Button type="button" onClick={() => setOpenModal(true)} bgColor='transparent'>
                                            <PersonAddOutlinedIcon />
                                            Adicionar familiar
                                        </Button>
                                    </CardButtons>
                                </div>
                            </FormContent>
                        </Form>
                    </TabBody>
                </TabContent>
                <ModalAddRelative openModal={openModal} closeModal={() => setOpenModal(false)} />
            </Container>
        </>
    );
}