import styled, { css } from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100vw;
  height: auto;

  ${(props) => props.border === false && css`
    .borderNone {
      border-bottom: 0;
    }
  `}

  .customTab {
    border-radius: 8px;
  }

  .no-border {
    border-radius: 8px;
  }

  p.accordionHeader {
    font-size: var(--font-15);
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--principal-50);
  }

  .MuiButtonBase-root {
    border-radius: 8px;
  }

  .MuiButtonBase-root.Mui-expanded {
    border-radius: 8px 8px 0 0;
  }

  @media screen and (max-width: 950px) {
    padding-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    .MuiAccordionSummary-content {
      flex-direction: column;
      margin: 24px 0;
    }

    .MuiButtonBase-root {
      padding: 0 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  height: 22.625rem;
  margin: 3.5rem 0 3rem;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    height: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  color: var(--cinza-100);

  @media screen and (max-width: 950px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 24.3125rem;

  &.mobile {
    display: none;
  }

  h2 {
    margin-bottom: 1rem;
    color: var(--cinza-90);

    span {
      color: var(--principal-50);
    }
  }

  p {
    font-size: 1.1875rem;
    line-height: 1.75rem;
    color: var(--cinza-90);
  }

  @media screen and (max-width: 950px) {
    p {
      font-size: 17px;
      line-height: 28px;
    }

    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
      margin-top: 44px;
    }
  }
`;

export const Phases = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin-top: auto;

  p {
    font-size: var(--font-15);
    line-height: 1.25rem;
    color: var(--cinza-80);
    margin-bottom: 1rem;

    &.title {
      line-height: 1.5rem;
      letter-spacing: 1.5px;
      font-weight: 700;
      color: var(--principal-50);
      margin-bottom: 0.25rem;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: 951px) {
    min-height: 215px;
  }

  @media screen and (max-width: 950px) {
    span.mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 28px 0 32px;
    }

    p {
      &.title,
      &.description {
        display: none;
      }
    }
  }
`;

export const CountPhases = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 2.625rem;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    width: auto;
    height: 336px;
    padding: 0;
  }
`;

export const NumberPhases = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => active ? "var(--gradiente-verde-claro)" : "var(--white)"};
  color: ${({ active }) => active ? "var(--white)" : "var(--cinza-30)"};
  width: 2.5rem;
  height: 2.5rem;
  border: ${({ active }) => active ? "none" : "2px solid var(--cinza-30)"};
  border-radius: 50%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-17);
    line-height: 1.25rem;
    font-weight: 700;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 2px;

  ${({ active }) =>
    active ?
    css `
      background: var(--gradiente-verde-claro);
    ` :
    css `
      background: var(--cinza-30);
    `
  }

  @media screen and (max-width: 950px) {
    width: 2px;
    height: 100%;
    margin: 4px 0;
  }
`;

export const DescriptionPhase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 0.5rem;

  p {
    max-width: 7.75rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 7.75rem;

  p {
    margin-bottom: 0.5rem;
  }

  svg {
    color: var(--atencao-50);
  }

  @media screen and (max-width: 950px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 8px;
    height: 2.5rem;

    p {
      font-size: 15px;
      line-height: 24px;
      max-width: 168px;
      text-align: left;
      margin: 0;
    }
  }
`;

export const MobileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
      font-size: 15px;
      line-height: 24px;
      font-weight: 700;
      color: var(--atencao-50);
    }

  @media screen and (min-width: 951px) {
    span {
      display: none;
    }
  }
`;

export const TooltipInfo = styled.div`
  transition: all 0.5s ease-in-out;
  opacity: ${({ active }) => active ? 1 : 0};
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  width: 24.25rem;
  background: var(--cinza-100);
  color: var(--white);
  border-radius: 8px;
  padding: 1rem;
  position: absolute;
  top: 130%;
  ${({ active }) => active ? 'z-index: 10;' : 'z-index: -1;'}

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent var(--cinza-100) transparent;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;

    ${({ active }) =>
      active ? 
      css `
        display: flex;
        z-index: 10;
        top: 160%;
        right: 4px;

        &:after {
          left: 87%;
        }
      ` :
      css `
        display: none;
      `
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const FormContent = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  position: relative;
  width: 100%;

  form {
    width: 100%;

    .react-select__menu-list {
      height: 110px;
    }
  }

  ${Wrap} {
    &:last-child {
      margin: none;
    }
  }
`;

export const AccordionDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  font-weight: bold;
  width: 100%;
  max-width: 36rem;
  margin-right: 1.6875rem;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    > p.title {
      color: var(--cinza-100);
    }
  }

  img {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 1100px) {
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  @media screen and (max-width: 767px) {
    p {
      font-size: 17px;
    }
  }
`;

export const AccordionButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    position: relative;

  @media screen and (max-width: 950px) {
    width: 100%;

    button {
      margin-top: 24px;
    }

    span {
      position: absolute;
      right: 0;
      top: -50px;
    }
  }

  @media screen and (max-width: 767px) {
    position: unset;

    span {
      position: absolute;
      top: 24px;
      right: 16px;
    }
  }
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.7s;
  transition-property: transform;
  margin-left: 1.5rem;
  background: var(--cinza-20);
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    width: 40px;
  }

  ${({ active }) => active
    ? `transform: rotate(180deg);
    -webkit-transform: rotate(180deg);`
    : ""
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 18px;
    right: 16px;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: var(--principal-40);
  font-size: var(--font-17);
  line-height: 1.75rem;
  font-weight: 700;
  color: var(--cinza-100);
  padding: 0.75rem 0;
  margin: 1.5rem auto 0;
  width: 100%;
  max-width: 200px;
  border-radius: 0.5rem;
  border: none;
  outline: none;

  .icon-spin {
    animation: iconSpin 0.8s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 28px;
    max-width: 100%;
    padding: 14px 0;
  }
`;
