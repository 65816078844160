import React from "react";
import { Switch } from "react-router-dom";

import Route from './Route';

import { Login } from "../pages/Login";
import { RecoveryPassword } from "../pages/RecoveryPassword";
import { ChangePassword } from "../pages/ChangePassword";
import { ResetPassword } from "../pages/ResetPassword";
import { Home } from "../pages/Home";
import { Portugueses } from "../pages/Portugueses";
import { Pagamentos } from "../pages/Pagamento";
import { PerguntasFrequentes } from "../pages/PerguntasFrequentes";
import { Contato } from "../pages/Contato";
import { ContatoCriticas } from "../pages/ContatoCriticas";
import { ContatoSugestoes } from "../pages/ContatoSugestoes";
import { Configuracoes } from "../pages/Configuracoes";
import { Procuracao } from "../pages/Procuracao";
import { Impressao } from "../pages/Impressao";
import { ImpressaoCartorios } from "../pages/ImpressaoCartorios";
import { Atividades } from "../pages/Atividades";
import { EmissaoCertidao } from "../pages/EmissaoCertidao";

export default function Routes({ history }) {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/recuperar-senha" exact component={RecoveryPassword} />
      <Route path="/redefinir-senha" exact component={ResetPassword} />
      
      <Route path="/" exact component={Home} isPrivate />
      <Route path="/portugueses" exact component={Portugueses} isPrivate />
      <Route path="/pagamentos" exact component={Pagamentos} isPrivate />
      <Route path="/perguntas-frequentes" exact component={PerguntasFrequentes} isPrivate />
      <Route path="/contato" exact component={Contato} isPrivate />
      <Route path="/sugestoes" exact component={ContatoSugestoes} isPrivate />
      <Route path="/criticas" exact component={ContatoCriticas} isPrivate />
      <Route path="/configuracoes" exact component={Configuracoes} isPrivate />
      <Route path="/procuracao" exact component={Procuracao} isPrivate />
      <Route path="/impressao" exact component={Impressao} isPrivate />
      <Route path="/impressao-cartorios" exact component={ImpressaoCartorios} isPrivate />
      <Route path="/registro-de-atividades" exact component={Atividades} isPrivate />
      <Route path="/troca-de-senha" exact component={ChangePassword} isPrivate />
      <Route path="/emissao-certidao" exact component={EmissaoCertidao} isPrivate />
    </Switch>
  );
}
