import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: auto;

  @media screen and (max-width: 950px) {
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  padding: 40px 0 99px;

  @media screen and (max-width: 1280px) {
    padding: 40px 40px 99px;
  }

  @media screen and (max-width: 950px) {
    padding: 20px 16px 32px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;

  img {
    width: max-content;
  }

  > div {
    display: flex;
    flex-direction: column;

    > p {
      font-size: 19px;
      line-height: 28px;
      color: var(--cinza-80);
    }

    > div {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-size: 10px;
        line-height: 12px;
        color: var(--cinza-80);
        text-transform: uppercase;
      }

      span {
        border: 0;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: var(--principal-40);
      }
    }
  }

  @media screen and (max-width: 950px) {
    gap: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  & + div {
    padding-top: 24px;
    border-top: 1px solid var(--cinza-40);
  }

  > h4 {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: var(--cinza-80);
    margin: 0 0 14px 0;
  }

  > div {
    display: flex;
    gap: 8px;

    & + div {
      margin-top: 10px;
    }

    > svg {
      font-size: 16px;
      color: var(--cinza-40);
    }

    > p {
      font-size: 11px;
      line-height: 13.2px;
      letter-spacing: 0.2px;
      color: var(--cinza-80);
      margin: 0;
    }
  }

  @media screen and (max-width: 950px) {
    gap: 20px;
  }
`;
