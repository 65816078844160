import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  border: 1px solid var(--cinza-40);
  border-radius: 16px;
  padding: 24px 16px;
  position: relative;

  & + div {
    margin-top: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > p {
        &.title {
        font-size: 17px;
        line-height: 28px;
        font-weight: 700;
        color: var(--cinza-90);
        }
    }

    > div {
      display: flex;
      gap: 32px;
      width: 100%;
      
      > div {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 16px;

        > p {
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: var(--cinza-70);
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 8px;

        > div {
          margin: 0;

          &:first-child {
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const Body = styled.div`
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 16px !important;
    max-width: 540px;
    max-height: 26px;
    overflow: hidden;

    ${({ active }) => active && css`
      max-height: 250px;
    `}

    > div {
      display: flex;
      gap: 16px !important;
      padding: 16px 0;
      margin-top: 0 !important;

      a {
        padding: 10px 24px;
        background: var(--principal-40);
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
        letter-spacing: 0.2px;
        color: var(--cinza-100);
        border-radius: 8px;
        text-decoration: none;

        &.no-background {
          background: transparent;
          color: var(--neutro-30);
          border: 1px solid var(--neutro-30);
        }
      }
    }

    > p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-70);
      margin-top: 4px;

      &.hidden {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
  transition-property: transform;
  background: var(--cinza-20);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 40px;
  }

  ${({ active }) => active
    ? `transform: rotate(180deg);
    -webkit-transform: rotate(180deg);`
    : ""
  }

  @media screen and (max-width: 767px) {
    margin: auto 0;
  }
`;
