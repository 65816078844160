import { Link } from "react-router-dom";

export const dataPerguntas = [
  {
    id: 1,
    title: `Quais são os canais de atendimento ao cliente?`,
    text: `<p>Você pode entrar em contato conosco via mensagem whatsapp no número (21) 99025-7203, via o nosso chat clicando aqui ao lado em atendimento online ou por e-mail através do endereço atendimento.servicos@cidadaniaja.com.br</p>
        <p>Nosso horário de atendimento é de segunda a sexta-feira das 09h às 17h, exceto feriados. Caso nos envie uma mensagem fora dessa janela, lhe responderemos no próximo dia útil!</p>`,
  },
  {
    id: 2,
    title: `O que é o alinhamento inicial do serviço?`,
    text: `<p>O alinhamento é uma etapa inicial muito importante pois tiramos todas as dúvidas sobre documentação, prazos e os procedimentos contratados. Não é obrigatório, mas recomendamos realizar o alinhamento para que você esteja a par do procedimento!</p>
        <p>Essa etapa é direcionada para novos clientes. Caso já tenha feito, mas tenha alguma dúvida específica, pode nos contatar nos nossos canais de atendimento.</p>`,
  },
  {
    id: 3,
    title: `Alinhamento inicial, como fazer?`,
    text: `<p>Você pode assistir novamente o seu alinhamento virtual na aba de tutorial <a href='/'>clicando aqui.</a><br />
            Caso tenha alguma dúvida que não tenha sido abordada por aqui, você poderá marcar com uma de nossas agentes
            para tirar suas dúvidas aqui:</p>
        <p><a href="https://cidadaniaja.pipedrive.com/scheduler/p1Dv5S8Q/reuniao-de-alinhamento-cidadania-ja">
        https://cidadaniaja.pipedrive.com/scheduler/p1Dv5S8Q/reuniao-de-alinhamento-cidadania-ja
        </a>
        </p>`,
  },
  {
    id: 4,
    title: `Como funciona a confirmação do recebimento de documentação no nosso escritório?`,
    text: `<p>O setor de documentação confirma o recebimento dos documentos por e-mail em até 72h úteis após a entrega do documento (seja entrega pelo correio ou presencial). Assim que for confirmado, você poderá visualizar os documentos atualizados na etapa de documentação. Se você não receber nenhuma confirmação dentro desse prazo, entre em contato conosco para verificarmos o que ocorreu!</p>`,
  },
  {
    id: 5,
    title: `Preciso entregar os documentos físicos para o meu procedimento?`,
    text: `<p>Sim! É essencial que todos os documentos sejam entregues de maneira física, dentro da validade e que as certidões sejam originais para que sejam válidas em Portugal.</p>`,
  },
  {
    id: 6,
    title: `Para qual endereço preciso enviar os documentos?`,
    text: `<p>Após reunir todos os documentos solicitados pela nossa equipe, é só enviar para o endereço do nosso escritório:</p>
        <p>Praia de Botafogo 518, 10°andar, frente, Rio de Janeiro 22250-040</p>`,
  },
  {
    id: 7,
    title: `Quando posso deixar os documentos presencialmente?`,
    text: `<p>O horário de funcionamento do nosso escritório para a entrega da documentação presencial é de:</p>
        <p>- Segunda a sexta: 8h às 19h</p>
        <p>- Finais de semana e feriados nacionais: FECHADA</p>
        <p>O nosso endereço é: Praia de Botafogo 518, 10°andar, frente. CEP: 22250-040</p>
        <p>Ao entregar a sua documentação, nossa equipe recolherá e confirmará o recebimento em até 72h úteis por e-mail e por aqui na central do cliente.</p>`,
  },
  {
    id: 8,
    title: `Preenchi o formulário do meu pedido mas não achei/recebi minha procuração!`,
    text: `<p>Após o preenchimento do formulário, a procuração fica disponível para download no item que você clicou para preencher o mesmo.</p>
        <p>Além disso, enviamos por e-mail a procuração em PDF para impressão e reconhecimento por autenticidade no cartório.</p>`,
  },
  {
    id: 9,
    title: `Posso usar o mesmo documento em mais um processo?`,
    text: `<p>Não é possível utilizar o mesmo documento para mais de um pedido. Cada procedimento é recebido separadamente por Portugal, que exige que seja enviada a via física original atualizada em cada um deles.</p>`,
  },
  {
    id: 10,
    title: `O que acontece após o recebimento da documentação?`,
    text: `<p>Após receber o e-mail de confirmação do recebimento da documentação, a mesma passará por uma análise interna, na qual será verificado se todos os documentos apresentados estão de acordo com os formatos aceitos pelas conservatórias em Portugal.</p>
        <p>Essa análise busca verificar inconsistências nas documentações para prevenir problemas de após o despacho para Portugal.</p>
        <p>Ao final desse procedimento, caso haja alguma observação ou algum impedimento em relação a algum documento, entraremos em contato com as orientações para prosseguir com o pedido.</p>
        <p>Se tudo estiver ok com a análise, enviaremos os documentos para o cartório para que estes sejam apostilados.</p>`,
  },
  {
    id: 11,
    title: `O que é o Apostilamento de Haia?`,
    text: `<p>O Apostilamento de Haia é definido como um certificado que autentica a origem de um documento público para sua utilização em outro país. Este documento é emitido em papel moeda contendo assinatura, cargo do agente público, selo ou carimbo da instituição.</p>
        <p>No Brasil, o apostilamento é feito nos cartórios. Com isso, os documentos podem ser utilizados em qualquer procedimento em Portugal.</p>`,
  },
  {
    id: 12,
    title: `Quanto tempo demora para fazer o apostilamento dos documentos?`,
    text: `<p>O apostilamento é feito em cartório e essa etapa demora até 2 semanas para retorno.</p>`,
  },
  {
    id: 13,
    title: `O que acontece após o apostilamento?`,
    text: `<p>Após o recebimento dos documentos apostilados do cartório, podemos preparar o despacho para Portugal.</p>
        <p>Nessa etapa, caso já tenhamos o assento ou registro de batismo do português da família, vamos preparar o envio do malote através de alguns passos:</p>
        <ul style="list-style: inside; font-size: 17px; line-height: 1.75rem; color: var(--cinza-80);">
            <li>A reanálise da documentação reunida</li>
            <li>Pagamento dos emolumentos do órgão em Portugal</li>
            <li>Preparo de formulários do pedido</li>
            <li>Enfim envio dos documentos via transportadora particular DHL.</li>
            <li>Assim que o envio for efetuado, você receberá a confirmação no seu e-mail com o rastreio.</li>
        </ul>
        <p>PS: O código de rastreio da DHL expira pouco tempo depois da documentação chegar em Portugal. Eles reutilizam o código para outros serviços.</p>`,
  },
  {
    id: 14,
    title: `Quanto tempo demora para o órgão público finalizar o pedido após o envio?`,
    text: `<p>O tempo estimado que cada procedimento leva, baseado em casos passados, é de:</p>
        <p>
        Aquisição de cidadania para cônjuge de portugueses: 18 a 28 meses;<br />
        Atribuição de cidadania para filhos: 6 a 14 meses;<br />
        Atribuição de cidadania para netos: 18 a 28 meses;<br />
        Atribuição de cidadania de menor de idade: 2 a 10 meses;<br />
        Transcrição de casamento: 4 a 12 meses;<br />
        Homologação de Divórcio ou de União Estável: 18 a 24  meses;
        </p>
        <p>É válido ressaltar que o tempo de conclusão <strong>depende exclusivamente</strong> da análise do pedido por parte do órgão
        público português, não sendo possível exercermos influência nessa velocidade, que varia conforme o procedimento
        e a conservatória. Logo, apesar das estimativas normalmente se confirmarem, é possível que o tempo seja superior,
        não cabendo a nós a responsabilidade nesse aspecto.
        </p>`,
  },
  {
    id: 15,
    title: `O que acontece ao concluir o procedimento de cidadania?`,
    text: `<p>Após conclusão, você se torna oficialmente um cidadão português, em casos de pedidos de aquisição e
        atribuição, e com isso obtém um número de assento, que é o seu número de registro, e analogamente seria o número
        da certidão de nascimento brasileira.
        </p>
        <p>Ter essa numeração do assento é fundamental, pois é com esse documento que você faz qualquer procedimento
        posterior, por exemplo: emissão do cartão cidadão de Portugal e passaporte europeu junto ao consulado, passar a
        cidadania para filhos ou netos, se for o caso, averbar seu casamento e/ou divórcio, entre outros. Nós lhe enviamos
        o número de assento digitalmente via e-mail, em um documento português digital. Para qualquer futuro procedimento,
        não se faz necessário esse documento de forma física
        </p>
        <p>PS: Lembrando que é obrigação de todo cidadão português ter o seu estado civil atualizado em Portugal. Se você
        se tornou português e ainda não reconheceu seu casamento em Portugal (através da transcrição) poderá ter problemas
        para emitir os documentos portugueses no consulado. Por isso, recomendamos que sempre tenha seu estado civil
        atualizado em Portugal</p>`,
  },
  {
    id: 16,
    title: `Como emitir o cartão cidadão e passaporte?`,
    text: `<p>A emissão do cartão cidadão e passaporte europeu é feita através de qualquer consulado de Portugal no
        Brasil e em outros países. Em Portugal existem as Lojas do Cidadão que prestam esse serviço de emissão. </p>
        <p>É necessário que o português entre em contato com o consulado para agendar um dia para emitir o cartão cidadão.
        Em muitos consulados, o agendamento é feito de forma online. Os procedimentos são sequenciais, ou seja, é preciso
        fazer primeiro o cartão cidadão, para então, tirar o passaporte. Ambos os trâmites são feitos com o próprio requerente
        presencialmente no consulado.</p>
        <p>Você pode também conferir o nosso guia sobre o assunto, enviado juntamente com o e-mail de conclusão.</p>`,
  },
  {
    id: 17,
    title: `Como eu acompanho o andamento e etapas do meu pedido em Portugal?`,
    text: `<p>Você consegue acompanhar o seu procedimento aqui na sua central do cliente, no “Passo 6 - Andamento”
        na parte de “Meus Pedidos”. Lá você consegue verificar a perspectiva de conclusão e quanto tempo se passou do
        seu pedido desde o envio.</p>
        <p>Para verificar mais informações sobre as etapas dentro da Conservatória, você pode utilizar a sua senha de
        acesso no site do governo português, e ficar atento à sua caixa de entrada no seu e-mail de cadastro conosco.
        Sempre que há atualizaçoes, informamos através de comunicados em tempo hábil!</p>
        <p>É importante salientar que a senha de acesso só é fornecida por Portugal cerca de quatro meses após a entrada
        dos documentos na Conservatória</p>`,
  },
  {
    id: 18,
    title: `Eu não recebi a minha senha de acesso para consulta no site do governo português!`,
    text: `<p>De acordo com o funcionamento da Conservatória e evolução do pedido, o tempo de geração da senha de
        consulta por parte do órgão responsável costuma variar.</p>
        <p>Seu pedido foi enviado com uma procuração para a advogada parceira, e a mesma será notificada quando a senha
        for gerada! Assim que tivermos essa informação, vamos te encaminhar por e-mail para que você possa consultar no
        site do governo português. Mas fique tranquilo que a evolução do pedido não tem relação com a senha de consulta.</p>
        <p>A senha de acesso é uma das ferramentas de verificação do pedido, mas não é atualizada com linearidade, por
        isso nosso acompanhamento em paralelo é primordial durante todo o percurso</p>`,
  },
  {
    id: 19,
    title: `Como reconhecer firma e autenticar documento de identificação do meu pedido no exterior?`,
    text: `<p>Para um cliente que mora em Portugal, ele pode reconhecer a firma em qualquer cartório de notas EM
        PORTUGAL ou com um advogado com órdem portuguesa. Basta o cliente imprimir a procuração já preenchida, assinar
        por autenticidade/verdadeiro, tirar uma cópia autenticada do passaporte/RG/CNH (CNH não pode para atribuição de
        neto e aquisição) dele no mesmo lugar e enviar tudo para NOSSO ENDEREÇO NO BRASIL. É importante que o
        passaporte dele conste filiação.</p>
        <p>Já para os clientes que residem em outro país que não seja Portugal ou Brasil, o CONSULADO PORTUGUÊS vai
        reconhecer a procuração e o CONSULADO BRASILEIRO os documentos brasileiros. Vale a tentativa de reconhecer a
        procuração no consulado BR, mas é importante salientar que eles podem negar por se tratar de uma procuração
        portuguesa.</p>
        <p>Para as certidões brasileiras do cliente, o processo segue os mesmos procedimentos que os processos nacionais.</p>`,
  },
  {
    id: 20,
    title: `Como reconhecer a procuração por autenticidade?`,
    text: `<p>O reconhecimento de assinatura por autenticidade é específico e feito apenas presencialmente no cartório.
        Isso significa que você precisará imprimir a procuração, levar até o cartório em branco, assinar e datar na frente
        do tabelião pedindo reconhecimento da firma por autenticidade.</p>
        <p>Para efetuar o reconhecimento da assinatura é preciso abrir firma no cartório. Verifique se o selo está com o
        reconhecimento de firma por autenticidade/verdadeiro, caso não esteja, peça para o oficial do cartório corrigir.</p>`,
  },
  {
    id: 21,
    title: `Surgiu um obstáculo no tratamento da minha documentação, como posso tirar dúvidas a respeito disso?`,
    text: `<p>Caso surja um obstáculo na análise de seus documentos, a central do cliente vai te alertar e a equipe
        especialista entrará em contato pelo e-mail cadastrado em nosso sistema em até 72 horas informando o motivo do
        obstáculo, de forma detalhada e como resolver. Fique atento ao seu e-mail e não se preocupe, sempre vamos te comunicar</p>`,
  },
  {
    id: 22,
    title: `Uma pessoa tendo cidadania portuguesa, precisa de algum procedimento para reagrupar o cônjuge?`,
    text: `<p>Sim! Sendo um cidadão português é necessário que seja realizada a atualização do estado civil em
        Portugal (transcrição de casamento), para que assim possa realizar o procedimento de reagrupamento familiar do
        cônjuge e assim possa residir legalmente em Portugal. Junto à SEF em Portugal</p>
        <p><a href="https://www.sef.pt/pt/Pages/homepage.aspx">https://www.sef.pt/pt/Pages/homepage.aspx</a></p>`,
  },
  {
    id: 23,
    title: `Ao tentar emitir uma certidão junto ao cartório, fui informado que ela está destruída. E agora?`,
    text: `<p>Infelizmente, com o tempo, no arquivo do cartório o livro de registro de nascimento/casamento pode
        ficar danificado. Seja por rasgos, pelo tempo e não ficar mais legível, seja molhar, pegar fogo em acidentes
        naturais, entre outros. Porém, mesmo com essa danificação, por lei o dono do registro tem direito a emitir a
        certidão quando quiser, em sua forma reprográfica se houver. Com isso, o cartório precisa restituir esse registro
        que foi danificado em um novo registro que será feito em um livro mais novo.</p>
        <p>Esse novo registro é chamado de registro restituído, que possui valor legal normal em território nacional.
        Porém, infelizmente não é o registro original e a conservatória em Portugal não aceita esse documento. Para ter
        mais informações sobre como podemos prosseguir no seu caso, por favor entre em contato conosco na central de
        atendimento aqui no canto inferior direito no chat!</p>`,
  },
  {
    id: 24,
    title: `Só tenho documento militar ou profissional, ele serve para o meu processo?`,
    text: `<p>Infelizmente Portugal não aceita esse tipo de documento como documento de identificação civil. Nenhuma
        carteira profissional (OAB, CRP, CBO, Servidor Público, entre outros…). Para o pedido de cidadania só é permitido
        Passaporte ou RG atualizado, e em alguns casos específicos CNH. Caso tenha dúvida sobre qual documento utilizar,
        verifique na lista de documentação do seu pedido!</p>`,
  },
  {
    id: 25,
    title: `Posso enviar a cópia do meu documento português ao invés de RG/passaporte/cnh?`,
    text: `<p>Sempre que pedirmos documento de identificação com foto se faz necessário preencher o PDF na procuração.
        Se quiser apresentar o cartão cidadão, é importante que preencha os dados da procuração com o NIF.</p>`,
  },
  {
    id: 26,
    title: `Existe fila prioritária para pedidos de cidadania em Portugal?`,
    text: `<p>As conservatórias só conseguem priorizar os PTs com pedido de urgência fundamentado e em casos raros, exemplos:</p>
        <p>Uma promessa de trabalho (Por exemplo, precisa ser cidadão português porque a vaga exige que a pessoa viaje
        entres os países da UE) Faculdade e pós (Se for relevante para alguma coisa como pagamento das mensalidades ou
        se tiver alguma bolsa que só pode concorrer português) Fora isso, não conseguimos priorizar, nem mesmo quando o
        requerente apresenta problemas de saúde!</p>`,
  },
  {
    id: 27,
    title: `Quais documentos precisam ser solicitados para os pedidos de prioridade?`,
    text: `<p>Uma declaração da empresa/faculdade.<br />
        A carta pode ser escrita na língua oficial da empresa, e deverá vir com a tradução juramentada. Só não há necessidade
        de tradução quando o documento estiver em português, inglês, espanhol ou francês.</p>`,
  },
  {
    id: 28,
    title: `Vocês pedem a certidão de nascimento de alguém que se casou e não tem certidão de nascimento em mãos, ficou
        retida no cartório. O que eu faço?`,
    text: `<p>Na verdade uma via da certidão de nascimento fica retida no cartório de casamento ao se casar, mas a
        outra via existe no cartório onde o nascimento da pessoa foi registrado. Basta entrar em contato com o cartório
        e solicitar a 2ª via original no formato especificado para seu pedido.</p>`,
  },
];
