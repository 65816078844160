// @ts-nocheck
import { Container, Content, Button } from "./styles";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import BasicDownload from "../../../../assets/icons/basic-download.svg";

export default function DocumentationDocuments({ data }) {
  let bgColor = "";
  let iconColor = "";
  let icon = "";

  switch (data?.status) {
    case "ARRIVED_SUCCESSFULLY":
      bgColor = "#E5FFEB";
      iconColor = "#1F9833";
      icon = <CheckCircleIcon />;
      break;
    case "PENDING":
      bgColor = "#FFF8E1";
      iconColor = "#EE9E00";
      icon = <AccessTimeIcon />;
      break;
    case "ARRIVED_WRONG":
      bgColor = "#FEE2E8";
      iconColor = "#DB0A3C";
      icon = <WarningIcon />;
      break;
    default:
      break;
  }

  return (
    <Container key={data?.reference_id} style={{ background: "var(--white)", border: "1px solid var(--cinza-30)" }}>
      <Content>
        <div>
          <p className="title">{data.name}</p>
          {/* <p>Baixar arquivo enviado</p> */}
        </div>
      </Content>
      {/* <Button onClick={() => window.location.href=`${data?.proxy_path}`}>
        <img src={BasicDownload} alt="Download" />
      </Button> */}
    </Container>
  );
}
