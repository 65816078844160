// @ts-nocheck
import { useState } from 'react';

import { format, differenceInDays , parseISO, addDays } from 'date-fns';

import {
    CardService,
    CardStats,
    CardPercent,
    LinePercent,
    StatsInfo,
    Prediction,
    Contact,
    CautionBox,
    Button
} from "./styles";

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import Check from '../../../assets/boas-vindas/check-grande.png';
import BasicLoading from '../../../assets/icons/basic-loading.svg';
import { ReactComponent as Chat } from '../../../assets/icons/chat.svg';
import { ReactComponent as RedChat } from '../../../assets/icons/red-chat.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external_link.svg';
import { ReactComponent as AlertInfo } from '../../../assets/icons/alert-info.svg';

import AnalysisDocuments from "./AnalysisDocuments";
import { Input } from "../InputCard";

import { useRequirement } from "../../../hooks/requirement";
import { useToast } from "../../../hooks/toast";
import api from "services/api";

export default function CardAnalysis({ data, action }) {
    const [loading, setLoading] = useState(false);

    const { addRequirement } = useRequirement();
    const { addToast } = useToast();

    const startAnalysisPredictionDate = data?.start_analysis_prediction_date && new Date(data.start_analysis_prediction_date);
    const endAnalysisPredictionDate = data?.end_analysis_prediction_date && new Date(data.end_analysis_prediction_date);

    let percentAnalysis = parseInt(((new Date() - startAnalysisPredictionDate) / (endAnalysisPredictionDate - startAnalysisPredictionDate)) * 100);
    if(percentAnalysis < 0) {
        percentAnalysis = 0;
    }

    const countAnalysisDaysRemain = differenceInDays(addDays(new Date(data?.end_analysis_prediction_date), 1), new Date());

    let bgColor = "";
    let iconColor = "";
    let color = "";
    let icon = "";
    let text = "";

    switch (data?.status) {
        case "ARRIVED_SUCCESSFULLY":
        bgColor = "#E5FFEB";
        iconColor = "#1F9833";
        color = "#2E5D38";
        icon = <CheckCircleIcon />;
        text = "Documento recebido";
        break;
        case "PENDING":
        bgColor = "#FFF8E1";
        iconColor = "#EE9E00";
        color = "#42455F";
        icon = <AccessTimeIcon />;
        text = "Documento não recebido";
        break;
        case "ARRIVED_WRONG":
        bgColor = "#FEE2E8";
        iconColor = "#DB0A3C";
        color = "#DB0A3C";
        icon = <CancelIcon />;
        text = "Documento recebido não aprovado";
        break;
        case "NOT_REQUIRED":
        bgColor = "#F2F4F7";
        iconColor = "#7A7FA7";
        color = "#42455F";
        icon = <InsertDriveFileOutlinedIcon />;
        text = "Documento não necessário";
        break;
        default:
        break;
    }

    async function handleContact() {
        try {
            setLoading(true);

            await api.post('central/request-new-sale-contact', {
                process_id: data?.id
            });

            addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Sua solicitação de contato foi enviada!'
            });
        } catch(err) {
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não foi possível concluir a sua solicitação de contato!'
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <CardStats style={{ margin: 0 }}>
                <p>como está meu pedido?</p>
                {data?.step?.name === "blocked" ? (
                    <>
                        <Prediction>
                            <CalendarTodayOutlinedIcon style={{ color: "var(--atencao-50)" }} />
                            <p>Previsão para conclusão: <strong>Indefinida</strong></p>
                        </Prediction>
                        <CautionBox>
                            <span>
                                <WarningIcon />
                                <p className="caution">Atenção</p>
                            </span>
                            <p className="caution-body">Devido ao obstáculo, não é possível fornecer uma estimativa de conclusão</p>
                        </CautionBox>
                        {data && !data.blocks.find((item) => item.show_to_client === true) ? (
                            <div className="blocks">
                                <hr />
                                <StatsInfo color="var(--erro-50)" iconColor="var(--erro-50)" bgColor="var(--erro-10)">
                                    <WarningIcon />
                                    <p>Obstáculo na análise</p>
                                </StatsInfo>
                                <strong>Obstáculo na análise</strong>
                                <p>
                                    Houve uma pequena inconsistência na análise dos documentos do seu processo. 
                                    Em até 72 horas nossa equipe entrará em contato com você para resolver essa 
                                    inconsistência. Se preferir, você pode entrar em contato com a nossa equipe. 
                                </p>
                            </div>
                        ) : (
                            <>
                                {data && data?.blocks?.map((block) => (
                                    <div className="blocks">                                
                                        <hr />
                                        {block?.solution?.category?.name !== 'WAITING_ANOTHER_PROCESS' ? (
                                            <StatsInfo color="var(--erro-50)" iconColor="var(--erro-50)" bgColor="var(--erro-10)">
                                                <WarningIcon />
                                                <p>Obstáculo na análise</p>
                                            </StatsInfo>
                                        ) : (
                                            <StatsInfo color="var(--atencao-50)" iconColor="var(--atencao-50)" bgColor="var(--atencao-10)">
                                                <WarningIcon />
                                                <p>Aguardando procedimento</p>
                                            </StatsInfo>
                                        )}
                                        <strong>{block?.reason?.public_name}</strong>
                                        <p>{block?.reason?.description}</p>
                                        {block?.solution?.category?.name === 'INFO_DOCUMENT' &&
                                        block?.document?.name === 'Procuração assinada' && (
                                            <Button onClick={() => {
                                                addRequirement({
                                                    ...block?.document,
                                                    open: true,
                                                    proxy_form_path: data?.proxy_form_path,
                                                    proxy_path: data?.proxy_path
                                                });
                                                action()
                                            }}>
                                                {data?.proxy_path ?
                                                    <span>Edite e baixe sua procuração aqui</span>
                                                :
                                                    <span className="edit">Preencha sua procuração aqui</span>
                                                }
                                                <ChevronRightOutlinedIcon />
                                            </Button>
                                        )}
                                        {block.solution.category.name === 'NEW_SALE' && (
                                            <Button
                                                loading={loading}
                                                onClick={() => handleContact()}
                                            >
                                                {loading ?
                                                    <RotateLeftIcon className="icon-spin" />
                                                : <>
                                                    Solicitar contato do consultor
                                                    <ChevronRightOutlinedIcon />                                                    
                                                </>}
                                            </Button>
                                        )}
                                        {block?.solution?.category?.name === 'INFO_DOCUMENT' &&
                                        block?.document?.name !== 'Procuração assinada' && (
                                            <Button onClick={() => {
                                                addRequirement({
                                                    ...block?.document,
                                                    open: true,
                                                    proxy_form_path: data?.proxy_form_path,
                                                    proxy_path: data?.proxy_path
                                                });
                                                action()
                                            }}>
                                                Saiba sobre o formato e como emitir
                                                <ChevronRightOutlinedIcon />
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Prediction>
                            <CalendarTodayOutlinedIcon style={percentAnalysis > 100 ? {color: "var(--atencao-50)"} : {color: "var(--principal-50)"}} />
                            <p>Previsão para conclusão: <strong>{data?.end_analysis_prediction_date && format(parseISO(data?.end_analysis_prediction_date), "dd/MM")}</strong></p>
                        </Prediction>
                        <div className="indicators">
                            <p>Início da análise</p>
                            <p>Fim da análise</p>
                        </div>
                        <CardPercent className="m4" color="var(--principal-50)">
                            <span className="bar">
                                <LinePercent
                                    size={percentAnalysis > 100 ? 100 : percentAnalysis}
                                    color={percentAnalysis > 100 ? "var(--atencao-50)" : "var(--principal-50)"}
                                />
                            </span>
                        </CardPercent>
                        <div className="indicators">
                            <p>{data?.start_analysis_prediction_date && format(parseISO(data?.start_analysis_prediction_date), "dd/MM")}</p>
                            <p>{data?.end_analysis_prediction_date && format(parseISO(data?.end_analysis_prediction_date), "dd/MM")}</p>
                        </div>
                        {percentAnalysis > 100 ? (
                            <>
                                <StatsInfo>
                                    <AccessTimeIcon style={{ color: "var(--atencao-50)" }} />
                                    <span>A análise demorará mais que o esperado</span>
                                </StatsInfo>
                                <p>Pedimos desculpas, mas a análise está levando um tempo 
                                maior que o inicialmente previsto. Estamos trabalhando para que em no máximo 2 
                                dias úteis essa etapa seja concluída.</p>
                            </>
                        ) : (
                            <>
                                <StatsInfo>
                                    <img src={BasicLoading} alt="Icon" />
                                    <span>Análise em andamento</span>
                                </StatsInfo>
                                <p>Em {countAnalysisDaysRemain > 1 ? `${countAnalysisDaysRemain} 
                                dias` : `${countAnalysisDaysRemain} dia`} a análise dos documentos do seu processo 
                                serão concluidas.</p>
                            </>
                        )}
                    </>
                )}
            </CardStats>
            {data?.step?.name === "blocked" && (
                <CardService>
                    <p>Quer saber mais?</p>
                    <Contact>
                        <a style={{ cursor: "pointer" }} href="/registro-de-atividades" rel="noreferrer">
                            <AlertInfo />
                            <p>Acompanhe o histórico do caso</p>
                        </a>
                        <a
                            style={{ cursor: "pointer" }}
                            target="_blank"
                            href="https://api.whatsapp.com/send?phone=5521990257203&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Meu%20pedido%20est%C3%A1%20com%20um%20obst%C3%A1culo%20em%20an%C3%A1lise%2C%20poderia%20me%20ajudar%3F"
                            rel="noreferrer"
                        >
                            <RedChat />
                            <p>Fale com um especialista agora</p>
                        </a>
                    </Contact>
                </CardService>
            )}
            <CardService>
                <p>Documentos em análise:</p>
                {data?.service_documents.map((req) => (
                    <AnalysisDocuments key={req?.id} data={{
                        ...req,
                        applicant: data.applicant.name,
                        type: data?.type,
                        proxy_form_path: data?.proxy_form_path,
                        proxy_path: data?.proxy_path
                    }} />
                ))}
            </CardService>
        </>
    );
}