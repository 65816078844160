/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useState, useEffect, useRef } from "react";
// import Slider from "react-slick";

// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// import { format, parseISO } from 'date-fns';

import { Player, BigPlayButton } from "video-react";

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxHeader,
    BoxContent,
    BoxImage,
    CardButtons,
    Button
} from "./styles";

import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';

import "video-react/dist/video-react.css";

import { useRequirement } from '../../../hooks/requirement';

export function ModalExigencia({ openModal, closeModal }) {
    const { requirement, removeRequirement } = useRequirement();

    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const vidRef = useRef(null);

    function handleCloseModal() {
        setOpen(false);
        setTimeout(() => {
            setVisible(false);
            removeRequirement();
            vidRef.current?.video.pause();
        }, 600);
    }

    useEffect(() => {
        if(!!Object.keys(requirement).length && requirement?.open && openModal) {
            setVisible(true);
            setTimeout(() => {
                setOpen(true);
            }, 600);
        }
    }, [requirement , openModal]);

    return (
        <PageContainer open={open} visible={visible}>
            <WhiteBox>
                <CloseButton onClick={ () => { handleCloseModal(); closeModal() }}>
                    Fechar
                    <CancelIcon />
                </CloseButton>
                <BoxHeader />
                <BoxContent>
                    <BoxImage className="video">
                        <Player
                            ref={vidRef}
                            playsInline
                            src={requirement?.video_url}
                            width="100%"
                        >
                            <BigPlayButton position="center" />
                        </Player>
                    </BoxImage>
                    <h4>
                        {requirement?.name}{requirement?.name && " "}
                        {requirement?.specification}{requirement?.specification && " "}
                        {requirement?.from_who && requirement?.from_who.replace("applicant", requirement?.applicant?.name)}
                    </h4>
                    <p dangerouslySetInnerHTML={{ __html: requirement?.requirement_observation }} />
                    <CardButtons>
                        <Button
                            bgColor="var(--principal-40)"
                            onClick={() => window.location.href=`/` }
                        >
                            Saiba onde emitir essa certidão
                            <ChevronRightOutlinedIcon />
                        </Button>
                    </CardButtons>
                </BoxContent>
            </WhiteBox>
        </PageContainer>
    );
}
