// @ts-nocheck
import {
    Container,
    CardStatsDocument,
    CardButton,
    CardStats
} from "./styles";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { ReactComponent as ExternalLink } from '../../../assets/icons/external_link.svg';

import { Input } from "../InputCard";

import { useRequirement } from "../../../hooks/requirement";

export default function DocumentationDocuments({ data, controllerModal, FMDModal }) {
  const { addRequirement } = useRequirement();

  let bgColor = "";
  let iconColor = "";
  let color = "";
  let icon = "";
  let text = "";

  let emissionBgColor;
  let emissionIconColor;
  let emissionColor;
  let emissionIcon;
  let emissionStatusText;
  let emissionTitle;
  let emissionText;

  switch (data?.status) {
    case "ARRIVED_SUCCESSFULLY":
      bgColor = "#E5FFEB";
      iconColor = "#1F9833";
      color = "#2E5D38";
      icon = <CheckCircleIcon />;
      text = "Documento recebido";
      break;
    case "PENDING":
      bgColor = "#FFF8E1";
      iconColor = "#EE9E00";
      color = "#42455F";
      icon = <AccessTimeIcon />;
      text = "Documento não recebido";
      break;
    case "ARRIVED_WRONG":
      bgColor = "#FEE2E8";
      iconColor = "#DB0A3C";
      color = "#DB0A3C";
      icon = <CancelIcon />;
      text = "Documento recebido não aprovado";
      break;
    case "NOT_REQUIRED":
      bgColor = "#F2F4F7";
      iconColor = "#7A7FA7";
      color = "#42455F";
      icon = <InsertDriveFileOutlinedIcon />;
      text = "Documento não necessário";
      break;
    default:
      break;
  }

    switch (data?.emission?.status) {
      case 'Em busca':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Buscando o documento';
        emissionText = 'Ainda estamos procurando o documento. Não se preocupe, assim que acharmos iremos te avisar';
        break;
      case 'Aguardando Requerimento':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Aguardando requerimento';
        emissionText = 'O cartório solicitou um requerimento específico para a emissão do documento pela nossa empresa. Por favor verifique as instruções enviadas para o seu e-mail para dar prosseguimento ao pedido.';
        break;
      case 'Aguardando requerimento do cliente':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Aguardando requerimento';
        emissionText = 'Ainda estamos procurando o documento. Não se preocupe, assim que acharmos iremos te avisar';
        break;
      case 'Aguardando dados':
        emissionBgColor = '#FEE2E8';
        emissionIconColor = '#DB0A3C';
        emissionColor = '#A70028';
        emissionIcon = <ErrorIcon />;
        emissionStatusText = 'Ação necessária';
        emissionTitle = 'Preencher formulário';
        emissionText = 'Precisamos que complete seus dados para darmos andamento ao seu pedido';
        break;
      case 'Concluído':
        emissionBgColor = '#E5FFEB';
        emissionIconColor = '#1F9833';
        emissionColor = '#2E5D38';
        emissionIcon = <CheckCircleIcon />;
        emissionStatusText = 'Tudo certo até aqui';
        emissionTitle = 'Certidão chegou no nosso escritório';
        emissionText = 'Tudo certo! O seu documento já está com a gente!';
        break;
      case 'Certidão Chegou':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Recebemos a confirmação da entrega da certidão';
        emissionText = 'Ainda estamos verificando se está tudo correto com o seu documento';
        break;
      case 'Certidão chegou':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Recebemos a confirmação da entrega da certidão';
        emissionText = 'Ainda estamos verificando se está tudo correto com o seu documento';
        break;
      case 'Aguardando código de rastreio':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Aguardando código de rastreio';
        emissionText = 'Estamos esperando o código de rastreio ser liberado pelos correios';
        break;
      case 'Orçamento solicitado':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Orçamento solicitado no cartório';
        emissionText = 'Estamos esperando o cartório nos responder sobre o pedido de orçamento';
        break;
      case 'Aguardando retirada':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Esperando o documento sair do cartório';
        emissionText = 'Em alguns dias o seu documento estará liberado para envio';
        break;
      case 'Pagamento confirmado pelo cartório':
        emissionBgColor = '#E5FFEB';
        emissionIconColor = '#1F9833';
        emissionColor = '#2E5D38';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Tudo certo até aqui';
        emissionTitle = 'Sua certidão já está sendo confeccionada';
        emissionText = 'Como o pagamento foi confirmado, o cartório já começou a preparar o seu documento';
        break;
      case 'Aguardando pagamento':
        emissionBgColor = '#FEE2E8';
        emissionIconColor = '#DB0A3C';
        emissionColor = '#A70028';
        emissionIcon = <ErrorIcon />;
        emissionStatusText = 'Ação necessária';
        emissionTitle = 'Aguardando pagamento do orçamento enviado pelo cartório';
        emissionText = 'Estamos aguardando o pagamento dos valores solicitados pelo cartório. Para dar prosseguimento ao pedido, você precisará realizar o pagamento de acordo com as instruções passadas por e-mail';
        break;
      case 'Pedido pago pelo cliente':
        emissionBgColor = '#FFF8E1';
        emissionIconColor = '#EE9E00';
        emissionColor = '#573A00';
        emissionIcon = <AccessTimeIcon />;
        emissionStatusText = 'Aguardando procedimento';
        emissionTitle = 'Aguardando confirmação de pagamento';
        emissionText = 'Estamos esperando o cartório confirmar o recebimento do seu pagamento';
        break;
      default:
        break;
    }

  return (
    <Container key={data?.reference_id}>
      {(data?.is_fmd && data?.enabled_to_fmd === 1) ? (
        <>
          {data?.emission ? (
            <>
              <CardStatsDocument color='var(--cinza-90)' iconColor='var(--cinza-90)' bgColor='var(--cinza-30)'>
                <InsertDriveFileOutlinedIcon />
                <p>Emissão de certidão inclusa</p>
              </CardStatsDocument>
              <p className="title">{data?.name}:</p>
              <p className="description">
                {data?.format}{" "}
                {data?.name}{data?.from_who && " "}
                {data?.from_who && data?.from_who.replace("applicant", data?.applicant)}{data.specification && ", "}
                {data?.specification}
              </p>
              <CardStatsDocument color={emissionColor} iconColor={emissionIconColor} bgColor={emissionBgColor}>
                {emissionIcon}
                <p>{emissionStatusText}</p>
              </CardStatsDocument>
              <p className="title">{emissionTitle}</p>
              <p className="description">{emissionText}</p>
              {(data?.emission?.status === 'Aguardando dados' || data?.emission?.status === 'Aguardando pagamento') && (
                <CardButton
                  className='black'
                  onClick={() => { addRequirement(data); FMDModal() }}
                  style={{ marginBottom: '16px' }}
                >
                  {data?.emission?.status === 'Aguardando dados' ? 'Completar dados' : 'Ver valor do orçamento'}
                  <ChevronRightOutlinedIcon />
                </CardButton>
              )}
            </>
          ) : (
            <>
              {data?.form_filled === 1 ? (
                <>
                  <CardStatsDocument color='var(--cinza-90)' iconColor='var(--cinza-90)' bgColor='var(--cinza-30)'>
                    <InsertDriveFileOutlinedIcon />
                    <p>Emissão de certidão inclusa</p>
                  </CardStatsDocument>
                  <p className="title">{data?.name}:</p>
                  <p className="description">
                    {data?.format}{" "}
                    {data?.name}{data?.from_who && " "}
                    {data?.from_who && data?.from_who.replace("applicant", data?.applicant)}{data.specification && ", "}
                    {data?.specification}
                  </p>
                  <CardStatsDocument color='#573A00' iconColor='#EE9E00' bgColor='#FFF8E1'>
                    <ErrorIcon />
                    <p>Aguardando procedimento</p>
                  </CardStatsDocument>
                  <p className="title">Recebemos seu formulário</p>
                  <p className="description">
                    Estamos analisando os seus dados de acordo com a respostas do formulário. Caso estejam corretos iremos
                    entrar em contato com o cartório para realizar o orçamento da emissão. Caso haja alguma divergência
                    entraremos em contato com você!
                  </p>
                  <CardButton
                    className='black'
                    onClick={() => { addRequirement(data); FMDModal() }}
                    style={{ marginBottom: '16px' }}
                  >
                    Editar dados
                    <ChevronRightOutlinedIcon />
                  </CardButton>
                </>
              ) : (
                <>
                  <CardStatsDocument color='var(--cinza-90)' iconColor='var(--cinza-90)' bgColor='var(--cinza-30)'>
                    <InsertDriveFileOutlinedIcon />
                    <p>Emissão de certidão inclusa</p>
                  </CardStatsDocument>
                  <p className="title">{data?.name}:</p>
                  <p className="description">
                    {data?.format}{" "}
                    {data?.name}{data?.from_who && " "}
                    {data?.from_who && data?.from_who.replace("applicant", data?.applicant)}{data.specification && ", "}
                    {data?.specification}
                  </p>
                  <CardStatsDocument color='#A70028' iconColor='#DB0A3C' bgColor='#FEE2E8'>
                    <ErrorIcon />
                    <p>Ação necessária</p>
                  </CardStatsDocument>
                  <p className="title">Preencher formulário</p>
                  <p className="description">Precisamos que complete seus dados para darmos andamento ao seu pedido</p>
                  <CardButton
                    className='black'
                    onClick={() => { addRequirement(data); FMDModal() }}
                    style={{ marginBottom: '16px' }}
                  >
                    Completar dados
                    <ChevronRightOutlinedIcon />
                  </CardButton>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>        
          <CardStatsDocument color={color} iconColor={iconColor} bgColor={bgColor}>
            {icon}
            <p>{text}</p>
          </CardStatsDocument>
          <p className="title">{data.name}:</p>
          <p className="description">
            {data?.format}{" "}
            {data?.name}{data?.from_who && " "}
            {data?.from_who && data?.from_who.replace("applicant", data?.applicant)}{data.specification && ", "}
            {data?.specification}
          </p>
          {text !== "Documento recebido" && (
            <CardButton
              className={`
              ${text === "Documento recebido não aprovado" ? "black" : ''}
              ${data.proxy_path && data.name === "Procuração assinada" ? "black" : ''}
              `}
              onClick={() => { addRequirement(data); controllerModal() }}
            >
              {text === "Documento recebido não aprovado" && (
                <span>Veja o que aconteceu</span>
              )}
              {data.name === "Procuração assinada" && text !== "Documento recebido não aprovado" && (
                <>
                  {data.proxy_path ?
                    <span>Edite e baixe sua procuração aqui</span>
                  :
                    <span className="edit">Preencha sua procuração aqui</span>
                  }
                </>
              )}
              {data.name !== "Procuração assinada" && text !== "Documento recebido não aprovado" && (
                <span>Saiba mais sobre o formato e como emitir</span>
              )}
              <ChevronRightOutlinedIcon />
            </CardButton>
          )}
        </>
      )}
      {data?.emission?.tracking_code && (
        <>
          {/* <hr style={{ height: "1px", background: "var(--cinza-30)", border: "0", margin: "16px 0" }} /> */}
          <CardStats>
            <p style={{ marginBottom: "4px" }}>Rastreamento</p>
            <p className="followUp">Acompanhe a chegada da sua certidão em nosso escritório.</p>
            <Input
                id={`user-${data?.id}-cod`}
                label="Clique para copiar o código de rastreio:"
                defaultValue={data?.emission?.tracking_code}
            />
            <a
              target="_blank"
              href="https://www.dhl.com/br-pt/home/tracking.html"
              rel="no-referrer noreferrer"
            >
              Acompanhar rastreamento
              <ExternalLink />
            </a>
          </CardStats>
        </>
      )}
    </Container>
  );
}