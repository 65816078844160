import { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api"

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        const access_token = localStorage.getItem('@Central:token');
        const user = localStorage.getItem('@Central:user');

        if(access_token && user) {
            api.defaults.headers['Authorization'] = `Bearer ${access_token}`;
            
            return { access_token, user: JSON.parse(user) }
        }

        return {};
    });

    const signIn = useCallback(async ({ email, password }) => {
        const response = await api.post('oauth/token', {
            grant_type: "password",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            username: email,
            password,
            scope: ""
        })

        const { access_token, user } = response.data;

        api.defaults.headers['Authorization'] = `Bearer ${access_token}`;

        localStorage.setItem('@Central:token', access_token);
        localStorage.setItem('@Central:user', JSON.stringify(user));

        setData({ access_token, user })
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@Central:token');
        localStorage.removeItem('@Central:user');
        localStorage.removeItem('apc_ann_errors');
        localStorage.removeItem('apc_user_id');
        localStorage.removeItem('apc_debug_url');
        localStorage.removeItem('apc_local_id');
        localStorage.removeItem('@Central:requirement');
        localStorage.removeItem('@Central:notariOffice');

        setData({});
    }, []);

    return (
        <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if(!context) {
        throw new Error('useAuth só pode ser usado com o AuthProvider');
    }

    return context;
};