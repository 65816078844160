import styled, { css } from "styled-components";
import { shade } from 'polished';
import  { TabContent } from '../../styles/components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100vw;
  height: auto;

  ${TabContent} {
    background: var(--white);
  }

  h3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--cinza-90);
    width: 100%;
    max-width: 80rem;
    margin: 2rem auto 1rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 1px solid var(--principal-50);
      }
      th {
        padding: 1.5rem 1rem 1rem;
      }
    }

    tbody {
      position: relative;
      width: 100%;

      tr {
        border-bottom: 1px solid var(--cinza-40);

        &:last-child {
          border: none;
        }
      }

      td {
        padding: 1rem;

        > p {
          line-height: 1.5rem;
          font-size: var(--font-17);
          color: var(--cinza-80);

          &.hour {
            font-size: var(--font-15);
          }

          &.info {
            display: flex;
          }
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    padding-bottom: 32px;

    h3 {
      font-size: 21px;
      line-height: 28px;
      color: var(--principal-50);
      padding: 0 16px;
    }

    table {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  height: 11.375rem;
  padding: 3.5rem 0 0;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    height: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  color: var(--cinza-100);

  @media screen and (max-width: 950px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  color: var(--principal-50);

  p {
    font-size: var(--font-15);
    line-height: 1.75rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 950px) {
    margin-bottom: 4px;

    p {
      font-size: 15px;
      line-height: 28px;
      font-weight: 700;
    }
  }
`;

export const ItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const WhiteBox = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: var(--white);
  width: 100%;
  max-width: 600px;
  padding: 16px 16px 36px;
  border-radius: 0.5rem;
  border: 1px solid var(--cinza-40);

  p {
    font-size: 17px;
    line-height: 28px;

    &.title {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 28px;
    }
  }

  hr {
    width: 100%;
    border: 1px solid  var(--cinza-40);
    margin: 20px 0;
  }

  button {
    font-size: 15px;
    line-height: 24px;
  }

  @media screen and (max-width: 950px) {
    display: flex;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  max-height: 54px;
  width: 100%;
  max-width: 174px;
  padding: 1rem 0;
  margin: 8px 0 0 0;
  background-color: var(--principal-40);
  color: var(--cinza-100);
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background-color: ${shade(0.1, "#32C050")};
  }

  ${({ disabled }) => disabled && css`
    background-color: var(--cinza-40);
    color: var(--white);
    pointer-events: none;

    &:hover {
      background-color: ${shade(0.2, "#C7CADD")};
    }
  `}

`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  margin-top: 8px;
  cursor: pointer;

  p {
    font-size: 14px;
    color: var(--principal-50);
  }

  @media screen and (max-width: 950px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 8px;
    height: 2.5rem;
  }
`;

export const TooltipInfo = styled.div`
  transition: all 0.5s ease-in-out;
  opacity: ${({ active }) => active ? 1 : 0};
  font-size: var(--font-17);
  line-height: 1.5rem;
  text-align: left;
  width: 24.25rem;
  background: var(--cinza-100);
  color: var(--white);
  border-radius: 8px;
  padding: 1rem;
  position: absolute;
  top: 180%;
  ${({ active }) => active ? 'z-index: 10;' : 'z-index: -1;'}

  p {
    font-size: 14px;
    color: var(--white);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 6%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent var(--cinza-100) transparent;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;

    ${({ active }) =>
      active ? 
      css `
        display: flex;
        z-index: 10;
        top: 160%;
        right: 4px;

        &:after {
          left: 87%;
        }
      ` :
      css `
        display: none;
      `
    }
  }
`;
