import styled, { css } from "styled-components";
import { shade } from 'polished';
import { TabHeader } from '../../styles/components';

import Background from "../../assets/header/fundo-procuracao.png";
import BackgroundMobile from "../../assets/header/fundo-procuracao-mobile.svg";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100%;
  height: auto;

  @media screen and (min-width: 426px) {
    .customTab {
      margin-top: 64px;
    }
  }

  @media screen and (max-width: 950px) {
    .customTab {
      background: var(--white);
      box-shadow: var(--shadow-baixo-pequena);
    }
  }

  @media screen and (max-width: 425px) {
    .customTab {
      margin-top: 36px;
    }
  }
`;

export const PageHeader = styled.section`
    display: flex;
    justify-content: center;
    width: 100vw;
    background: url(${Background}) no-repeat right top;

    @media screen and (max-width: 950px) {
      background-size: contain;
    }

    @media screen and (max-width: 425px) {
      background: url(${BackgroundMobile}) no-repeat right top;
    }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  max-height: 25.75rem;
  padding: 18px 0 36px;
  position: relative;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;

    img.frameDesktop {
      max-width: 180px;
      margin: 44px 0 16px;
    }
  }

  @media screen and (min-width: 425px) {
    img.frameMobile {
      display: none;
    }
  }

  @media screen and (max-width: 425px) {
    img {
      &.frameDesktop {
        display: none;
      }
      &.frameMobile {
        position: absolute;
        top: 14px;
        left: 21px;
      }
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;

  > P {
    font-size: 17px;
    line-height: 28px;
    color: var(--cinza-90);

    &.title {
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    &.description {
      max-width: 320px;
    }
  }

  > h2 {
    font-size: 32px;
    line-height: 36px;
    color: var(--cinza-100);
    max-width: 415px;
    margin-bottom: 16px;

    > span {
      color: var(--principal-50);
    }
  }

  &.defaultInfo {
    width: 100vw;

    > p {
      width: 100%;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 950px) {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 425px) {
    margin-top: 90px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const InfoProcuracao = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  margin: 64px auto 0;

  > h3 {
    font-size: 24px;
    line-height: 32px;
    color: var(--cinza-90);
  }

  > p {
    font-size: 17px;
    line-height: 28px;
    color: var(--cinza-90);
    max-width: 600px;
    margin: 16px 0 32px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 700;
    background: var(--principal-40);
    color: var(--cinza-100);
    border-radius: 8px;
    padding: 12px 24px 12px 16px;
    max-width: 264px;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      background: ${shade(0.1, "#32C050")};
    }
  }

  @media screen and (max-width: 950px) {
    padding: 0 16px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: var(--font-17);
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  max-width: 250px;
  outline: none;

  &.midButton {
    border: none;
  }

  svg {
    margin-left: 0.5rem;
  }
`;
