// @ts-nocheck
import { useState, useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxHeader,
    BoxContent,
    BoxImage,
    CautionBox,
    CardButtons,
    Button,
    Slide,
    ArrowSlider
} from "./styles";

import WarningIcon from '@material-ui/icons/Warning';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Certidao from "../../../assets/certidao/certidao01.png";
import Certidao2 from "../../../assets/certidao/certidao02.png";

export function ModalCertidao({ data, openModal, closeModal }) {
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    function handleCloseModal(e) {
        // (e).preventDefault();
        setOpen(false);
        setTimeout(() => {
            setVisible(false);
        }, 600);
    }

    useEffect(() => {
        if(openModal) {
            setVisible(true);
            setTimeout(() => {
                setOpen(true);
            }, 600);
        }
    }, [openModal]);

    const settings = {
        dots: true,
        dotsClass: 'slick-dots',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowSlider right><ArrowForwardIosIcon /></ArrowSlider>,
        prevArrow: <ArrowSlider><ArrowForwardIosIcon className="left" /></ArrowSlider>
    };

    return (
        <PageContainer open={open} visible={visible}>
            <WhiteBox>
                <CloseButton onClick={() => { handleCloseModal(); closeModal() }}>
                    Fechar
                    <CancelIcon />
                </CloseButton>
                <BoxHeader />
                <BoxContent>
                    <h4>Onde encontrar os dados na certidão?</h4>
                    <p>As informações podem estar em várias posições. Isso varia de acordo com o modelo 
                    da certidão. A seguir, você pode ver exemplos de onde encontrar os dados nos modelos mais comuns.</p>
                    <BoxImage>
                        <Slider {...settings}>
                            <Slide>
                                <img src={Certidao} alt="Documentação"/>
                                <p style={{ marginTop: '8px' }}><strong>Certidao tipo 1</strong></p>
                                <p>Nesse modelo. o termo também pode ser chamado de “ordem”</p>
                            </Slide>
                            <Slide>
                                <img src={Certidao2} alt="Documentação"/>
                                <p style={{ marginTop: '8px' }}><strong>Certidao tipo 2</strong></p>
                                <p>Identificamos o Livro, a Folha e o Termo, respectivamente</p>
                            </Slide>
                        </Slider>
                    </BoxImage>
                    <CautionBox>
                        <span>
                            <WarningIcon />
                            <p className="caution">Atenção</p>
                        </span>
                        <p className="caution-body">Caso o formato da sua certidão não esteja como nos exemplos acima, 
                        entre em contato conosco que podemos te ajudar!</p>
                    </CautionBox>
                    <CardButtons>
                        <Button bgColor="var(--principal-40)" onClick={() => { handleCloseModal(); closeModal() }}>
                            Entendi
                        </Button>
                    </CardButtons>
                </BoxContent>
            </WhiteBox>
        </PageContainer>
    );
}
