import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  position: relative;

  span {
    font-size: 14px;
    font-weight: 700;
    color: #c53030;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  font-size: 1.063rem;
  font-weight: bold;
  color: ${({ disabled }) => disabled ? "var(--cinza-50)" : "var(--cinza-90)"};
  letter-spacing: 0.2px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ icon }) => icon ? "0.6875rem 0.5rem 0.6875rem 3rem" : "0.6875rem 1rem"};
  margin-right: 1.5rem;
  position: relative;
  background: var(--white);
  border: 1px solid var(--cinza-50);
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-top: 0.25rem;

  ${(props) => props.isErrored
    && css`
      border-color: #c53030;
    `}

  ${props => props.isFocused && css`
    color: var(--principal-40);
    border-color: var(--principal-40);
  `}

  ${props => props.isFilled && css`
    color: var(--principal-40);
  `}

  span {
    width: 3rem;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    left: 0;
    top: 0;
  }

  input {
    font-size: var(--font-17);
    line-height: 1.75rem;
    border: none;
    outline: none;
    width: 100%;
  }

  input.MuiInputBase-input {
    padding: 1px 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;