/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useRef, useState, useCallback, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import { PageHeader, TabContent, TabBody } from "styles/components";

import {
  Container,
  Content,
  Text,
  Title,
  Welcome,
  Phases,
  CountPhases,
  NumberPhases,
  Separator,
  DescriptionPhase,
  Info,
  MobileInfo,
  TooltipInfo,
  FormContent,
  Wrap,
  AccordionDescription,
  AccordionButtons,
  Arrow,
  SubmitButton,
} from "./styles";

import { Input, Select } from "../../components/Form";

import Header from "../../components/Header";
import Button from "../../components/Button";
// import Accordion from "../../components/Accordion";

import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

import { dataPaises } from "../../data/dataPaises";

/* inicio estilos acordeon */
const Accordion = withStyles({
  root: {
    borderTop: "1px solid var(--cinza-50)",
    borderRadius: "8px",
    boxShadow: "none",
    width: "100%",
    "&:first-child": {
      borderTop: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "var(--white)",
    borderBottom: "1px solid var(--cinza-50)",
    marginBottom: -1,
    padding: "0 24px",
    minHeight: 80,
    "&$expanded": {
      minHeight: 80,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
    padding: "24px",
  },
}))(MuiAccordionDetails);
/* fim estilos acordeon */

export function Portugueses() {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [tooltip, setTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [portugues, setPortugues] = useState([]);
  const [country, setCountry] = useState([]);
  const [concelho, setConcelho] = useState([]);
  const [freguesia, setFreguesia] = useState([]);
  const [expanded, setExpanded] = React.useState("");

  const { addToast } = useToast();
  const { user } = useAuth();

  function handleChange(e) {
    setFormData(e.target.value);
  }

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData((state) => ({ ...state, [name]: value }));
  }

  function handleSubMenu(item, toggle) {
    setTooltip({ [item]: toggle });
  }

  useEffect(async () => {
    await api.get("central/portugueses").then((response) => {
      setPortugues(response.data);
    });

    setCountry(
      dataPaises.map(({ sigla, nome_pais }) => ({
        value: sigla,
        label: nome_pais,
      }))
    );
  }, []);

  useEffect(async () => {
    await api.get("people/conselhos").then((response) => {
      setConcelho(
        response?.data?.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      );
    });

    if (portugues[0]?.concelho) {
      await api
        .get(`people/conselhos/${portugues[0].concelho}/freguesias`)
        .then((response) => {
          setFreguesia(
            response?.data?.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          );
        });
    }
  }, [portugues]);

  // const handleBirthdate = (birthdate) => {
  //     return format(new Date().setDate(new Date().getDate() - birthdate), 'dd/MM/yyyy');
  // }

  useEffect(async () => {
    if (formData?.concelho) {
      await api
        .get(`people/conselhos/${formData?.concelho}/freguesias`)
        .then((response) => {
          setFreguesia(
            response?.data?.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          );
        });
    }
  }, [formData?.concelho]);

  const handleSubmit = useCallback(async (data) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      await api.put(`central/portugueses/${data?.id}`, data);

      addToast({
        type: "success",
        title: "Sucesso",
        description: "Dados atualizados!",
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      addToast({
        type: "error",
        title: "Erro",
        description: "Não possível atualizar seus dados!",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.Appcues.identify(user.id, user);
  }, [user]);

  return (
    <>
      <Container border={expanded}>
        <Header />
        <PageHeader>
          <Content>
            <Text>
              <Title>Portugueses</Title>
              <Welcome className="desktop">
                <p>
                  Ao lado, você pode ver as possíveis etapas do seu procedimento
                  de busca da documentação dos portugueses.
                </p>
                <br />
                <p>
                  É essencial que as informações sobre do português do seu
                  procedimento estejam completas. Dessa forma, poderemos
                  continuar com o andamento do seu pedido.
                </p>
              </Welcome>
              <Welcome className="mobile">
                <p>
                  Abaixo, você pode ver as possíveis etapas do seu procedimento
                  de busca da documentação dos portugueses.
                </p>
                <br />
                <p>
                  É essencial que as informações sobre do português do seu
                  procedimento estejam completas. Dessa forma, poderemos
                  continuar com o andamento do seu pedido.
                </p>
              </Welcome>
            </Text>
            <Phases>
              <p className="title">As etapas</p>
              <p className="description">
                Passe o mouse em cada etapa para saber mais
              </p>
              <span className="mobile">
                <CountPhases>
                  <NumberPhases>
                    <span>1</span>
                  </NumberPhases>
                  <Separator />
                  <NumberPhases>
                    <span>2</span>
                  </NumberPhases>
                  <Separator />
                  <NumberPhases>
                    <span>3</span>
                  </NumberPhases>
                  <Separator />
                  <NumberPhases>
                    <span>4</span>
                  </NumberPhases>
                  <Separator />
                  <NumberPhases>
                    <span>5</span>
                  </NumberPhases>
                </CountPhases>
                <DescriptionPhase>
                  <Info>
                    <p>Informações coletadas</p>
                    <MobileInfo
                      onClick={() =>
                        handleSubMenu("tooltip1", !tooltip?.tooltip1)
                      }
                      onMouseEnter={() =>
                        handleSubMenu("tooltip1", !tooltip?.tooltip1)
                      }
                      onMouseLeave={() => handleSubMenu(false)}
                    >
                      <InfoOutlinedIcon />
                      <span>Saiba mais</span>
                    </MobileInfo>
                    <TooltipInfo active={tooltip?.tooltip1}>
                      Assim que as informações estiverem completas, é hora de
                      entrar em contato com as
                      <br />
                      Conservatórias da região de nascimento do seu ascendente
                      português para localizarmos o seu registro de nascimento.
                    </TooltipInfo>
                  </Info>
                  <Info>
                    <p>Contato com conservatórias</p>
                    <MobileInfo
                      onClick={() =>
                        handleSubMenu("tooltip2", !tooltip?.tooltip2)
                      }
                      onMouseEnter={() =>
                        handleSubMenu("tooltip2", !tooltip?.tooltip2)
                      }
                      onMouseLeave={() => handleSubMenu(false)}
                    >
                      <InfoOutlinedIcon />
                      <span>Saiba mais</span>
                    </MobileInfo>
                    <TooltipInfo active={tooltip?.tooltip2}>
                      Quando a documentação do ascendente português está na
                      primeira etapa significa que é hora de coletar informações
                      necessárias para conseguir dar andamento ao procedimento.
                      <br />
                      Ela finalizará quando todas as informações sobre o
                      ascendente português estiverem completas
                    </TooltipInfo>
                  </Info>
                  <Info>
                    <p>Documento Localizado</p>
                    <MobileInfo
                      onClick={() =>
                        handleSubMenu("tooltip3", !tooltip?.tooltip3)
                      }
                      onMouseEnter={() =>
                        handleSubMenu("tooltip3", !tooltip?.tooltip3)
                      }
                      onMouseLeave={() => handleSubMenu(false)}
                    >
                      <InfoOutlinedIcon />
                      <span>Saiba mais</span>
                    </MobileInfo>
                    <TooltipInfo active={tooltip?.tooltip3}>
                      Com o registro localizado, se o ascendente português
                      nasceu antes de 1911 significa que ele não está
                      informatizado, ou seja, nós vamos precisar de uma cópia
                      física emitida pela conservatória e enviada para nós pelo
                      correio.
                      <br />
                      Caso ele tenha nascido após 1911 significa que o documento
                      está informatizado e dura x dias para conseguirmos o
                      número de assento.
                    </TooltipInfo>
                  </Info>
                  <Info>
                    <p>Envio dos documentos</p>
                    <MobileInfo
                      onClick={() =>
                        handleSubMenu("tooltip4", !tooltip?.tooltip4)
                      }
                      onMouseEnter={() =>
                        handleSubMenu("tooltip4", !tooltip?.tooltip4)
                      }
                      onMouseLeave={() => handleSubMenu(false)}
                    >
                      <InfoOutlinedIcon />
                      <span>Saiba mais</span>
                    </MobileInfo>
                    <TooltipInfo active={tooltip?.tooltip4}>
                      A etapa de envio é mais rápida quando o assento está
                      informatizado, pois será necessário apenas que a
                      conservatória nos envie um número. No caso do registro
                      físico, ele demora cerca de x dias para chegar aqui no
                      nosso escritório.
                    </TooltipInfo>
                  </Info>
                  <Info>
                    <p>Documento no escritório</p>
                    <MobileInfo
                      onClick={() =>
                        handleSubMenu("tooltip5", !tooltip?.tooltip5)
                      }
                      onMouseEnter={() =>
                        handleSubMenu("tooltip5", !tooltip?.tooltip5)
                      }
                      onMouseLeave={() => handleSubMenu(false)}
                    >
                      <InfoOutlinedIcon />
                      <span>Saiba mais</span>
                    </MobileInfo>
                    <TooltipInfo active={tooltip?.tooltip5}>
                      Se você está nessa etapa, pode comemorar que está tudo
                      certo com os documentos do seu ascendente português. Ele
                      está em nossas mãos e estamos fazendo de tudo para
                      despachar tudinho para Portugal e iniciar o seu
                      procedimento lá! Falta pouco!
                    </TooltipInfo>
                  </Info>
                </DescriptionPhase>
              </span>
            </Phases>
          </Content>
        </PageHeader>
        <TabContent className="customTab">
          <TabBody className="no-border">
            {portugues &&
              portugues?.map((infos, index) => (
                <Accordion
                  key={index}
                  square
                  expanded={expanded === index}
                  onChange={handleAccordionChange(index)}
                >
                  <AccordionSummary
                    className="borderNone"
                    aria-controls={`${index}-content`}
                    id={`${index}d-header`}
                  >
                    <AccordionDescription>
                      <span>
                        <p className="title">{infos?.name}</p>
                      </span>
                    </AccordionDescription>
                    <AccordionButtons>
                      <Button
                        btType="var(--principal-40)"
                        color="var(--cinza-100)"
                        label="Conferir dados"
                        full
                        padding="0.625rem 1.5rem"
                      />
                      <Arrow active={expanded === index}>
                        <ExpandMoreIcon />
                      </Arrow>
                    </AccordionButtons>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormContent>
                      <Form ref={formRef} onSubmit={handleSubmit}>
                        <Wrap style={{ display: "none" }}>
                          <Input name="id" defaultValue={infos?.id || null} />
                          <Input name="crc" defaultValue={infos?.crc || null} />
                        </Wrap>
                        <Wrap>
                          <Input
                            label="Nome do português"
                            name="name"
                            placeholder="Seu nome"
                            defaultValue={infos?.name || null}
                          />
                          <Select
                            label="País de nascimento do português"
                            name="nationality"
                            placeholder="Seu país"
                            value={
                              formData?.nationality
                                ? country.filter(
                                    (item) =>
                                      item.value === formData?.nationality
                                  )
                                : country.filter(
                                    (item) => item.value === infos.nationality
                                  )
                            }
                            options={country}
                            onChange={(value) =>
                              handleSelectChange("nationality", value)
                            }
                          />
                        </Wrap>
                        <Wrap>
                          <Input
                            label="Nome do pai do português"
                            name="father_name"
                            placeholder="Insira aqui o nome do pai"
                            defaultValue={infos?.father_name || null}
                          />
                          <Input
                            label="Nome da mãe do português"
                            name="mother_name"
                            placeholder="Nome da mãe do português"
                            defaultValue={infos?.mother_name || null}
                          />
                        </Wrap>
                        <Wrap>
                          <Select
                            label={
                              infos?.nationality === "BR" ||
                              formData?.nationality === "BR"
                                ? "Estado"
                                : "Concelho"
                            }
                            name="concelho"
                            placeholder={
                              infos?.nationality === "BR" ||
                              formData?.nationality === "BR"
                                ? "Insira aqui o estado"
                                : "Insira aqui o concelho"
                            }
                            value={
                              formData?.concelho
                                ? concelho.filter(
                                    (item) => item.value === formData?.concelho
                                  )
                                : concelho.filter(
                                    (item) => item.value === infos.concelho
                                  )
                            }
                            options={concelho}
                            onChange={(value) =>
                              handleSelectChange("concelho", value)
                            }
                          />
                          <Select
                            label={
                              infos?.nationality === "BR" ||
                              formData?.nationality === "BR"
                                ? "Cidade"
                                : "Freguesia"
                            }
                            name="freguesia"
                            placeholder={
                              infos?.nationality === "BR" ||
                              formData?.nationality === "BR"
                                ? "Insira aqui a cidade"
                                : "Insira aqui a freguesia"
                            }
                            value={
                              formData?.freguesia
                                ? freguesia.filter(
                                    (item) => item.value === formData?.freguesia
                                  )
                                : freguesia.filter(
                                    (item) => item.value === infos.freguesia
                                  )
                            }
                            options={freguesia}
                            onChange={(value) =>
                              handleSelectChange("freguesia", value)
                            }
                          />
                        </Wrap>
                        <Wrap>
                          <Input
                            type="text"
                            label="Data ou ano de nascimento"
                            name="birthdate"
                            placeholder="Data ou ano de nascimento"
                            defaultValue={infos?.birthdate || null}
                          />
                          <Input
                            type="number"
                            label="Número do assento"
                            name="assento"
                            placeholder="Insira aqui o número do assento"
                            defaultValue={infos?.assento || null}
                          />
                        </Wrap>
                        <SubmitButton disabled={loading} type="submit">
                          {loading ? (
                            <RotateLeftOutlinedIcon className="icon-spin" />
                          ) : (
                            <>
                              <SaveOutlinedIcon />
                              Salvar alterações
                            </>
                          )}
                        </SubmitButton>
                      </Form>
                    </FormContent>
                  </AccordionDetails>
                </Accordion>
              ))}
          </TabBody>
        </TabContent>
      </Container>
    </>
  );
}
