// @ts-nocheck
import React, { useState, useEffect } from "react";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { TabContent } from "styles/components";

import {
    Container,
    PageHeader,
    Content,
    Text,
    InfoProcuracao,
    Button
} from "./styles";

import Header from "../../components/Header";
import { ModalCertidao } from "../../components/Modal";

import Frame from "../../assets/header/frame-procuracao.png";
import FrameMobile from "../../assets/header/frame-procuracao-mobile.png";

import { useRequirement } from '../../hooks/requirement';

export function EmissaoCertidao() {
    const { requirement } = useRequirement();

    const [certidaoModal, setCertidaoModal] = useState(false);

    useEffect(() => {
        // inicio typeform
        var qs,js,q,s,d= document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/";
        
        if(!gi.call(d,id)) { 
            js=ce.call(d,"script");
            js.id=id; js.src=b+"embed.js";
            q=gt.call(d,"script")[0];
            q.parentNode.insertBefore(js,q)
        }
        // fim typeform
    }, []);

    return (
        <>
            <Header />
            <Container>
                <PageHeader>
                    <Content>
                        <Text>
                            <p className="title">{requirement?.type}</p>
                            <h2>
                                Formulário de dados da certidão para o pedido de
                                <span> {requirement?.applicant}</span>
                            </h2>
                            <p className="description">
                                Preencha esses dados para dar andamento à emissão de certidão do seu pedido
                            </p>
                            <p className="description">
                                Lembrando que, no seu plano fechado com a gente, o serviço de pedido de emissão de 
                                documentos é realizado por nós do Cidadania Já!
                            </p>
                        </Text>
                        <img className="frameDesktop" src={Frame} alt="Frame"/>
                        <img className="frameMobile" src={FrameMobile} alt="Frame"/>
                    </Content>
                </PageHeader>
                <InfoProcuracao style={{ alignItems: 'center' }}>
                    <h3>Alguma dúvida para achar os dados?</h3>
                    <p style={{ textAlign: 'center' }}>Alguns modelos de certidão têm um padrão de posicionamento. Assim, fica mais fácil de saber qual 
                        número é do livro, da folha e do termo da certidão.</p>
                    <Button type="button" onClick={() => setCertidaoModal(true)}>
                        Ver onde achar os dados
                    </Button>
                </InfoProcuracao>
                <TabContent className="customTab">
                    <div
                        class="typeform-widget"
                        data-url={`https://contatocidadaniaja.typeform.com/to/Ly2sGj?central=1&service_code=${requirement?.service_code}&identifier=${requirement?.identifier}&reference_id=${requirement?.reference_id}&typeform-medium=embed-snippet`}
                        style={{ width: "100%", height: "500px" }}
                    ></div>
                    {/* <div
                        data-tf-widget="Ly2sGj"
                        data-tf-hidden={`central=1,service_code=${requirement?.service_code},identifier=${requirement?.identifier},reference_id=${requirement?.reference_id}`}
                        style={{ width: "100%", height: "500px" }}
                    ></div> */}
                </TabContent>
                {/* {requirement && !requirement.proxy_form_path && (
                    <Text className="defaultInfo">
                        <p className="description">
                            No momento não há nenhum formulário de procuração a ser preenchido/alterado para esse cliente!
                        </p>
                    </Text>
                )} */}
                <InfoProcuracao>
                    <h3>Já preencheu os dados do formulário?</h3>
                    <p>
                        Em breve nossa equipe entrará em contato para realizar o orçamento da emissão do seu documento 
                        junto ao Cartório. Enquanto isso, que tal acompanhar o andamento dos seus pedidos na Central? 
                        Clique no botão abaixo para voltar para a página principal.
                    </p>
                    <a href="/">
                        <ChevronLeftIcon />
                        Voltar para meus pedidos
                    </a>
                </InfoProcuracao>
            </Container>
            <ModalCertidao openModal={certidaoModal} closeModal={() => setCertidaoModal(false)} />
        </>
    );
}