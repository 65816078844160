// @ts-nocheck
import { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from "../../../hooks/auth";

import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import IconPerfil from '../../../assets/menu/icon-perfil.svg';
import { ReactComponent as LogOut } from '../../../assets/menu/logOut.svg';
import { ReactComponent as Settings } from '../../../assets/menu/settings.svg';
import { ReactComponent as DisableBell } from '../../../assets/icons/sino-sem-notificacao.svg';
import { ReactComponent as ActiveBell } from '../../../assets/icons/sino-com-notificacao.svg';
import { ReactComponent as NotificationCheck } from '../../../assets/icons/notification-check.svg';
import { ReactComponent as NotificationAlert } from '../../../assets/icons/notification-alert.svg';
import { ReactComponent as NotificationUser } from '../../../assets/icons/notification-user.svg';
import { ReactComponent as NoNotification } from '../../../assets/icons/no-notification.svg';

import {
  Container,
  Items,
  Item,
  Sub,
  SubItem,
  NotificationContainer,
  ArrowNotification
} from "./styles";

import api from "../../../services/api";

const subMenus = {
  perfil: false,
  contato: false
};

export default function Menu() {
  let history = useHistory();
  const [subMenu, setSubMenu] = useState(subMenus);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user, signOut } = useAuth();

  function handleSubMenu(item, toggle) {
    setSubMenu((state) => ({ ...state, [item]: toggle }));
  }

  function handleNavigate(route) {
    history.push(route);
  }

  const handleNotification = useCallback(async() => {
    setOpenNotification(!openNotification);
  }, [openNotification]);

  const handleMarkRead = useCallback(async(data) => {
    try {
      await api.patch(`central/notifications/${data?.id}/mark-as-read`);
      
      setLoading(true);
      
      await api.get('central/notifications')
      .then((response) => setNotifications(response.data.data.filter((read) => 
      read.read_at === null
      )));
      
      history.push({ pathname: '/registro-de-atividades', state: data });
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await api.get('central/notifications')
                  .then((response) => setNotifications(response.data.data.filter((read) =>
                    read.read_at === null
                  )));
      } catch(err) {
        console.log(err)
      }
    }
    fetchData();
  }, []);

  var url = useLocation();

  var pathnameUrl = url.pathname;

  var retorno = pathnameUrl.split("/");

  const handleLogout = useCallback(async () => {
    try {
      signOut();

      history.push('/login');
    } catch(err) {
      console.log("Logout falhou", err)
    }
  }, [signOut]);

  return (
    <Container>
      <Items>
        <li>
          <Item
            onClick={() => handleNavigate("/")}
            efeitoHover={retorno[1] == ""}>
            Meus pedidos
          </Item>
        </li>
        <li>
          <Item
            onClick={() => handleNavigate("/portugueses")}
            efeitoHover={retorno[1] == "portugueses"}>
            Portugueses
          </Item>
        </li>
        <li>
          <Item
            onClick={() => handleNavigate("/pagamentos")}
            efeitoHover={retorno[1] == "pagamentos"}>
            Pagamentos
          </Item>
        </li>
        <li>
          <Item
            onClick={() => handleNavigate("/perguntas-frequentes")}
            efeitoHover={retorno[1] == "perguntas-frequentes"}>
            Perguntas Frequentes
          </Item>
        </li>
        <li>
          <Item
            onClick={() => handleSubMenu("contato", !subMenu.contato)}
            className="contactButton">
            Contato
            <KeyboardArrowDownOutlinedIcon />
            <Sub open={subMenu.contato}>
              <SubItem onClick={() => handleNavigate("/contato")}>
                Contato
              </SubItem>
              <SubItem onClick={() => handleNavigate("/sugestoes")}>
                Sugestões
              </SubItem>
              <SubItem onClick={() => handleNavigate("/criticas")}>
                Críticas
              </SubItem>
            </Sub>
          </Item>
        </li>
        <li>
          <Item onClick={() => handleNotification()}>
            {notifications.length > 0 ? (
              <ActiveBell />
            ) : (
              <DisableBell />
            )}
            <ArrowNotification active={openNotification} />
          </Item>
          <NotificationContainer active={openNotification}>
            <>
              <p>Notificações</p>
              {loading ? (
                  <div>
                    <RotateLeftOutlinedIcon className="icon-spin" />
                  </div>
              ) : (
                notifications.length > 0 ? notifications.slice(0, 5).map((notification) => 
                  notification?.read_at === null && (
                    <>
                      <div onClick={() => handleMarkRead(notification)}>
                        <NotificationCheck />
                        <div>
                          <span className="header">
                            <p className="title">
                              {notification?.title}
                            </p>
                            <span>10/07</span>
                          </span>
                          <p className="body">{notification?.body}</p>
                          <span>
                            <NotificationUser />
                            <span>{notification?.meta?.info}</span>
                          </span>
                        </div>
                      </div>
                      <hr />
                    </>
                  )
                ) : (
                  <div>
                    <div style={{ alignItems: "center", gap: "8px" }}>
                      <NoNotification />
                      <p className="body">Não há novas notificações!</p>
                    </div>
                  </div>
                )
              )}
              <a href="/registro-de-atividades">Ver tudo</a>
            </>
          </NotificationContainer>
        </li>
        <li>
          <Item
            onClick={() => handleSubMenu("perfil", !subMenu.perfil)}
            green={subMenu.perfil}
            className="accordionButton">
            <img src={IconPerfil} alt="Perfil"/>
            Meu perfil
            <KeyboardArrowDownOutlinedIcon />
            <Sub open={subMenu.perfil}>
              {user?.user_type !== 'PERSON' && (
                <SubItem onClick={() => handleNavigate("/configuracoes")}>
                  <Settings />
                  Configurações
                </SubItem>
              )}
              <SubItem onClick={() => handleNavigate("/registro-de-atividades")}>
                Registro de Atividades
              </SubItem>
              <SubItem onClick={() => handleNavigate("/troca-de-senha")}>
                Troca de Senha
              </SubItem>
              <SubItem onClick={handleLogout}>
                <LogOut />
                Sair
              </SubItem>
            </Sub>
          </Item>
        </li>
      </Items>
    </Container>
  );
}