// @ts-nocheck
import { useEffect } from 'react';

import {
    Container,
    PageContent,
    Text,
    Title,
    ContactBoxes,
    Box
} from "./styles";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// import Header from "../../components/Header";
import Header from "../../components/Header";

import { useAuth } from '../../hooks/auth';

export function Contato() {
    const { user } = useAuth();

    useEffect(() => {
        window.Appcues.identify(user.id, user)
    }, [user]);

    return (
        <>
            <Container>
                <Header />
                <PageContent>
                    <Text>
                        <p>Está com alguma dúvida específica?</p>
                        <Title>Entre em contato com o Cidadania Já</Title>
                    </Text>
                    <ContactBoxes>
                        <Box>
                            <a href="https://api.whatsapp.com/send?phone=5521990257203&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Vim%20da%20Central%20do%20Cliente%20mas%20continuo%20com%20d%C3%BAvida!%20Poderia%20me%20ajudar%3F"
                            target="_blank"
                            referrer="no-referrer" rel="noreferrer">
                                <WhatsAppIcon />
                                <p>(21) 99025-7203</p>
                                <span>
                                    (WhatsApp)
                                </span>
                            </a>
                        </Box>
                        <Box>
                            <MailOutlineIcon />
                            <p>atendimento.servicos@cidadaniaja.com.br</p>
                        </Box>
                    </ContactBoxes>
                    <p>Sua dúvida pode estar na nossa página de perguntas frequentes</p>
                    <a className="perguntas" href="/perguntas-frequentes">
                        Ver perguntas frequentes
                        <KeyboardArrowRightIcon />
                    </a>
                </PageContent>
            </Container>
        </>
    );
}