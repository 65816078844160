import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    margin: 0
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function CheckboxLabels({ label, name, checked, onChange }) {
  // const [state, setState] = useState({
  //   checkedG: checked,
  // });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  return (
    <FormGroup row>
      <FormControlLabel
        control={<GreenCheckbox checked={checked} onChange={onChange} name={name} />}
        label={ label }
      />
    </FormGroup>
  );
}