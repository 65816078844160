import styled, { css } from "styled-components";
import { shade } from 'polished';

export const CardService = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;

    & + div {
        margin-top: 32px;
    }

    &.next {
        align-items: center;
        padding-top: 1.5rem;

        svg {
            color: var(--atencao-50);
            margin-bottom: 0.5rem;
        }

        p {
            &.service {
                margin-bottom: 0.25rem;
            }

            &.history {
                font-size: var(--font-17);
                color: var(--cinza-90);
                margin-bottom: 0.25rem;
                text-align: center;
            }

            /* &.date {
                font-size: var(--font-17);
                color: var(--principal-50);
                font-weight: 700;
            } */
        }
    }

    &.analysis {
        margin-bottom: 0;
        gap: 8px;

        > p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.2px;
            font-weight: 700;
            color: var(--cinza-100);
            margin-bottom: 0;
        }
    }

    &.dispatch {
        margin: 24px 0;
        gap: 8px;

        > p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.2px;
            font-weight: 700;
            color: var(--cinza-100);
            margin-bottom: 0;
        }
    }

    p {
        font-size: 1.1875rem;
        line-height: 1.75rem;
        color: var(--cinza-90);
        margin-bottom: 1.5rem;

        &.service {
            font-size: 0.9375rem;
            letter-spacing: 1.5px;
            font-weight: 700;
            color: var(--cinza-100);
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }
    }

    hr {
        width: 100%;
        height: 1px;
        background: var(--cinza-30);
        border: 0;
    }
`;

export const CardStats = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;

    > p {
        &:first-child {
            font-size: 0.9375rem;
            line-height: 1.75rem;
            letter-spacing: 1.5px;
            font-weight: 700;
            color: var(--cinza-100);
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }

        &.analysisText {
            font-size: 15px;
            line-height: 24px;
            color: var(--cinza-80);
            letter-spacing: 0.2px;
            margin-top: 8px;
        }

        &.followUp {
            font-size: 15;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: var(--cinza-70);
        }
    }

    > div.indicators {
        display: flex;
        justify-content: space-between;

        > p {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: var(--cinza-100);
        }
    }
`;

export const CardPercent = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.0625rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: ${({ color }) => color ? [color] : ''};

    &.m4 {
        margin: 4px 0;
    }

    span {
        &.bar {
            width: 100%;
            background: var(--cinza-30);
            border-radius: 0.5rem;
        }

        &.percent {
            margin: 0 0 0 0.5rem;
        }
    }
`;

export const LinePercent = styled.div`
    width: ${({ size }) => size ? [size]+"%" : 0};
    height: 0.75rem;
    background: ${({ color }) => color ? [color] : ''};
    border-radius: 0.5rem;
`;

export const Prediction = styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
    margin-top: 8px;
    margin-bottom: 24px;

    > svg {
        color: var(--principal-50);
    }

    > p {
        font-size: 15px;
        line-height: 24px;
        color: var(--cinza-90);
    }

    > div {
        display: flex;
        flex-direction: column;

        > p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: var(--cinza-90);
        }
    }
`;

export const Contact = styled.div`
    display: flex;
    gap: 8px;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 100%;
        background: var(--white);
        border: 1px solid var(--cinza-40);
        border-radius: 8px;
        padding: 16px 8px;

        > img {
            width: fit-content;
        }

        > p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-align: center;
            color: var(--cinza-90);
            margin: 0;
        }
    }
`;

export const StatsDispatch = styled.div`
    display: flex;
    flex-direction: column;

    & + div {
        margin-top: 24px;
    }

    > p {
        font-size: 17px;
        line-height: 28px;
        font-weight: 700;
        color: var(--cinza-100);

        &.analysisText {
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;
            color: var(--cinza-90);
            letter-spacing: 0.2px;
            margin: 4px 0;
        }
    }
`;

export const Delay = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ color }) => color ? [color] : ''};
    margin-top: 8px;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
            font-size: 17px;
            line-height: 28px;
            font-weight: 700;
        }
    }

    > p {
        font-size: 15px;
        line-height: 24px;
        color: var(--cinza-80);
        letter-spacing: 0.2px;
    }
`;
