import styled from "styled-components";

import Background from "../../assets/login/background.png";

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(${Background}) no-repeat right bottom, var(--gradiente-verde-claro);
  /* background: var(--gradiente-verde-claro); */

  img {
    &.desktop {
      margin-bottom: 5rem;
    }

    &.mobile {
      position: absolute;
      top: 50px;
      left: 16px;
    }
  }
  
  @media (max-width: 1280px) {
    background-size: cover;
  }

  @media screen and (min-width: 951px) {
    img.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 950px) {
    justify-content: center;
    background-position: top;
    background-size: contain;

    img.desktop {
      display: none;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  width: 40%;
  height: 100%;
  /* position: absolute; */
  /* max-width: 24.5rem; */
  /* box-shadow: var(--shadow-baixo-grande);
  border-radius: 0.5rem; */
  /* padding: 1.5rem 1rem 2rem; */

  p.description {
    display: flex;
    text-align: center;
    font-size: var(--font-17);
    line-height: 1.75rem;
    width: 100%;
    max-width: 25.75rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  form {
    width: 100%;
    max-width: 25.75rem;

    input::placeholder{
      font-size: 1.063rem;
    }

    span.resetPassword {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.25rem;
      font-size: var(--font-17);
      line-height: 1.5rem;
      color: var(--principal-50);
      margin-top: 1.5rem;

      a {
        text-decoration: none;
        color: var(--principal-50);
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 950px) {
    width: 95%;
    height: auto;
    position: absolute;
    margin: 0 auto;
    padding: 24px 16px 32px;
    border-radius: 8px;
    box-shadow: var(--shadow-baixo-grande);
    top: 180px;

    form {
      p.description {
        font-size: 17px;
        line-height: 28px;
        max-width: 296px;
      }

      span.resetPassword {
        font-size: 17px;
        line-height: 28px;
      }
    }

    button {
      font-size: 21px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 767px) {
    form {
      max-width: 100%;
    }
  }
`;