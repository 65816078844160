import styled, { css } from "styled-components";
import { TabHeader } from '../../styles/components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100%;
  height: auto;

  @media screen and (max-width: 950px) {
    .customTab {
      background: var(--white);
      box-shadow: var(--shadow-baixo-pequena);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  max-height: 25.75rem;
  padding: 3.5rem 0 1.5rem;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;

    img.imgBanner {
      max-width: 180px;
      margin: 44px 0 16px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  color: var(--cinza-100);

  @media screen and (max-width: 950px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  margin-bottom: 2.83rem;

  &.mobile {
    display: none;
  }

  h2 {
    margin-bottom: 1rem;
    color: var(--cinza-90);

    span {
      color: var(--principal-50);
    }
  }

  p {
    font-size: 1.1875rem;
    line-height: 1.75rem;
    color: var(--cinza-80);
  }

  @media screen and (max-width: 950px) {
    h2 {
      line-height: 28px;
      margin-bottom: 4px;
    }

    p {
      font-size: 17px;
      line-height: 28px;
    }

    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
`;

export const ContentRequests = styled.div`
  display: ${({ active }) => active ? "flex" : "none"};
  flex-direction: column;
  width: 100%;

  ${(props) => !props.border && css`
    .borderNone {
      border-bottom: 0;
    }
  `}

  &.no-accordion {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 24px;
    padding: 0 24px;

    ${TabHeader} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .MuiAccordionSummary-content {
      flex-direction: column;
      margin: 24px 0;
    }

    .MuiButtonBase-root {
      padding: 0 16px;
    }

    .MuiAccordionDetails-root {
      padding: 24px 8px 16px;
    }
  }
`;

export const CardRequests = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 52px;
`;

export const AccordionHeading = styled.div`
  display: flex;
  width: 100%;

  p {
    font-size: 1.1875rem;
    line-height: 1.75rem;
    font-weight: bold;
    color: var(--cinza-100);
    margin-right: 1rem;

    &.countPasso {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--principal-40);
        color: var(--white);
        margin-right: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
    }

    &.passo {
        display: flex;
        align-items: center;
        color: var(--principal-50);
        margin-right: 1.5rem; 
    }

    &.contador,
    &.contadorMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 1.5625rem;
        padding: 0 0.5625rem;
        background: var(--cinza-30);
        color: var(--cinza-80);
        border-radius: 6.25rem;
        margin: 0;
    }

    &.contadorMobile {
        display: none;
    }
  }
`;

export const AccordionDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1875rem;
    line-height: 1.75rem;
    font-weight: bold;
    width: 100%;
    max-width: 36rem;
    margin-right: 1.6875rem;

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    img {
        margin-right: 0.5rem;
    }

    p {
        font-size: 19px;
        line-height: 1.75rem;
        font-weight: bold;
        color: var(--cinza-100);
        margin-right: 1rem;

        &.countPasso {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--principal-40);
          color: var(--white);
          margin-right: 0.5rem;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
        }

        &.passo {
          display: flex;
          align-items: center;
          color: var(--principal-50);
          margin-right: 1.5rem; 
        }

        &.contador,
        &.contadorMobile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            max-height: 1.5625rem;
            padding: 0 0.5625rem;
            background: var(--cinza-30);
            color: var(--cinza-80);
            border-radius: 6.25rem;
            margin: 0;
        }

        &.contadorMobile {
            display: none;
        }
    }

    @media screen and (max-width: 1100px) {
    }

    @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        p {
            &.countPasso {
                display: none;
            }

            &.contador {
                display: none;
            }

            &.contadorMobile {
                display: flex;
            }

            &.title {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 767px) {
      p {
        font-size: 17px;

        &.contadorMobile {
          font-size: 15px;
        }
      }
    }
`;

export const AccordionStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ color }) => [color]};

    svg {
        margin-right: 1.125rem;
    }

    p {
        font-size: 1.1875rem;
        line-height: 1.75rem;
        /* color: var(--cinza-100); */
        margin-right: 1rem;
    }

    @media screen and (max-width: 950px) {
        margin-top: 4px;
    }
`;

export const AccordionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  position: relative;

  @media screen and (max-width: 950px) {
    width: 100%;

    button {
      margin-top: 24px;
    }

    span {
      position: absolute;
      right: 0;
      top: -50px;
    }
  }

  @media screen and (max-width: 767px) {
    position: unset;
  }
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.7s;
  transition-property: transform;
  margin-left: 1.5rem;
  background: var(--cinza-20);
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    width: 40px;
  }

  ${({ active }) => active
    ? `transform: rotate(180deg);
    -webkit-transform: rotate(180deg);`
    : ""
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 54px;
    right: 16px;
    transition: all 0.5s;

    ${({ active }) => active && css `
      top: 30px;
    `
  }
  }
`;

export const AccordionDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  width: 100%;
  font-size: 17px;
  line-height: 28px;
  color: var(--cinza-90);
  margin-bottom: 32px;

  > p {
    &.print {
      margin-top: 32px;
      margin-bottom: 16px;
    }

    span.activeModal {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > a {
    display: flex;
    gap: 8px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 700;
    max-width: 335px;
    background: var(--cinza-80);
    border-radius: 8px;
    padding: 12px 24px 12px 16px;
    text-decoration: none;
    cursor: pointer;
    color: var(--white);

    svg {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    color: var(--cinza-80);

    > p {
      &.print {
        margin-top: 24px;
      }
    }

    > a {
      font-size: 15px;
      line-height: 24px;
      /* max-width: 335px; */
      padding: 10px 21.5px 10px 13.5px;
    }
  }
`;

export const AccordionDetailBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  row-gap: 24px;
  column-gap: 52px;
  width: 100%;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 24px;
  }
`;
