import styled, { css } from "styled-components";
import { shade } from 'polished';
import  { TabContent, TabBody, Options, Option } from '../../styles/components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100vw;
  height: auto;
  
  /* ${TabContent} {
    background: var(--white);
  } */

  ${TabBody} {
    padding: 48px;

    &.hide {
      display: none;
    }

    > p {
      font-size: 19px;
      line-height: 32px;
      color: var(--cinza-80);
      margin-bottom: 48px;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* gap: 48px; */
      width: 100%;
    }
  }

  ${Options} {
    width: 100%;
  }

  ${Option} {
    width: 100%;
    max-width: 33.33%;

    svg {
      margin-right: 0.75rem;
    } 
  }

  h3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--cinza-90);
    width: 100%;
    max-width: 80rem;
    margin: 2rem auto 1rem;
  }

  @media screen and (max-width: 950px) {
    padding-bottom: 32px;

    ${TabBody} {
      padding: 16px;
    }

    .customTab {
      background: var(--white);
      box-shadow: var(--shadow-baixo-pequena);
    }

    .body {
      padding: 0 16px;
    }

    h3 {
      font-size: 21px;
      line-height: 28px;
      color: var(--principal-50);
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 767px) {
    ${TabContent} {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  height: 11.375rem;
  padding: 3.5rem 0 0;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    height: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  color: var(--cinza-100);

  @media screen and (max-width: 950px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 392px;

  > p {
    font-size: 15px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: var(--cinza-90);
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const AsideBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  border: 1px solid var(--cinza-40);
  border-radius: 16px;
  padding: 20px 24px;

  > div {
    display: flex;
    flex-direction: column;

    > p {
      font-size: 17px;
      line-height: 28px;
      font-weight: 700;
      color: var(--cinza-90);

      &.type {
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: var(--cinza-80);
        margin-top: 4px;
      }
    }
  }

  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
`;

export const Activities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 688px;

  > svg {
    &.icon-spin {
      animation: iconSpin 0.8s infinite linear;
    }

    @keyframes iconSpin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-359deg);
      }
    }
  }
`;

export const ActivitiesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & + div {
    margin-top: 32px;
  }

  > p {
    font-size: 15px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: var(--cinza-70);
    margin-bottom: 8px;
  }
`;

export const AsideMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  position: relative;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  background: transparent;
  color: var(--cinza-90);
  border: none;
  outline: none;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--cinza-40);
  border-radius: 8px;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  svg {
    font-size: 24px;
    color: var(--cinza-70);
  }

  ${({ green }) => 
  green &&
    css`
      border: none;
      background: var(--principal-40);
    `}

  @media screen and (min-width: 950px) {
    display: none !important;
  }
`;

export const Sub = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all 0.5s ease-in;
  background-color: #ffffff;
  width: 100%;
  max-height: 0;
  top: 68px;
  left: 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: var(--shadow-baixo-pequena);
  border-radius: 8px;
  border: 1px solid var(--white);

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px 16px;
  }
  
  ${({ open }) => 
    open && 
    css `
      max-height: 900px;
      border: 1px solid var(--cinza-40);
    `}

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -6px;
    left: 0;
  }
`;

export const SubItem = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  background: var(--white);
  color: var(--neutro-30);
  padding: 12px 16px;
  transition: 300ms;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--principal-20);

  img {
    margin-right: 8px;
  }

  &:last-child {
    margin-bottom: 0;
    border: none;
  }

  /* &:hover {
    color: #fdd51d;
  } */
`;
