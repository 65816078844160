// @ts-nocheck
import styled, { css } from "styled-components";

import Background from "../assets/header/fundo.svg";

export const PageHeader = styled.section`
  display: flex;
  justify-content: center;
  width: 100vw;
  background: url(${Background}) no-repeat left top;

  @media screen and (max-width: 950px) {
    background-size: contain;
  }
`;

export const TabContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: var(--cinza-20);
  box-shadow: var(--shadow-baixo-grande);
  border-radius: 0.5rem;
  width: 100%;
  margin: 0 auto;
  max-width: 80rem;

  @media screen and (max-width: 950px) {
    width: 92%;
  }
`;

export const TabBody = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  border-top: 1px solid var(--principal-40);
  border-radius: 0 0 0.5rem 0.5rem;
  padding-bottom: 1.5rem;

  &.no-border {
    border: none;
    padding: 0;
  }
`;

export const TabHeader = styled.div`
  display: flex;
  padding: 1.5rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
  }

  p {
    font-size: 1.1875rem;
    line-height: 1.75rem;
    color: var(--cinza-80);

    span {
      font-weight: 700;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 950px) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 8px;
    max-width: 100%;
    padding-top: 56px;
    padding-left: 8px;
    overflow-y: hidden;
  }
`;

export const OptionInfo = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  color: var(--cinza-70);
  width: 100%;
  top: 16px;

  @media only screen and (max-width: 950px) {
    display: flex;
  }
`;

export const Option = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.0625rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--cinza-60);
  padding: 16px 20px;
  background-color: transparent;
  transition: 400ms ease;
  position: relative;
  min-width: max-content;
  border: none;
  outline: none;

  svg {
    color: var(--cinza-60);
    margin-right: 0.875rem;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--principal-40);
    color: var(--white);
    margin-right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cinza-30);
    color: var(--cinza-60);
    margin-left: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
  }

  hr {
    display: flex;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: transparent;
    border-radius: 100px 100px 0 0;
    border: none;
    bottom: 0;
    transition: 400ms ease;

    ${(props) =>
      props.active &&
      css`
        background-color: var(--principal-40);
      `}
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--white);
      border-radius: 8px 8px 0 0;
      color: var(--cinza-90);

      svg {
        color: var(--principal-50);
      }
    `}

  @media only screen and (max-width: 1024px) {
    margin: 0 0 16px 0;
    width: 100%;

    &:after {
      display: none;
    }
  }

  @media screen and (min-width: 951px) {
    &:hover {
      background-color: var(--white);
      border-radius: 8px 8px 0 0;
      color: var(--cinza-90);

      svg {
        color: var(--principal-50);
      }

      hr {
        background-color: var(--principal-40);
      }
    }
  }

  @media only screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;
    padding: 12px;
    background-color: var(--cinza-20);
    width: 100%;
    max-width: 206px;
    border: 1px solid var(--principal-40);
    border-radius: 100px;
    outline: none;

    hr {
      display: none;
    }

    span {
      background: var(--principal-20);
      color: var(--principal-50);
      margin-left: 12px;
      width: 28px;
      height: 28px;
    }

    ${(props) =>
      props.active &&
      css`
        background-color: var(--principal-40);
        color: var(--cinza-90);

        svg {
          color: var(--cinza-100);
        }
      `}
  }
`;
