// @ts-nocheck
import React from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Container } from './styles';

export default function Button({
  btType,
  color,
  label,
  action,
  type,
  icon,
  padding,
  margin,
  full,
  width,
  btBorder,
  loading,
  disabled,
  ...rest
}) {
  return (
    <Container
      btType={btType}
      color={color}
      onClick={action}
      type={type || 'button'}
      icon={!!icon}
      full={full}
      width={width}
      margin={margin}
      padding={padding}
      className="defaultButton"
      disabled={disabled}
      {...rest}
    >
      {loading && <RotateLeftIcon className="icon-spin" />}
      {!loading && label}
      {icon && icon}
    </Container>
  );
}
