// @ts-nocheck
import styled from "styled-components";
import Background from "../../assets/contato/background.png";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  background: url(${Background}) no-repeat center top;
  background-size: contain;
  margin-top: 5.5rem;
  padding-bottom: 6.6875rem;
  width: 100vw;
  height: auto;

  @media screen and (max-width: 950px) {
    padding-bottom: 32px;
    background-size: auto;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-top: 4.9375rem ;
  margin: 0 auto;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: var(--font-17);
    line-height: 1.75rem;
    text-align: center;
    max-width: 37.5rem;
  }

  form {
    background: var(--white);
    box-shadow: var(--shadow-baixo-grande);
    border-radius: 8px;
    width: 100%;
    padding: 24px 16px;
  }

  @media screen and (max-width: 950px) {
    align-items: flex-start;
    padding: 44px 16px 0 16px;

    p {
      font-size: 17px;
      line-height: 28px;
      text-align: left;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  text-align: center;
  max-width: 590px;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 950px) {
    font-size: 40px;
    line-height: 48px;
    text-align: left;
    max-width: 288px;
  }
`;

export const ContactBoxes = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 3rem;

  svg {
    font-size: 2.5rem;
    color: var(--principal-40);
    margin-bottom: 0.5rem;
}

  p {
    font-size: var(--font-17);
    line-height: 1.75rem;
    text-align: center;
    color: var(--cinza-90);
    max-width: 10rem;
  }

  span {
    font-size: var(--font-15);
    line-height: 1.5rem;
    text-align: center;
    color: var(--cinza-60);
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 8px;

    p {
      font-size: 17px;
      line-height: 28px;
    }

    span {
      font-size: 15px;
      line-height: 24px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--cinza-40);

  p {
    word-break: break-all;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-decoration: none;
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;

  .customContainerInput,
  .customContainerSelect {
    margin-bottom: 0;
  }

  & + div {
    margin-top: 1rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "var(--principal-40)"};
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  color: var(--white);
  padding: 0.75rem 0;
  margin: 1.5rem auto 0;
  width: 100%;
  max-width: 200px;
  border-radius: 0.5rem;
  border: ${({ bgColor }) => bgColor ? "1px solid var(--neutro-30)" : "none"};
  outline: none;

  .icon-spin {
    animation: iconSpin 0.8s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 28px;
    max-width: 100%;
    padding: 14px 0;
  }
`;
