// @ts-nocheck
import styled, { css } from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? "0" : "100%")};
  width: 100%;
  height: 200%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 80px;
  background-color: var(--white);
  z-index: 100;
  transition: all 300ms ease;
`;

export const Items = styled.div`
  width: 100%;

  li {
    display: flex;
    width: 100%;
    position: relative;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  color: ${({ white }) => (white ? "var(--white)" : "var(--cinza-100)")};
  width: 100%;
  padding: 16px 8px 16px 16px;
  border-bottom: 1px solid var(--principal-20);
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  svg {
    font-size: 24px;
    color: var(--principal-50);
  }

  ${({ green }) => 
  green &&
    css`
      border: none;
      background: var(--principal-40);
    `}

  &:hover {
    background-color: #0002;
  }

  &.contactButton {
    border: 0;
    border-radius: 0;
    min-width: 120px;
    padding: 16px 8px 16px 16px;

    &:hover {
      &:after {
        display: none;
      }
    }
  }

  &.accordionButton {
    justify-content: center;
    transition: all 0.5s ease-in;
    background: var(--principal-40);
    border: none;
    outline: none;
    border-radius: ${({ green }) => green ? "0.5rem 0.5rem 0 0" : "0.5rem"};
    padding: 12px;
    margin-top: 38px;

    svg {
      font-size: 24px;
      margin-left: 8px;
      transition: all 0.5s ease-in;
      color: var(--cinza-100);

      ${({ green }) => green
        ? `transform: rotate(180deg);
        -webkit-transform: rotate(180deg);`
        : ""
      }
    }

    &:hover {
      &:after {
        display: none;
      }
    }
  }

  &:before {
    display: ${({ sub }) => (sub ? "block" : "none")};
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent #af9337;
    position: absolute;
    right: 20px;
  }
`;

export const SubTitle = styled.h5`
  font-size: 14px;
  color: #fdd51d;
  margin-bottom: 8px;
  margin-top: ${({ last }) => (last ? "18px" : 0)};
  text-transform: uppercase;
  cursor: default;
`;

export const BackButton = styled.button`
  padding: 0 50px 20px 0;
  margin-bottom: 10px;
`;

export const Scroll = styled(PerfectScrollbar).attrs({
  options: {
    wheelSpeed: 0.3,
  },
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: calc(100vh - 200px);
  width: 100%;
  overflow: hidden;
  position: relative;
  .ps__rail-y {
    position: absolute;
    width: 5px;
    z-index: 1005;
    border-radius: 15px;
  }
`;

export const Sub = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  transition: all 0.5s ease-in;
  width: 100%;
  max-height: 0;
  top: 52px;
  left: 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: var(--shadow-baixo-pequena);

  ${({ open }) => 
    open && 
    css `
      max-height: 18rem;
      border-radius: 0 0 8px 8px;
    `}

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -6px;
    left: 0;
  }
`;

export const SubItem = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  background: var(--white);
  color: var(--neutro-30);
  padding: 12px 16px;
  transition: 300ms;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--principal-20);

  img {
    margin-right: 8px;
  }

  &:last-child {
    margin-bottom: 0;
    border: none;
  }

  /* &:hover {
    color: #fdd51d;
  } */
`;
