import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;

  span {
    font-size: 14px;
    font-weight: 700;
    color: #c53030;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  line-height: 24px;
  color: ${({ disabled }) => disabled ? "var(--cinza-50)" : "var(--cinza-90)"};
  letter-spacing: 0.2px;
  margin-bottom: 4px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 14px 12px 16px;
  position: relative;
  background: var(--white);
  border: 1px solid var(--principal-50);
  box-sizing: border-box;
  border-radius: 8px;

  /* span {
    width: 3rem;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    left: 0;
    top: 0;
  } */

  button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    outline: none;
  }

  input {
    flex: 1;
    background: transparent;
    font-size: 17px;
    line-height: 28px;
    width: 100%;
    border: none;
    outline: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset;
      -webkit-text-fill-color: var(--cinza-90);
    }
  }

  input.MuiInputBase-input {
    padding: 1px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 950px) {
    input,
    input::placeholder {
      font-size: 17px;
      line-height: 28px;
    }

    span,
    span.resetPassword {
      font-size: 17px;
      line-height: 28px;
    }
  }
`;

export const TooltipInfo = styled.div`
  transition: all 0.5s ease-in-out;
  opacity: ${({ active }) => active ? 1 : 0};
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  width: 150px;
  background: var(--cinza-100);
  color: var(--white);
  border-radius: 8px;
  padding: 1rem;
  position: absolute;
  top: 110%;
  right: 0;
  ${({ active }) => active ? 'z-index: 10;' : 'z-index: -1;'}

  p {
    font-size: 14px;
    color: var(--white);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 8%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent var(--cinza-100) transparent;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;

    ${({ active }) =>
      active ? 
      css `
        display: flex;
        z-index: 10;
        top: 160%;
        right: 4px;

        &:after {
          left: 87%;
        }
      ` :
      css `
        display: none;
      `
    }
  }
`;
