import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --stroke-hover: #2E5D38;
        --white: #FFFFFF;

        --erro-50: #DB0A3C;
        --erro-10: #FEE2E8;

        --principal-50: #1F9833;
        --principal-40: #32C050;
        --principal-30: #67D67E;
        --principal-20: #C6FFD2;
        --principal-10: #E5FFEB;

        --cinza-100: #14142A;
        --cinza-90: #2B2D41;
        --cinza-80: #42455F;
        --cinza-70: #64688B;
        --cinza-60: #7A7FA7;
        --cinza-50: #ABAEC9;
        --cinza-40: #C7CADD;
        --cinza-30: #DEE0ED;
        --cinza-20: #F2F4F7;
        --cinza-10: #F9F8F9;

        --secundaria-50: #DB0A3C;
        --secundaria-40: #F52D5D;
        --secundaria-30: #F7597F;
        --secundaria-20: #FA8FA9;
        --secundaria-10: #FEE2E8;
        --secundaria-dark: #A70028;

        --neutro-50: #253528;
        --neutro-40: #2E5D38;
        --neutro-30: #388443;
        --neutro-20: #62A56C;
        --neutro-10: #C4ECCD;

        --atencao-50: #EE9E00;
        --atencao-40: #FFBF40;
        --atencao-30: #FFD582;
        --atencao-20: #FFE7B7;
        --atencao-10: #FFF8E1;

        --gradiente-verde-claro: linear-gradient(55.73deg, #32C050 19.37%, #67D67E 77.71%);
        --gradiente-cinza: linear-gradient(to right, #14142A, #64688B);
        --gradiente-verde-esxuro: linear-gradient(to right, #1F9833, #32C050);

        --shadow-baixo-grande: 0px 0px 1px rgba(20, 20, 42, 0.06), 0px 20px 25px -5px rgba(20, 20, 42, 0.1), 0px 10px 10px -5px rgba(20, 20, 42, 0.04);
        --shadow-baixo-pequena: 0px 4px 6px -1px rgba(20, 20, 42, 0.1), 0px 2px 4px -1px rgba(20, 20, 42, 0.06);

        --font-15: 0.9375rem;
        --font-17: 1.0625rem;
        --font-19: 1.1875rem;
        --font-21: 1.3125rem;
        
        --primaria: #EAFFEF;

        overflow-x: hidden;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;

            body, input, textarea, button {
                font-size: 100%;
            }
        }

        @media (max-width: 720px) {
            font-size: 87.5%;

            body, input, textarea, button {
                font-size: 100%;
            }
        }
    }

    body {
        background: var(--cinza-10);
        --webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: "Lato", sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;