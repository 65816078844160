// @ts-nocheck
import { useState, useCallback } from 'react';

import Copy from '../../../assets/icons/copy.svg';

import { Container, Label, Content, TooltipInfo } from "./styles";

export const Input = ({ name, ...props }) => {
  const [info, setInfo] = useState(false);

  const handleCopy = useCallback((inputID) => {
    /* Get the text field */
    var copyText = document.getElementById(inputID);
  
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
  
    /* Copy the text inside the text field */
    document.execCommand("copy");

    setInfo(true);
    setTimeout(() => {
      setInfo(false);
    }, 1000);
  }, []);

  return (
      <Container>
          {props.label &&
            <Label disabled={props.disabled} htmlFor={props.name}>{props.label}</Label>
          }
          <Content>
              <input {...props} />
              <button onClick={() => handleCopy(props.id)}>
                <img src={Copy} alt="Copy" />
              </button>
              <TooltipInfo active={info}>
                Texto copiado!
              </TooltipInfo>
          </Content>
      </Container>
  );
}
