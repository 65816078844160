// @ts-nocheck
import { useState, useRef, useEffect, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from 'yup';

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxHeader,
    BoxContent,
    BoxImage,
    CautionBox,
    Wrap,
    CardButtons,
    Button
} from "./styles";

import { Input } from "../../../components/Form";

import WarningIcon from '@material-ui/icons/Warning';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';

import convidandoUsuarios from "../../../assets/boas-vindas/convidando-usuarios.png";
import CheckGrande from "../../../assets/boas-vindas/check-grande.png";

import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

export function ModalAddRelative({ openModal, closeModal }) {
    const formRef = useRef(null);
    const [active, setActive] = useState(1);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        birthday: ''
    });

    const { addToast } = useToast();
    
    function handleChange(e) {
        const { name, value } = e.target;
        setValues(state => ({ ...state, [name]: value }));
    }

    function handleCloseModal() {
        setOpen(false);
        setTimeout(() => {
            setVisible(false);
        }, 600);
    }

    useEffect(() => {
        if(openModal) {
            setVisible(true);
            setTimeout(() => {
                setOpen(true);
            }, 600);
        }
    }, [openModal]);

    const handleSubmit = useCallback(async (data, { reset }) => {
        try {
            setLoading(true);

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
                name: Yup.string().required('Nome obrigatório'),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.post('central/processes/shared-access', data);

            setActive(2);
            reset();
        } catch(err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não foi possível adicionar familiar!'
            });
        } finally {
            setLoading(false);
        }
    }, [addToast]);

    return (
        <PageContainer open={open} visible={visible}>
            <WhiteBox>
                <CloseButton onClick={ () => { handleCloseModal(); closeModal() }}>
                    Fechar
                    <CancelIcon />
                </CloseButton>
                <BoxHeader />
                <BoxContent active={ active === 1 }>
                    <BoxImage>
                        <img src={convidandoUsuarios} alt="Convidando Usuários"/>
                    </BoxImage>
                    <h4>Convidando familiares</h4>
                    <p className="body">Nossa primeira pergunta é: <span>Tem mais alguém que você gostaria que tivesse acesso ao 
                    acompanhamento do procedimento?</span> Se sim, adicione os seus contatos que eles receberão login e senha para 
                    entrar por aqui. Mas não se preocupe, você pode definir qual tipo de permissão eles poderão ter.</p>
                    <CautionBox>
                        <span>
                            <WarningIcon />
                            <p className="caution">Atenção</p>
                        </span>
                        <p className="caution-body">Você também poderá alterar e adicionar as pessoas a qualquer momento nas 
                        configurações da sua conta!</p>
                    </CautionBox>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Wrap>
                            <Input
                                type="text"
                                label="Nome do convidado"
                                name="name"
                                placeholder="Nome"
                                onChange={handleChange}
                            />
                            <Input
                                type="email"
                                label="E-mail do convidado"
                                name="email"
                                placeholder="seuemail@email.com"
                                onChange={handleChange}
                            />
                        </Wrap>
                        <CardButtons>
                            <Button type="button" onClick={() => { handleCloseModal(); closeModal() }}>Fechar</Button>
                            <Button type="submit" bgColor="var(--principal-40)">
                                {loading ? (
                                    <RotateLeftOutlinedIcon className="icon-spin" />
                                ) : (
                                    <>
                                        Enviar
                                        <ChevronRightIcon />
                                    </>
                                )}
                            </Button>
                        </CardButtons>
                    </Form>
                </BoxContent>
                <BoxContent active={ active === 2 }>
                    <BoxImage>
                        <img src={CheckGrande} alt="Check"/>
                    </BoxImage>
                    <h4>Convite enviado!</h4>
                    <p className="body">Seu convite foi enviado com sucesso! O familiar que você convidou receberá um 
                    acesso para acompanhar o procedimento!</p>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(1)}>Convidar mais</Button>
                        <Button bgColor="var(--principal-40)" onClick={() => { handleCloseModal(); closeModal() }}>
                            Concluir
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent>
            </WhiteBox>
        </PageContainer>
    );
}
