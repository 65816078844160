import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 9.3125rem;
  width: 100vw;
  height: auto;

  .options {
    width: 100%;
  }

  .option {
    width: 100%;
    max-width: 33.33%;

    svg {
      margin-right: 0.75rem;
    } 
  }

  .body {
    align-items: center;
  }

  form {
    width: 100%;
    max-width: 37.5rem;
    padding: 3rem 0;

    h4 {
      display: flex;
      flex-direction: flex-start;
      font-size: 21px;
      line-height: 28px;
      font-weight: 700;
      color: var(--principal-50);
      width: 100%;
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 950px) {
    .customTab {
      background: var(--white);
      box-shadow: var(--shadow-baixo-pequena);
    }

    .body {
      padding: 0 16px;
    }

    form {
      h4 {
        font-size: 21px;
        line-height: 28px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  height: 11.375rem;
  padding: 3.5rem 0 0;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 20px 16px 32px;
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    height: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;

  @media screen and (max-width: 950px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const FormContent = styled.div`
  display: ${({ active }) => active ? "flex" : "none"};
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  hr.line {
    width: 100%;
    height: 1px;
    background: var(--cinza-50);
    margin: 48px 0;
  }

  div.relatives {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      & + div {
        margin-top: 24px;
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;

      p {
        font-size: 19px;
        line-height: 32px;
        font-weight: 700;
        color: var(--cinza-100);
      }
    }

    .infos {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--cinza-50);

      .email {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.2px;
          font-weight: 700;
          color: var(--cinza-90);
          margin-bottom: 16px;
        }

        span {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 17px;
          line-height: 28px;
          color: var(--cinza-90);

          svg {
            color: var(--cinza-70);
          }
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: transparent;
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
        letter-spacing: 0.2px;
        color: var(--erro-50);
        padding: 10px 16px 10px 8px;
        width: 100%;
        max-width: 170px;
        /* height: 44px; */
        border-radius: 8px;
        border: 1px solid var(--erro-50);
        outline: none;

        .icon-spin {
          animation: iconSpin 0.8s infinite linear;
        }

        @keyframes iconSpin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-359deg);
          }
        }
      }
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  width: 100%;

  &.first {
    padding-bottom: 1.5rem;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  background: transparent;
  width: 100%;
  margin-top: 24px;

  &.marginButton {
    margin-top: 2rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "var(--principal-40)"};
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  color: ${({ bgColor }) => bgColor ? "var(--neutro-30)" : "var(--cinza-100)"};
  padding: 0.75rem 0;
  margin-top: 1.5rem;
  width: 100%;
  max-width: 200px;
  border-radius: 0.5rem;
  border: ${({ bgColor }) => bgColor ? "1px solid var(--neutro-30)" : "none"};
  outline: none;

  .icon-spin {
    animation: iconSpin 0.8s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 28px;
    max-width: 100%;
    padding: 14px 0;
  }
`;
