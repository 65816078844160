// @ts-nocheck
import { useRef, useState, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import { Container, Content, WhiteBox, CloseButton } from "./styles";

import LockOpenIcon from "@material-ui/icons/LockOpen";

import { Input } from "../../components/Form";
import Button from "../../components/Button";

import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo-white.png";

export function ChangePassword() {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [revealPassword, setRevealPassword] = useState(false);
  const [revealNewPassword, setRevealNewPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);

  const { signOut } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().required("Senha antiga obrigatória"),
          new_password: Yup.string().required("Nova senha obrigatória"),
          confirm_password: Yup.string().oneOf(
            [Yup.ref("new_password"), null],
            "Senhas não coincidem"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.patch("central/profile/reset-password", {
          current_password: data?.old_password,
          new_password: data?.new_password,
          new_password_confirmation: data?.confirm_password,
        });

        addToast({
          type: "success",
          title: "Sucesso",
          description: "Sua senha foi atualizada com sucesso!",
        });

        setTimeout(() => {
          signOut();
        }, 2000);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        addToast({
          type: "error",
          title: "Erro",
          description: "Não possível atualizar sua senha!",
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast]
  );

  const handleKeepPassword = useCallback(async () => {
    try {
      await api.patch("central/profile/updated-password");

      addToast({
        type: "success",
        title: "Sucesso",
        description: "Sua senha será mantida!",
      });

      setTimeout(() => {
        signOut();
      }, 2000);
    } catch (err) {
      console.log(err);
      addToast({
        type: "error",
        title: "Erro",
        description: "Não possível confirmar manter senha!",
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, signOut]);

  return (
    <Container>
      <img
        className="mobile"
        src={logoWhite}
        alt="Cidadania Já"
        style={{ maxWidth: "184px" }}
      />
      <Content>
        <img
          className="desktop"
          src={logo}
          alt="Cidadania Já"
          style={{ maxWidth: "184px" }}
        />
        <h3>Altere aqui sua senha</h3>
        <p className="description">
          Recomendamos que altere a sua senha de acesso à Central do Cliente
          para que seus dados fiquem ainda mais protegidos. E após realizar a
          alteração, lembre-se de anotá-la em algum lugar para não esquecer!
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            type={revealPassword ? "text" : "password"}
            label="Senha atual"
            icon={<LockOpenIcon />}
            name="old_password"
            placeholder="Insira sua senha atual aqui"
            iconPassword
            changePassword={() => setRevealPassword(!revealPassword)}
            visible={revealPassword}
          />
          <Input
            type={revealNewPassword ? "text" : "password"}
            label="Criar nova senha"
            icon={<LockOpenIcon />}
            name="new_password"
            placeholder="Insira nova senha aqui"
            iconPassword
            changePassword={() => setRevealNewPassword(!revealNewPassword)}
            visible={revealNewPassword}
          />
          <Input
            type={revealConfirmPassword ? "text" : "password"}
            label="Confirmar nova senha"
            icon={<LockOpenIcon />}
            name="confirm_password"
            placeholder="Confirmar nova senha"
            iconPassword
            changePassword={() =>
              setRevealConfirmPassword(!revealConfirmPassword)
            }
            visible={revealConfirmPassword}
          />
          <Button
            type="submit"
            btType="var(--principal-40)"
            color="var(--cinza-100)"
            label="Alterar senha"
            margin="1.5rem 0 0 0"
            full
            padding="1rem 0"
            loading={loading}
            disabled={loading}
          />
          <span className="resetPassword" onClick={() => handleKeepPassword()}>
            <p>Quero manter minha senha atual</p>
          </span>
        </Form>
      </Content>
    </Container>
  );
}
