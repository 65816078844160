// @ts-nocheck
import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const PageContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  top: 0;
  transition: all 0.5s ease-in-out;
  opacity: ${({ open }) => open ? 1 : 0};

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--white);
  width: 100%;
  max-width: 600px;
  max-height: 95%;
  padding: 1rem 1.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-baixo-grande);

  @media screen and (max-width: 950px) {
    width: 95%;
  }

  @media screen and (max-width: 767px) {
    /* height: 95vh; */
    top: 16px;
  }
`;

export const BoxContent = styled.div`
  transition: all 0.5s ease-in-out;
  /* display: flex; */
  display: ${({ active }) => active ? "block" : "none"};
  position: relative;
  width: 100%;
  opacity: ${({ active }) => active ? "1" : "0"};
  overflow: auto;
  overflow-x: hidden;

  /* &::-webkit-scrollbar {
    width: 0;
  } */

  h4 {
    font-size: var(--font-21);
    line-height: 1.75rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
    color: var(--cinza-100);
    margin-bottom: 16px;
  }

  p {
    &.description {
      font-size: var(--font-17);
      line-height: 1.75rem;
      font-weight: 700;
      color: var(--principal-50);
      text-align: center;
    }

    &.body {
      font-size: var(--font-17);
      line-height: 1.75rem;
      color: var(--cinza-90);
      text-align: left;

      hr {
        border: 0;
        height: 8px;
      }

      span {
        color: var(--principal-50);
        font-weight: 700;
      }
    }

    &.documents {
      margin-top: 56px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-top: 24px;
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.body,
      &.description {
        font-size: 17px;
        line-height: 28px;
        text-align: left;
      }
    }
  }
`;

export const BoxImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  .slick-list,
  .slick-slider {
    width: 100%;
  }

  ul.slick-dots {
    display: flex !important;
    width: 100%;
    height: 4px;
    top: 0;

    li {
      width: 100%;
      height: 4px;
      margin: 0;
      background: var(--cinza-30);

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }

      button {
        width: 100%;
        height: 4px;
        padding: 0;

        &:before {
          width: 100%;
          height: 4px;
          font-size: 0;
          line-height: 0;
        }
      }

      &.slick-active {
        background: var(--principal-50);
      }
    }
  }

  @media only screen and (max-width: 650px) {

    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
      min-height: 300px;
    }

    .video-react-controls-enabled {
      padding-top: 50%;
    }
  }

  &.video {
    height: 17.5rem;
    background: var(--cinza-20);

    img {
      position: absolute;
      cursor: pointer;
    }
  }
`;

export const CloseButton = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--principal-50);
  top: 1rem;
  right: 1.5rem;
  z-index: 10;
  cursor: pointer;

  svg {
    color: var(--principal-40);
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  position: relative;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
`;

export const Dot = styled.div`
  transition: all 0.5s ease-in;
  width: ${({ active }) => active ? "1.5rem" : "0.75rem"};
  height: 0.75rem;
  background: ${({ active }) => active ? "var(--gradiente-verde-claro)" : "transparent"};
  border-radius: 0.5rem;
  border: ${({ active }) => active ? "none" : "1px solid var(--principal-50)"};
`;

export const CautionBox = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--atencao-10);
  width: 100%;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 0.25rem;
  border-left: 4px solid var(--atencao-30);

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    svg {
      width: 1.5rem;
      color: var(--atencao-50);
      margin-right: 0.5rem;
    }
  }

  p {
    font-size: var(--font-15);
    line-height: 1.5rem;

    &.caution {
      font-weight: 700;
      color: var(--cinza-100);
    }

    &.caution-body {
      color: var(--cinza-90);
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
    }

    p {
      &.caution,
      &.caution-body {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;

export const AddPerson = styled.div`
  display: flex;
  align-items: center;;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--principal-50);
  width: 100%;
  margin-bottom: 2rem;
  cursor: pointer;

  img {
    margin-right: 0.5rem;
  }
`;

export const Wrap = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  width: 100%;

  &.first {
    padding-bottom: 1.5rem;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  background: transparent;
  width: 100%;
  padding-bottom: 1px;

  &.marginButton {
    margin-top: 2rem;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--principal-40);
    border-radius: 0.5rem;
    font-size: var(--font-17);
    line-height: 1.5rem;
    font-weight: 700;
    padding: 0.75rem 0;
    border: 0;
    color: var(--cinza-100);
    width: 100%;
    text-decoration: none;
    transition: background 0.2s;

    &.midButton {
      border: none;
    }

    &.isDisabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;
    }

    &:hover {
      background: ${shade(0.1, "#32C050")};
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: var(--font-17);
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  outline: none;

  &.midButton {
    border: none;
  }

  ${(props) => props.disabled && css`
    background: var(--cinza-40);
    color: var(--white);
  `}
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 337px;
  background: var(--cinza-20);
  outline: none;

  > p {
    font-size: 15px;
    line-height: 24px;
    color: var(--cinza-70);
    text-align: center;
    margin-top: 16px;
  }

  > img {
    width: 100%;
    max-height: 240px;
    object-fit: cover;
    object-position: top;
    max-width: 414px;
    margin: 32px auto 0;
  }

  @media screen and (max-width: 1250px) {
    width: 1100px;
    height: calc(100vh - 265px);
  }

  @media screen and (max-width: 1100px) {
    width: 100vw;
  }

  @media screen and (max-width: 950px) {
    height: calc(100vh - 87px);
  }

  @media only screen and (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 400px) {
    height: calc(100vh - 72px);
  }
`;

export const ArrowSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(55.73deg, #32C050 19.37%, #67D67E 77.71%);
  color: var(--white);
  border-radius: 8px;
  z-index: 90;
  width: 40px;
  height: 40px;
  ${({ right }) => right ? 'right: 10px;' : "left: 10px;"}

  > svg {
    position: relative;
    top: 8px;
    left: 8px;
    color: #fff;

    &.left {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: ${shade(0.1, "#32C050")};
  }

  &::before {
    content: '';
  }
`;

export const NotaryOffices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > h5 {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 1.5px;
    color: var(--principal-50);
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`;
