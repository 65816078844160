// @ts-nocheck
import { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import { Container, Label, Content } from "./styles"

export const Textarea = ({ name, ...props }) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container className="customContainerTextarea">
            {props.label &&
                <Label disabled={props.disabled} htmlFor={props.name}>{props.label}</Label>
            }
            <Content icon={props.icon} className="customTextarea" isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
                {props.icon &&
                    props.icon
                }
                <textarea
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...props}
                />
            </Content>
            {error && <span className="error">{error}</span>}
        </Container>
    );
}