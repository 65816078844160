// @ts-nocheck
import { useState, useRef, useEffect, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { format, parseISO } from "date-fns";

import { Player, BigPlayButton } from "video-react";

// import * as Yup from 'yup';

import {
  PageContainer,
  WhiteBox,
  CloseButton,
  BoxHeader,
  Dot,
  BoxContent,
  BoxImage,
  CautionBox,
  AddPerson,
  Wrap,
  CardButtons,
  Button,
  Slide,
  ArrowSlider,
} from "./styles";

import { Input, Select } from "../../../components/Form";

import WarningIcon from "@material-ui/icons/Warning";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";

import convidandoUsuarios from "../../../assets/boas-vindas/convidando-usuarios.png";
import PersonAdd from "../../../assets/boas-vindas/personAdd.svg";
import CheckGrande from "../../../assets/boas-vindas/check-grande.png";
import Certidao from "../../../assets/boas-vindas/certidao.jpg";
import Certidao2 from "../../../assets/boas-vindas/exemplo-documentacao.png";
import Requerimento from "../../../assets/boas-vindas/requerimento.jpg";
import Requerimento2 from "../../../assets/boas-vindas/exemplo-documentacao2.png";
import Notificacoes from "../../../assets/boas-vindas/notificacoes.png";
import Documentos from "../../../assets/boas-vindas/documentos.png";
import Registro from "../../../assets/boas-vindas/registro.png";
import EnviarDepois from "../../../assets/boas-vindas/enviar-depois.png";
import Confirmacao from "../../../assets/boas-vindas/confirmacao.png";

import "video-react/dist/video-react.css";

import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

export function ModalWelcome({ data, openModal, closeModal }) {
  const formRef = useRef(null);
  const [active, setActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    birthday: "",
  });

  const { addToast } = useToast();
  const vidRef = useRef(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleCloseModal(e) {
    // (e).preventDefault();
    setOpen(false);
    setTimeout(() => {
      setVisible(false);
      vidRef.current.video.pause();
    }, 600);
    closeModal();
  }

  useEffect(() => {
    const modalWellcome = localStorage.getItem("@Central:wellcome");

    if (!modalWellcome) {
      setOpen(true);
      setTimeout(() => {
        setVisible(true);
      }, 600);
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      setOpen(true);
      setTimeout(() => {
        setVisible(true);
      }, 400);
    }
  }, [openModal]);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
          name: Yup.string().required("Nome obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post("central/processes/shared-access", data);

        setActive(3);
        reset();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        addToast({
          type: "error",
          title: "Erro",
          description: "Não foi possível adicionar familiar!",
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast]
  );

  const settings = {
    dots: true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <ArrowSlider right>
        <ArrowForwardIosIcon />
      </ArrowSlider>
    ),
    prevArrow: (
      <ArrowSlider>
        <ArrowForwardIosIcon className="left" />
      </ArrowSlider>
    ),
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <ArrowSlider right>
        <ArrowForwardIosIcon />
      </ArrowSlider>
    ),
    prevArrow: (
      <ArrowSlider>
        <ArrowForwardIosIcon className="left" />
      </ArrowSlider>
    ),
  };

  return (
    <PageContainer open={open} visible={visible}>
      <WhiteBox>
        <CloseButton onClick={() => handleCloseModal()}>
          Fechar
          <CancelIcon />
        </CloseButton>
        <BoxHeader>
          <Dot active={active === 1} />
          <Dot active={active === 2} />
          <Dot active={active === 3} />
          <Dot active={active === 4} />
          <Dot active={active === 5} />
          <Dot active={active === 6} />
          {/* <Dot active={ active === 7 } />
                    <Dot active={ active === 8 } />
                    <Dot active={ active === 9 } />
                    <Dot active={ active === 10 } /> */}
        </BoxHeader>
        <BoxContent active={active === 1}>
          <BoxImage className="video">
            <Player
              ref={vidRef}
              playsInline
              src="https://storage.googleapis.com/cidadaniaja.appspot.com/medias/video-areadocliente_v4.mp4"
              width="100%"
            >
              <BigPlayButton position="center" />
            </Player>
          </BoxImage>
          <h4>Bem vindo, {data?.name}!</h4>
          <p className="description">
            Essa é a sua orientação pré documentação.
          </p>
          <p className="body">
            Primeiro vamos entender um pouco sobre como será a sua assessoria.
            <br />
            <span
              style={{
                display: "flex",
                fontWeight: "400",
                color: "var(--cinza-90)",
                marginTop: "8px",
                marginBottom: "24px",
              }}
            >
              Assista o vídeo para obter informações sobre seus documentos, o
              passo a passo do seu procedimento e como entregar toda sua
              documentação para a gente!
            </span>
          </p>
          <CautionBox>
            <span>
              <WarningIcon />
              <p className="caution">Atenção</p>
            </span>
            <p className="caution-body">
              Nas próximas telas, vamos explicar como funciona a etapa de
              documentação mas você verá apenas exemplos de documentos. Depois,
              você terá acesso aos documentos e informações necessárias para
              cada um de seus pedidos.
            </p>
          </CautionBox>
          <CardButtons>
            <Button onClick={() => handleCloseModal()}>
              Já conheço o procedimento
            </Button>
            <Button
              bgColor="var(--principal-40)"
              onClick={() => {
                setActive(2);
                vidRef.current.video.pause();
              }}
            >
              Começar tutorial
              <ChevronRightIcon />
            </Button>
          </CardButtons>
        </BoxContent>
        <BoxContent active={active === 2}>
          <BoxImage>
            <img src={convidandoUsuarios} alt="Convidando Usuários" />
          </BoxImage>
          <h4>Convidando familiares</h4>
          <p className="body">
            Nossa primeira pergunta é:{" "}
            <span>
              Tem mais alguém que você gostaria que tivesse acesso ao
              acompanhamento do procedimento?
            </span>{" "}
            Se sim, adicione os seus contatos que eles receberão login e senha
            para entrar por aqui. Mas não se preocupe, você pode definir qual
            tipo de permissão eles poderão ter.
          </p>
          <CautionBox>
            <span>
              <WarningIcon />
              <p className="caution">Atenção</p>
            </span>
            <p className="caution-body">
              Você também poderá alterar e adicionar as pessoas a qualquer
              momento nas configurações da sua conta!
            </p>
          </CautionBox>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Wrap>
              <Input
                type="text"
                label="Nome do convidado"
                name="name"
                placeholder="Nome"
                onChange={handleChange}
              />
              <Input
                type="email"
                label="E-mail do convidado"
                name="email"
                placeholder="seuemail@email.com"
                onChange={handleChange}
              />
            </Wrap>
            <CardButtons>
              <Button type="button" onClick={() => setActive(1)}>
                Voltar
              </Button>
              <Button
                type="button"
                className="midButton"
                onClick={() => setActive(4)}
              >
                Convidar depois
              </Button>
              <Button
                type="submit"
                bgColor="var(--principal-40)"
                disabled={loading}
              >
                {loading ? (
                  <RotateLeftOutlinedIcon className="icon-spin" />
                ) : (
                  <>
                    Convidar
                    <ChevronRightIcon />
                  </>
                )}
              </Button>
            </CardButtons>
          </Form>
        </BoxContent>
        <BoxContent active={active === 3}>
          <BoxImage>
            <img src={CheckGrande} alt="Check" />
          </BoxImage>
          <h4>Convite enviado!</h4>
          <p className="body">
            Seu convite foi enviado com sucesso! O familiar que você convidou
            receberá um acesso para acompanhar o procedimento!
          </p>
          <CardButtons className="marginButton">
            <Button onClick={() => setActive(2)}>Convidar mais</Button>
            <Button bgColor="var(--principal-40)" onClick={() => setActive(4)}>
              Próximo
              <ChevronRightIcon />
            </Button>
          </CardButtons>
        </BoxContent>
        <BoxContent active={active === 4}>
          <BoxImage>
            <Slider {...settings}>
              <Slide>
                <img src={Certidao} alt="Documentação" />
                <p>
                  Exemplo de certidão de nascimento inteiro teor por cópia
                  reprográfica
                </p>
              </Slide>
              <Slide>
                <img src={Certidao2} alt="Documentação" />
                <p>Exemplo de certidão de nascimento inteiro teor digitada</p>
              </Slide>
            </Slider>
          </BoxImage>
          <h4>Documentação em cartório</h4>
          <p className="body">
            Para darmos início, em cada pedido é necessária a solicitação da
            documentação em cartório. Em cada um deles, há uma certidão com
            formato específico. Aqui em cima você pode rolar para o lado e ver
            os exemplos de documentação que podem ser pedidas.
            <br />
            Além das certidões, na maioria dos tipos de pedidos, é necessário
            uma cópia autenticada do documento de identificação com foto e
            filiação do solicitante (com emissão INFERIOR a 5 anos, salvo alguns
            casos excepcionais). Após este passo a passo, você poderá ver com
            mais detalhes os documentos específicos para os seus casos.
          </p>
          <CautionBox>
            <span>
              <WarningIcon />
              <p className="caution">Atenção</p>
            </span>
            <p className="caution-body">
              Após a nossa análise da documentação, pode ser que novos
              documentos sejam solicitados. Não esqueça de ficar atento aos
              nossos canais de comunicação para saber em primeira mão todas as
              novidades sobre seu procedimento!
            </p>
          </CautionBox>
          <CardButtons>
            <Button onClick={() => setActive(2)}>Voltar</Button>
            <Button bgColor="var(--principal-40)" onClick={() => setActive(5)}>
              Próximo
              <ChevronRightIcon />
            </Button>
          </CardButtons>
        </BoxContent>
        <BoxContent active={active === 5}>
          <BoxImage>
            <Slider {...settings2}>
              <Slide>
                <img src={Requerimento} alt="Documentação" />
                <p>Exemplo de formulário de requerimento</p>
              </Slide>
              <Slide>
                <img src={Requerimento2} alt="Documentação" />
                <p>Exemplo de procuração</p>
              </Slide>
            </Slider>
          </BoxImage>
          <h4>Procuração e Requerimento</h4>
          <p className="body">
            Você precisará de uma procuração e, em alguns casos, um requerimento
            com <span>assinatura (firma) reconhecida por autenticidade</span> no
            cartório para dar início ao seu pedido.
            <br />
            Após esse passo a passo, será necessário preencher algumas
            informações para gerar a procuração e, em alguns casos, o
            requerimento, para que você possa imprimir e levar ao cartório.
            <br />
            Fique tranquilo, vamos te ajudar durante todas as etapas.
          </p>
          <CautionBox>
            <span>
              <WarningIcon />
              <p className="caution">Atenção</p>
            </span>
            <p className="caution-body">
              Após a nossa análise da documentação, pode ser que novos
              documentos sejam solicitados. Não esqueça de ficar atento aos
              nossos canais de comunicação para saber em primeira mão todas as
              novidades sobre seu procedimento!
            </p>
          </CautionBox>
          <CardButtons>
            <Button onClick={() => setActive(4)}>Voltar</Button>
            <Button bgColor="var(--principal-40)" onClick={() => setActive(6)}>
              Próximo
              <ChevronRightIcon />
            </Button>
          </CardButtons>
        </BoxContent>
        {/* <BoxContent active={ active === 6 }>
                    <BoxImage>
                        <img src={Notificacoes} alt="Notificações"/>
                    </BoxImage>
                    <h4>Notificações</h4>
                    <p className="body">Caso queira receber notificações em seu computador a respeito do Cidadania Já, as 
                    notificações ajudarão você a ficar por dentro de seus pedidos e saber quando houver atualizações!</p>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(5)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={() => setActive(7)}>
                            Próximo
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
        <BoxContent active={active === 6}>
          <BoxImage>
            <img src={Documentos} alt="Notificações" />
          </BoxImage>
          <h4>Documentos ok?</h4>
          <p className="body">
            Quando estiver com toda sua documentação reunida e de acordo com as
            especificações, você deverá enviar para o nosso escritório,
            pessoalmente ou pelo correio.
            <br />
            <br />O endereço do nosso escritório é: Praia de Botafogo 518,
            10°andar, frente. CEP: 22250-040. Rio de Janeiro/RJ
          </p>
          <CardButtons className="marginButton">
            <Button onClick={() => setActive(5)}>Voltar</Button>
            <Button
              bgColor="var(--principal-40)"
              onClick={() => handleCloseModal()}
            >
              Ir para a Central
              <ChevronRightIcon />
            </Button>
          </CardButtons>
        </BoxContent>
        {/* <BoxContent active={ active === 8 }>
                    <BoxImage>
                        <img src={Documentos} alt="Notificações"/>
                    </BoxImage>
                    <h4>Dados do português</h4>
                    <p className="body">Antes de despachar os documentos para Portugal, precisamos do assento/registro do(s) 
                    seu(s) ascendente(s) português(es).
                    <br />
                    Para isso, precisamos confirmar algumas de suas informações. Esses dados são de extrema importância 
                    para garantir a agilidade para seu pedido.
                    <br />
                    Por favor, preencha as informações para prosseguir no nosso tour.</p>
                    <form className="formGray">
                        <Input
                            type="date"
                            label="José da Silva nasceu em:"
                            name="data-nascimento"
                            onChange={handleChange('birthday')}
                            value=""
                        />
                    </form>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(7)}>Voltar</Button>
                        <Button className="midButton" onClick={ () => setActive(10) }>Preencher depois</Button>
                        <Button bgColor="var(--principal-40)" onClick={ () => { values?.birthday ? setActive(9) : alert('Preencha uma data!') }}>
                            Próximo
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
        {/* <BoxContent active={ active === 9 }>
                    <BoxImage>
                        <img src={Registro} alt="Notificações"/>
                    </BoxImage>
                    { birthYear <= 1991 ? (
                        <>
                            <h4>Registro do Português</h4>
                            <p className="body">Como <span>‘Nome do ascendente portguês’ nasceu antes de 1911</span>, o assento dele ainda não 
                            está informatizado no sistema das conservatórias de Portugal. Isso quer dizer que teremos que entrar 
                            em contato com o local onde foi registrado e pedir uma segunda via do documento para ser enviado aqui 
                            para o Brasil. Sem esse documento, sua documentação não pode seguir para Portugal. 
                            <br />
                            PPara isso, é necessário saber em qual arquivo distrital ele foi registrado para entrarmos em contato e 
                            pedir o envio de seu registro. Após localizado e confirmado pelo arquivo, o documento demora em média dois 
                            meses para estar conosco. 
                            <br />
                            <span style={{ fontWeight: 700, color: `var(--cinza-90)` }}>Confirme, por favor, os dados abaixo para que 
                            possamos entrar em contato com o arquivo distrital o mais breve possível?</span></p>
                        </>
                    ) : (
                        <>
                            <h4>Assento do Português</h4>
                            <p className="body">Como <span>‘Nome do ascendente portguês’ nasceu depois de 1911</span>, é provável que o 
                            assento esteja informatizado no sistema das conservatórias de Portugal. Para isso, é necessário 
                            saber em qual delas ele foi registrado para entrarmos em contato e pedir o número do assento. 
                            Caso todas as informações sobre o <span>‘Nome do ascendente portguês’</span> estejam corretas, esse procedimento 
                            dura cerca de 2 semanas.
                            <br />
                            <span style={{ fontWeight: 700, color: `var(--cinza-90)` }}>Confirme, por favor, os dados abaixo para que 
                            possamos entrar em contato com o arquivo distrital o mais breve possível?</span></p>
                        </>
                    )}
                    <form className="fullFormGray">
                        <Input
                            label="Nome completo"
                            placeholder="Nome completo do ascendente português"
                            name="nome-pt"
                            // onChange={handleChange('birthday')}
                            value=""
                        />
                        <Input
                            label="Nome completo do pai"
                            placeholder="Insira o nome aqui"
                            name="nome-pt"
                            // onChange={handleChange('birthday')}
                            value=""
                        />
                        <Input
                            label="Nome completo da mãe"
                            placeholder="Insira o nome aqui"
                            name="nome-pt"
                            // onChange={handleChange('birthday')}
                            value=""
                        />
                        <Select
                            label="Em que país o ascendente português nasceu?" 
                            name="teste-select"
                            onChange={handleSelectChange}
                        />
                        <div>
                            <Input
                                label="Estado"
                                placeholder="Nome do estado"
                                name="nome-pt"
                                // onChange={handleChange('birthday')}
                                value=""
                                disabled
                            />
                            <Input
                                label="Cidade"
                                placeholder="Nome da cidade"
                                name="nome-pt"
                                // onChange={handleChange('birthday')}
                                value=""
                                disabled
                            />
                        </div>
                    </form>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(8)}>Voltar</Button>
                        <Button className="midButton" onClick={ () => setActive(10) }>Preencher depois</Button>
                        <Button bgColor="var(--principal-40)" onClick={ () => setActive(11) }>
                            Próximo
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
        {/* <BoxContent active={ active === 10 }>
                    <BoxImage>
                        <img src={EnviarDepois} alt="Notificações"/>
                    </BoxImage>
                    <h4>Tudo bem, você pode nos enviar depois!</h4>
                    <p className="body">Sabemos que essas informações podem ser complicadas de se conseguir. Você pode nos 
                    informar mais tarde, mas lembre-se que é muito importante que você complete essas informações para 
                    garantir o andamento do procedimento.
                    <br />
                    Enquanto isso, que tal conferir o passo a passo de como navegar aqui na Central do Cliente, visualizar 
                    as etapas do seu processo e acompanha-lo de perto?</p>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(9)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={ () => window.location.href = '/' }>
                            Ir para a central
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
        {/* <BoxContent active={ active === 11 }>
                    <BoxImage>
                        <img src={Confirmacao} alt="Notificações"/>
                    </BoxImage>
                    <h4>Português pelo Cidadania Já</h4>
                    <p className="body">Temos ótimas notícias! O assento do seu ascendente português já está em nossas mãos.
                    <br />
                    Ou seja, você não precisa se preocupar mais com essa etapa e seu procedimento está cada vez mais perto 
                    de ser enviado logo para Portugal!
                    <br />
                    Agora, confira o passo a passo de como navegar pela nossa Central do Cliente, visualizar as etapas do 
                    seu processo e acompanhá-lo de perto.</p>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(9)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={ () => window.location.href = '/' }>
                            Ir para a central
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
      </WhiteBox>
    </PageContainer>
  );
}
