// @ts-nocheck
import { useEffect, useCallback, useState } from 'react';
import { format, parseISO } from 'date-fns';

import {
    PageHeader,
    TabContent
} from "../../styles/components";

import {
    Container,
    Content,
    Text,
    Title,
    ItemHeader,
    ItemBody,
    WhiteBox,
    Link,
    Info,
    IconInfo,
    TooltipInfo
} from "./styles";

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import IconCashback from '../../assets/icons/cashback.png';
import Sad from '../../assets/icons/sad.png';

import Header from "../../components/Header";

import { useAuth } from '../../hooks/auth';
import api from "../../services/api";

export function Pagamentos() {
    const [payments, setPayments] = useState([]);
    const [tooltip, setTooltip] = useState(false);

    const { user } = useAuth();

    function handleTooltip(item, toggle) {
        setTooltip({ [item]: toggle });
    }

    useEffect(() => {
        api
            .get('central/payments')
            .then(response => {
                setPayments(response.data);
            });
    }, []);

    useEffect(() => {
        window.Appcues.identify(user.id, user)
    }, [user]);

    return (
        <>
            <Container>
                <Header />
                <PageHeader>
                    <Content>
                        <Text>
                            <Title>Meus Pagamentos</Title>
                        </Text>
                    </Content>
                </PageHeader>
                <h3>Veja detalhes de pagamento de cada pedido</h3>
                <TabContent>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <ItemHeader>
                                        <InsertDriveFileOutlinedIcon />
                                        <p>Pedidos</p>
                                    </ItemHeader>
                                </th>
                                <th>
                                    <ItemHeader>
                                        <CalendarTodayOutlinedIcon />
                                        <p>Data da venda</p>
                                    </ItemHeader>
                                </th>
                                <th>
                                    <ItemHeader>
                                        <AttachMoneyOutlinedIcon />
                                        <p>Valor do Item</p>
                                    </ItemHeader>
                                </th>
                                <th>
                                    <ItemHeader>
                                        <img src={IconCashback} alt="Cashback"/>
                                        <p>Cashback</p>
                                    </ItemHeader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <span />
                            { payments && payments.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <p>
                                            {
                                                (item?.family_plan === "Nenhum" || !item?.family_plan) ?
                                                "Plano de Cidadania Portuguesa" :
                                                item?.family_plan
                                            }
                                        </p>
                                        <Info onClick={() => !handleTooltip(`indice`, index === tooltip.indice ? false : index)}>
                                            <p>Detalhes</p>
                                            <TooltipInfo active={tooltip.indice === index}>
                                                {item?.products.map((product, index) => (
                                                    <p key={index}>&bull; {product?.qty} {product.name}</p>
                                                ))}
                                            </TooltipInfo>
                                        </Info>
                                    </td>
                                    <td>
                                        <p><strong>{ format(parseISO(item?.created_at), "dd/MM/yyyy") }</strong></p>
                                        <p className="hour">às { format(parseISO(item?.created_at), "HH:mm") }</p>
                                    </td>
                                    <td>
                                        <p><strong>{ item?.total }</strong></p>
                                    </td>
                                    <td>
                                        { (item?.cash_back_value && item.hasCashBack) ? (
                                            <>
                                                {item?.redeem_cash_back ? (
                                                    <>
                                                        <p>
                                                            <strong>R$ { (item?.cash_back_value / 100).toLocaleString('pt-BR',
                                                                { style: 'currency', currency: 'BRL' }) }
                                                            </strong>
                                                        </p>
                                                        <Link disabled>
                                                            Cashback resgatado
                                                        </Link>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p>
                                                            <strong>{ (item?.cash_back_value / 100).toLocaleString('pt-BR',
                                                                { style: 'currency', currency: 'BRL' }) }
                                                            </strong>
                                                        </p>
                                                        {/* <Link target="_blank" href={`https://contatocidadaniaja.typeform.com/to/BX1MEglj#email=${item?.email}&idpipe=${item?.pt}`}>
                                                            Resgatar cashback
                                                        </Link> */}
                                                    </>
                                                )}
                                            </>
                                        ) :
                                            <ItemBody>
                                                <img src={Sad} alt="Sad"/>
                                                <p>Cashback indisponível</p>
                                            </ItemBody>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    { payments && payments.map((item, index) => (
                        <WhiteBox key={index}>
                            <p className="title">{ item?.family_plan }</p>
                            <ItemHeader>
                                <CalendarTodayOutlinedIcon />
                                <p>Data da venda</p>
                            </ItemHeader>
                            <p>
                                <strong>{ format(parseISO(item?.created_at), "dd/MM/yyyy") } </strong>
                                às { format(parseISO(item?.created_at), "HH:mm") }
                            </p>
                            <hr />
                            <ItemHeader>
                                <AttachMoneyOutlinedIcon />
                                <p>Valor do Item</p>
                            </ItemHeader>
                            <p><strong>{ item?.total }</strong></p>
                            <hr />
                            <ItemHeader>
                                <img src={IconCashback} alt="Cashback"/>
                                <p>Cashback</p>
                            </ItemHeader>
                            { item?.cash_back_value ? (
                                <>
                                    {item?.redeem_cash_back === 1 ? (
                                        <>
                                            <p>
                                                <strong>{ (item?.cash_back_value / 100).toLocaleString('pt-BR',
                                                    { style: 'currency', currency: 'BRL' }) }
                                                </strong>
                                            </p>
                                            <Link disabled>
                                                Cashback resgatado
                                            </Link>
                                        </>
                                    ): (
                                        <>
                                            <p>
                                                <strong>{ (item?.cash_back_value / 100).toLocaleString('pt-BR',
                                                    { style: 'currency', currency: 'BRL' }) }
                                                </strong>
                                            </p>
                                            <Link target="_blank" href={`https://contatocidadaniaja.typeform.com/to/BX1MEglj#email=${item?.email}&idpipe=${item?.pt}`}>
                                                Resgatar cashback
                                            </Link>
                                        </>
                                    )}
                                </>
                            ) :
                                <ItemBody>
                                    <img src={Sad} alt="Sad"/>
                                    <p>Cashback indisponível</p>
                                </ItemBody>
                            }
                        </WhiteBox>
                    ))}
                </TabContent>
            </Container>
        </>
    );
}
