// @ts-nocheck
import { useState, useEffect } from "react";

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxHeader,
    BoxContent,
    CardButtons,
    Button
} from "./styles";

import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { useRequirement } from '../../../hooks/requirement';

export function ModalFMD({ openModal, closeModal }) {
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    const { requirement } = useRequirement();

    function handleCloseModal(e) {
        // (e).preventDefault();
        setOpen(false);
        setTimeout(() => {
            setVisible(false);
        }, 600);
    }

    function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
    };

    useEffect(() => {
        if(openModal) {
            setVisible(true);
            setTimeout(() => {
                setOpen(true);
            }, 600);
        }
    }, [openModal]);

    console.log('REQ', requirement)

    return (
        <PageContainer open={open} visible={visible}>
            <WhiteBox>
                <CloseButton onClick={ () => { handleCloseModal(); closeModal() }}>
                    Fechar
                    <CancelIcon />
                </CloseButton>
                <BoxHeader />
                {requirement?.emission?.status === 'Aguardando pagamento' ? (
                    <>
                        <BoxContent>
                            <h4>Orçamento da certidão</h4>
                            <p className="body">A seguir, você pode conferir o valor total do orçamento dado pelo 
                            cartório para a emissão dos seus documentos.</p>
                            <div className="price">
                                <h5>Total</h5>
                                <p><span>R$</span> {(requirement.emission.value / 100).toLocaleString('pt-BR', {minimumFractionDigits: 2})}</p>
                            </div>
                            <CardButtons className="marginButton">
                                <Button bgColor="var(--principal-40)" onClick={() => { handleCloseModal(); closeModal() }}>
                                    Entendi
                                </Button>
                            </CardButtons>
                        </BoxContent>
                    </>
                ) : (
                    <>
                        {requirement.form_filled === 1 ? (
                            <BoxContent>
                                <h4>Essa emissão já está em andamento!</h4>
                                <p className="body">Seu formulário foi preenchido e já está no nosso sistema.
                                <br />
                                Abaixo, você pode editar os dados que já foram preenchidos.</p>
                                <CardButtons className="marginButton">
                                    <Button onClick={() => window.location.href=`/emissao-certidao`}>
                                        Editar dados do formulário
                                    </Button>
                                    {/* <a
                                        href={`${requirement?.proxy_path}`}
                                        target="_blank"
                                        className={!requirement?.proxy_path ? "isDisabled" : ""}
                                        rel="noreferrer"
                                    >
                                        <SaveAltIcon style={{ marginLeft: 0, marginRight: "8px" }} />
                                        Baixar documento
                                    </a> */}
                                </CardButtons>
                            </BoxContent>
                        ) : (
                            <BoxContent>
                                <h4>Tenha em mãos o seu documento</h4>
                                <p className="body">
                                    Há informações sobre a certidão que necessitam de preenchimento obrigatório e para 
                                    preenchê-las, tenha em mãos a {requirement.name}{requirement.from_who && " "}
                                    {requirement.from_who && requirement.from_who.replace("applicant", requirement?.applicant)}.
                                    <br />
                                    Caso não tenha tais informações, entre em contato conosco por meio do Atendimento 
                                    online da sua Central do Cliente.
                                    <br />
                                    Lembrando que o seu plano engloba o serviço de pedido de emissão feito por nós do 
                                    Cidadania Já junto ao Cartório. Caso queira utilizar esse serviço, basta preencher 
                                    os dados da certidão que deseja solicitar através do botão abaixo:
                                </p>
                                <CardButtons className="marginButton">
                                    <Button bgColor='var(--principal-40)' onClick={() => window.location.href=`/emissao-certidao`}>
                                        Preencha aqui seu formulário
                                    </Button>
                                </CardButtons>
                            </BoxContent>
                        )}
                    </>
                )}
            </WhiteBox>
        </PageContainer>
    );
}
