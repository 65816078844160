import styled, { css } from "styled-components";
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.5rem;

  & + div {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid var(--cinza-30);
  }

  &:last-child {
    padding-bottom: 0;
  }

  > p {
    &.title {
      font-size: 1.0625rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: var(--cinza-100);
      margin-bottom: 0.25rem;
    }
    &.description {
      font-size: 0.9375rem;
      line-height: 1.5rem;
      color: var(--cinza-90);
      margin-bottom: 1rem;
    }
  }

  > hr {
    width: 100%;
    height: 1px;
    background: var(--cinza-30);
    margin-top: 1.5rem;
    border: 0;
  }
`;

export const CardStats = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > p {
    &:first-child {
      font-size: 0.9375rem;
      line-height: 1.75rem;
      letter-spacing: 1.5px;
      font-weight: 700;
      color: var(--cinza-100);
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    &.followUp {
      font-size: 15;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--cinza-70);
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--principal-50);
    border-radius: 0.5rem;
    font-size: 17px;
    line-height: 28px;
    font-weight: 700;
    padding: 12px 0;
    border: 0;
    color: var(--cinza-100);
    width: 100%;
    outline: none;
    text-decoration: none;
  }
`;

export const CardStatsDocument = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-radius: 8px;
  padding: 4px 12px 4px 8px;
  margin-bottom: 16px;
  gap: 10px;
  background: ${({ bgColor }) => [bgColor]};
  color: ${({ color }) => [color]};

  > p {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
  }    

  > svg {
    color: ${({ iconColor }) => [iconColor]};
  }
`;

export const CardButton = styled.button`
  display: ${props => props.disabled ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.625rem 0.625rem 0.625rem 1rem;
  border: 1px solid var(--neutro-30);
  color: var(--neutro-30);
  outline: none;

  &.black {
    background: var(--cinza-80);
    border: 0;
    color: var(--white);
  }
`;