import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 100;

  @media screen and (max-width: 767px) {
    top: 78px;
    padding: 0;
  }
`;
