// @ts-nocheck
import { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { Form } from "@unform/web";
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { usePagination } from '@material-ui/lab/Pagination';
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Player, BigPlayButton } from "video-react";

import { Input, Select } from "../../../components/Form";

import {
    PageContainer,
    WhiteBox,
    CloseButton,
    BoxHeader,
    Dot,
    BoxContent,
    BoxImage,
    CautionBox,
    Wrap,
    CardButtons,
    Button,
    Slide,
    ArrowSlider,
    NotaryOffices
} from "./styles";

import WarningIcon from '@material-ui/icons/Warning';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-short-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-short-right.svg";

import Certidao from "../../../assets/boas-vindas/certidao.jpg";
import Certidao2 from "../../../assets/boas-vindas/exemplo-documentacao.png";
import Requerimento from "../../../assets/boas-vindas/requerimento.jpg";
import Requerimento2 from "../../../assets/boas-vindas/exemplo-documentacao2.png";
import Documentos from "../../../assets/boas-vindas/documentos.png";

import "video-react/dist/video-react.css";

import { useRequirement } from '../../../hooks/requirement';
import { useNotaryOffice } from '../../../hooks/notaryOffice';
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

import NotaryOffice from "./NotaryOffice";
import { IDs } from '../../../data/requirementsId';

export function ModalRequirements({ controllerModal }) {
    const { requirement, removeRequirement } = useRequirement();
    const { addNotaryOffice } = useNotaryOffice();
    const { addToast } = useToast();

    const history = useHistory();

    const [formData, setFormData] = useState({});
    const [active, setActive] = useState(1);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notaryOffices, setNotaryOffices] = useState([]);

    // selects
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);

    // pagination
    const [page, setPage] = useState(1);
    const [totPage, setTotPage] = useState(1);

    // refs
    const formRef = useRef(null);
    const vidRef = useRef(null);

    function handleCloseModal() {
        setOpen(false);
        setTimeout(() => {
            setActive(1);
            removeRequirement();
            setVisible(false);
            vidRef.current?.video.pause();
        }, 600);
    }

    function handleSelectChange(name, option) {
        const value = option ? option.value : null;
        setFormData(state => ({ ...state, [name]: value }));
    }

    // --- pagination --- inicio
    const handleChangePaginator = useCallback(async (event) => {
        await api.get(`central/notary-offices?city=${formData?.city}&district=${formData?.neighborhood}&page=${event}`)
        .then(response => {
            setNotaryOffices(response.data.data);
            setTotPage(response.data.last_page);
        });
        setPage(event);
    }, [formData]);

    const useStyles = makeStyles(theme => ({
        ul: {
            listStyle: 'none',
            padding: 0,
            margin: '24px 0 40px',
            display: 'flex',
            justifyContent: 'center'
        }
    }));

    function CustomPagination() {
        const classes = useStyles();
        const { items } = usePagination({
            count: totPage,
            page
        });

        return (
            <nav>
                <ul className={classes.ul}>
                    {items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;

                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = (
                                <MoreHorizIcon
                                    style={{
                                        fontSize: '16px',
                                        marginTop: '16px',
                                        color: 'var(--principal-50)'
                                    }}
                                />
                            );
                        } else if (type === "page") {
                            children = (
                            <IconButton
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 'bold',
                                    color: 'var(--principal-50)',
                                    backgroundColor: selected ? 'var(--principal-10)' : 'transparent',
                                    border: selected ? '1px solid var(--principal-50)' : 0,
                                    width: 48,
                                    height: 48
                                }}
                                {...item}
                            >
                                {page}
                            </IconButton>
                            );
                        } else {
                            children = (
                                <IconButton
                                    style={{
                                        color: 'var(--principal-50)',
                                        backgroundColor: item.disabled ? 'var(--cinza-30)' : 'var(--principal-40)',
                                        margin: type === 'previous' ? '0 16px 0 0' : '0 0 0 16px',
                                        width: 48,
                                        height: 48
                                    }}
                                    {...item}
                                >
                                    {type === "previous" ? <ArrowLeft /> : <ArrowRight />}
                                </IconButton>
                            );
                        }
                        return (
                            <li
                                key={index}
                                style={{ margin: "auto 0" }}
                                onClick={() => item.disabled ? '' : handleChangePaginator(page)}
                            >
                                {children}
                            </li>
                        );
                    })}
                </ul>
            </nav>
        );
    }
    // --- pagination --- fim

    const settings = {
        dots: true,
        dotsClass: 'slick-dots',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowSlider right><ArrowForwardIosIcon /></ArrowSlider>,
        prevArrow: <ArrowSlider><ArrowForwardIosIcon className="left" /></ArrowSlider>
    };

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowSlider right><ArrowForwardIosIcon /></ArrowSlider>,
        prevArrow: <ArrowSlider><ArrowForwardIosIcon className="left" /></ArrowSlider>
    };

    const handleSubmit = useCallback(async (data) => {
        try {
            setLoading(true);

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                state: Yup.string()
                .typeError('Estado é obrigatório')
                .test(
                  'state',
                  'Estado é obrigatório',
                  value =>
                    !(formData?.state === true && (value?.length <= 0 || !value)),
                ),
                city: Yup.string()
                .typeError('Cidade é obrigatória')
                .test(
                    'city',
                    'Cidade é obrigatória',
                    value =>
                    !(formData?.city === true && (value?.length <= 0 || !value)),
                ),
                neighborhood: Yup.string()
                .typeError('Bairro é obrigatório')
                .test(
                    'neighborhood',
                    'Bairro é obrigatório',
                    value =>
                    !(formData?.neighborhood === true && (value?.length <= 0 || !value)),
                ),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.get(`central/notary-offices?city=${formData?.city}&district=${formData?.neighborhood}`)
                    .then(response => {
                        setNotaryOffices(response.data.data);
                        setTotPage(response.data.last_page);
                    });

            setActive(3);
        } catch(err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            addToast({
                type: 'error',
                title: 'Erro',
                description: 'Não foi possível enviar as informações!'
            });
        } finally {
            setLoading(false);
        }
    }, [addToast, formData]);

    useEffect(() => {
        if(!!Object.keys(requirement).length && requirement?.open && controllerModal) {
            setOpen(true);
            setVisible(true);

            async function fectchData() {
                await api.get('people/conselhos/brazilian')
                        .then(response => setStates(response.data.data.map(({ id, name}) => ({
                            label: name,
                            value: id
                        }))));
                if (formData?.state) {
                    await api.get(`people/conselhos/${formData?.state}/freguesias`)
                        .then(response => setCities(response.data.map(({ id, name}) => ({
                            label: name,
                            value: id
                        }))));
                }
                if (formData?.city) {
                    await api.get(`central/notary-offices/cities/${formData?.city}/districts`)
                        .then(response => setNeighborhoods(response.data.map((item) => ({
                            label: item,
                            value: item
                        }))));
                }
            }
            fectchData();
        }
    }, [requirement, controllerModal, formData]);

    return (
        <PageContainer open={open} visible={visible} className="Olar">
            <WhiteBox>
                <CloseButton onClick={handleCloseModal}>
                    Fechar
                    <CancelIcon />
                </CloseButton>
                <BoxHeader>
                    {requirement &&
                    requirement.name !== "Procuração assinada" &&
                    requirement.name !== "Documento de identificação (RG, CNH ou passaporte)" &&
                        <>
                            <Dot active={ active === 1 } />
                            <Dot active={ active === 2 } />
                            <Dot active={ active === 3 } />
                        </>
                    }
                </BoxHeader>
                {requirement && requirement.name === "Procuração assinada" ?
                    (<>
                        {requirement.proxy_path ?
                            <BoxContent active={ active === 1 }>
                                <BoxImage className="video">
                                    <Player
                                        ref={vidRef}
                                        playsInline
                                        src={requirement.video_url}
                                        width="100%"
                                    >
                                        <BigPlayButton position="center" />
                                    </Player>
                                </BoxImage>
                                <h4>O que você quer fazer?</h4>
                                {requirement.status === 'ARRIVED_WRONG' && (
                                    <>
                                        <p className="body" dangerouslySetInnerHTML={{ __html: requirement.observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                        <br />
                                    </>
                                )}
                                <p className="body">
                                    Sua procuração já está pronta e também já foi enviada para o seu e-mail cadastrado com a gente!
                                    <br />
                                    Abaixo, você pode baixar o seu documento ou editar os dados que já foram preenchidos.
                                </p>
                                <CardButtons className="marginButton">
                                    <Button onClick={() => window.location.href=`/procuracao`}>
                                        Editar dados da procuração
                                    </Button>
                                    <a
                                        href={`${requirement.proxy_path}`}
                                        target="_blank"
                                        className={!requirement?.proxy_path ? "isDisabled" : ""}
                                        rel="noreferrer"
                                    >
                                        <SaveAltIcon style={{ marginLeft: 0, marginRight: "8px" }} />
                                        Baixar procuração
                                    </a>
                                </CardButtons>
                            </BoxContent>
                        :
                            <BoxContent active={ active === 1 }>
                                <BoxImage className="video">
                                    <Player
                                        ref={vidRef}
                                        playsInline
                                        src={requirement.video_url}
                                        width="100%"
                                    >
                                        <BigPlayButton position="center" />
                                    </Player>
                                </BoxImage>
                                <h4>{requirement.name}</h4>
                                {requirement.status === 'ARRIVED_WRONG' && (
                                    <>
                                        <p className="body" dangerouslySetInnerHTML={{ __html: requirement.observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                        <br />
                                    </>
                                )}
                                <p className="body" dangerouslySetInnerHTML={{ __html: requirement.requirement_observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                <CardButtons className="marginButton">
                                    {/* <Button bgColor="var(--principal-40)" onClick={() => { setActive(2); vidRef.current.video.pause(); }}> */}
                                    <Button bgColor="var(--principal-40)" onClick={() => window.location.href="/procuracao"}>
                                        Preencha aqui sua procuração
                                    </Button>
                                </CardButtons>
                            </BoxContent>
                        }
                    </>)
                :
                    <>
                        {requirement && IDs.find((item) => item.id === requirement.id) ?
                            <>
                                <BoxContent active={ active === 1 }>
                                    <BoxImage className="video">
                                        <Player
                                            ref={vidRef}
                                            playsInline
                                            src={requirement.video_url}
                                            width="100%"
                                        >
                                            <BigPlayButton position="center" />
                                        </Player>
                                    </BoxImage>
                                    <h4>
                                        {requirement.name}{requirement.name && " "}
                                        {requirement.specification}{requirement.specification && " "}
                                        {requirement.from_who && requirement.from_who.replace("applicant", requirement?.applicant)}
                                    </h4>
                                    {requirement.status === 'ARRIVED_WRONG' && (
                                        <>
                                            <p className="body" dangerouslySetInnerHTML={{ __html: requirement.observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                            <br />
                                        </>
                                    )}
                                    <p className="body" dangerouslySetInnerHTML={{ __html: requirement.requirement_observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                    <CardButtons className="marginButton">
                                        <Button bgColor="var(--principal-40)" onClick={() => { setActive(2); vidRef.current.video.pause(); }}>
                                            Saiba onde emitir essa certidão
                                        </Button>
                                    </CardButtons>
                                </BoxContent>
                                <BoxContent active={ active === 2 }>
                                    <h4>Onde emitir a certidão?</h4>
                                    <p className="body">
                                        As certidões são emitidas em seu cartório de registro. Ou seja, no caso 
                                        de certidão de nascimento, no cartório onde o nascimento foi registrado e, no caso de 
                                        casamento, onde o casamento foi celebrado. Se você está com dúvidas sobre qual cartório 
                                        você foi registrado, digite embaixo nos campos que poderemos te ajudar.
                                        <br /><br />
                                        Por favor, preencha os dados a seguir do <strong>local onde o registro</strong> de 
                                        nascimento/casamento de {requirement?.applicant} foi realizado
                                    </p>
                                    <Form ref={formRef} onSubmit={handleSubmit}>
                                        <Select
                                            label="Estado" 
                                            name="state"
                                            placeholder="Selecione o estado"
                                            options={states}
                                            onChange={(value) => handleSelectChange('state', value)}
                                        />
                                        <Select
                                            label="Cidade" 
                                            name="city"
                                            placeholder="Selecione a cidade"
                                            options={cities}
                                            onChange={(value) => handleSelectChange('city', value)}
                                        />
                                        <Select
                                            label="Bairro" 
                                            name="neighborhood"
                                            placeholder="Selecione o bairro"
                                            options={neighborhoods}
                                            onChange={(value) => handleSelectChange('neighborhood', value)}
                                        />
                                    </Form>
                                    <CardButtons className="marginButton">
                                        <Button onClick={() => setActive(1)}>Voltar</Button>
                                        <Button bgColor="var(--principal-40)" onClick={() => handleSubmit()}>
                                            Próximo
                                            <ChevronRightIcon />
                                        </Button>
                                    </CardButtons>
                                </BoxContent>
                                <BoxContent active={ active === 3 }>
                                    <h4>Cartórios da região</h4>
                                    <p className="body">
                                        Essa é a lista com nome e contato dos cartórios da região do nascimento/casamento. 
                                        Alguns cartórios respondem facilmente pela internet ou telefone e você talvez consiga 
                                        até fazer o pedido virtualmente.
                                    </p>
                                    <NotaryOffices>
                                        <h5>Listagem dos cartórios</h5>
                                        {notaryOffices && notaryOffices?.map((item) => (
                                            <NotaryOffice data={item} />
                                        ))}
                                        <CustomPagination />
                                    </NotaryOffices>
                                    <CardButtons>
                                        <Button onClick={() => setActive(2)}>Voltar</Button>
                                        <Button
                                            bgColor="var(--principal-40)"
                                            onClick={() => {
                                                addNotaryOffice({
                                                    state: states?.find((state) => state.value === formData.state),
                                                    city: cities?.find((city) => city.value === formData.city),
                                                    neighborhood: formData?.neighborhood
                                                });
                                                window.open(
                                                    '/impressao-cartorios',
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            <SaveAltIcon />
                                            Baixar lista de cartórios
                                        </Button>
                                    </CardButtons>
                                </BoxContent>
                            </>
                        :
                            <>
                                <BoxContent active={ active === 1 }>
                                    <BoxImage className="video">
                                        <Player
                                            ref={vidRef}
                                            playsInline
                                            src={requirement.video_url}
                                            width="100%"
                                        >
                                            <BigPlayButton position="center" />
                                        </Player>
                                    </BoxImage>
                                    <h4>
                                        {requirement.name}{requirement.name && " "}
                                        {requirement.specification}{requirement.specification && " "}
                                        {requirement.from_who && requirement.from_who.replace("applicant", requirement?.applicant)}
                                    </h4>
                                    {requirement.status === 'ARRIVED_WRONG' && (
                                        <>
                                            <p className="body" dangerouslySetInnerHTML={{ __html: requirement.observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                            <br />
                                        </>
                                    )}
                                    <p className="body" dangerouslySetInnerHTML={{ __html: requirement.requirement_observation?.replaceAll('applicant', requirement?.applicant).replaceAll('<br />\n<br />', '<hr>') }} />
                                    <CardButtons className="marginButton">
                                        <Button bgColor="var(--principal-40)" onClick={() => handleCloseModal()}>
                                            Ok, entendi!
                                        </Button>
                                    </CardButtons>
                                </BoxContent>
                            </>
                        }
                    </>
                }
                {/* <BoxContent active={ active === 2 }>
                    <BoxImage>
                        <Slider {...settings}>
                            <Slide>
                                <img src={Certidao} alt="Documentação"/>
                                <p>Exemplo de certidão de nascimento inteiro teor por cópia reprográfica</p>
                            </Slide>
                            <Slide>
                                <img src={Certidao2} alt="Documentação"/>
                                <p>Exemplo de certidão de nascimento inteiro teor digitada</p>
                            </Slide>
                        </Slider>
                    </BoxImage>
                    <h4>Documentação em cartório</h4>
                    <p className="body">Para darmos início, em cada pedido é necessária a solicitação da documentação em 
                    cartório. Em cada um deles, há uma certidão com formato específico. Aqui em cima você pode rolar para 
                    o lado e ver os exemplos de documentação que podem ser pedidas.
                    <br />
                    Além das certidões, na maioria dos tipos de pedidos, é necessário uma cópia autenticada do documento 
                    de identificação com foto e filiação do solicitante (com emissão INFERIOR a 5 anos, salvo alguns casos 
                    excepcionais). Após este passo a passo, você poderá ver com mais detalhes os documentos específicos para 
                    os seus casos.</p>
                    <CautionBox>
                        <span>
                            <WarningIcon />
                            <p className="caution">Atenção</p>
                        </span>
                        <p className="caution-body">Após a nossa análise da documentação, pode ser que novos documentos 
                        sejam solicitados. Não esqueça de ficar atento aos nossos canais de comunicação para saber em primeira 
                        mão todas as novidades sobre seu procedimento!</p>
                    </CautionBox>
                    <CardButtons>
                        <Button onClick={() => setActive(1)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={() => setActive(3)}>
                            Próximo
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent>
                <BoxContent active={ active === 3 }>
                    <BoxImage>
                        <Slider {...settings2}>
                            <Slide>
                                <img src={Requerimento} alt="Documentação"/>
                                <p>Exemplo de formulário de requerimento</p>
                            </Slide>
                            <Slide>
                                <img src={Requerimento2} alt="Documentação"/>
                                <p>Exemplo de procuração</p>
                            </Slide>
                        </Slider>
                    </BoxImage>
                    <h4>Procuração e Requerimento</h4>
                    <p className="body">Você precisará de uma procuração e, em alguns casos, um requerimento com <span>assinatura 
                    (firma) reconhecida por autenticidade</span> no cartório para dar início ao seu pedido.
                    <br />
                    Após esse passo a passo, será necessário preencher algumas informações para gerar a procuração e, em alguns 
                    casos, o requerimento, para que você possa imprimir e levar ao cartório.
                    <br />
                    Fique tranquilo, vamos te ajudar durante todas as etapas.</p>
                    <CautionBox>
                        <span>
                            <WarningIcon />
                            <p className="caution">Atenção</p>
                        </span>
                        <p className="caution-body">Após a nossa análise da documentação, pode ser que novos documentos 
                        sejam solicitados. Não esqueça de ficar atento aos nossos canais de comunicação para saber em primeira 
                        mão todas as novidades sobre seu procedimento!</p>
                    </CautionBox>
                    <CardButtons>
                        <Button onClick={() => setActive(2)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={() => setActive(4)}>
                            Próximo
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent>
                <BoxContent active={ active === 4 }>
                    <BoxImage>
                        <img src={Documentos} alt="Notificações"/>
                    </BoxImage>
                    <h4>Documentos ok?</h4>
                    <p className="body">Quando estiver com toda sua documentação reunida e de acordo com as especificações, 
                    você deverá enviar para o nosso escritório, pessoalmente ou pelo correio.</p>
                    <CardButtons className="marginButton">
                        <Button onClick={() => setActive(3)}>Voltar</Button>
                        <Button bgColor="var(--principal-40)" onClick={() => handleCloseModal()}>
                            Ir para a Central
                            <ChevronRightIcon />
                        </Button>
                    </CardButtons>
                </BoxContent> */}
            </WhiteBox>
        </PageContainer>
    );
}
