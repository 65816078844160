// @ts-nocheck
import { useState, useCallback } from "react";
import { format, differenceInDays, parseISO, addDays } from "date-fns";

import {
  CardService,
  CardStats,
  Historic,
  Prediction,
  Contact,
  CautionBox,
  StatsInfo,
  Delay,
  Bars,
  Bar,
  Button,
  ButtonBlock,
} from "./styles";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

import { ReactComponent as Chat } from "../../../assets/icons/chat.svg";
import { ReactComponent as RedChat } from "../../../assets/icons/red-chat.svg";
import { ReactComponent as ExternalLink } from "../../../assets/icons/external_link.svg";
import { ReactComponent as AlertInfo } from "../../../assets/icons/alert-info.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/historic/chevron_down.svg";

import { Input } from "../InputCard";
import ComponentHistoric from "./Historic";

import { useRequirement } from "../../../hooks/requirement";
import { useToast } from "../../../hooks/toast";
import api from "services/api";

export default function CardFollowUp({ data, action }) {
  const step = [];
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(true);

  const { addRequirement } = useRequirement();
  const { addToast } = useToast();

  const firstMonth = format(new Date(data?.initial_prediction_end_date), "MM");
  const firstYear = format(new Date(data?.initial_prediction_end_date), "yyyy");

  const secondMonth = format(new Date(data?.final_prediction_end_date), "MM");
  const secondYear = format(new Date(data?.final_prediction_end_date), "yyyy");

  const countDaysPostCode = differenceInDays(
    new Date(),
    new Date(data?.post_date)
  );
  const countDelayOnConclusion =
    differenceInDays(new Date(), new Date(data?.final_prediction_end_date)) > 0;

  let i;
  for (i = 1; i <= data?.total_steps; i++) {
    if (
      i <= data?.current_step &&
      !data?.blocked_step?.includes(i) &&
      !countDelayOnConclusion
    ) {
      step.push({ color: "var(--principal-50)" });
    } else if (data?.blocked_step?.includes(i)) {
      step.push({ color: "var(--erro-50)" });
    } else {
      if (!countDelayOnConclusion) {
        step.push({ color: "var(--cinza-30)" });
      }
    }
    if (i <= data?.current_step && countDelayOnConclusion) {
      step.push({ color: "var(--atencao-50)" });
    }
  }

  const handleMonth = useCallback((month) => {
    let currentMonth;
    switch (month) {
      case "01":
        currentMonth = "Janeiro";
        break;
      case "02":
        currentMonth = "Fevereiro";
        break;
      case "03":
        currentMonth = "Março";
        break;
      case "04":
        currentMonth = "Abril";
        break;
      case "05":
        currentMonth = "Maio";
        break;
      case "06":
        currentMonth = "Junho";
        break;
      case "07":
        currentMonth = "Julho";
        break;
      case "08":
        currentMonth = "Agosto";
        break;
      case "09":
        currentMonth = "Setembro";
        break;
      case "10":
        currentMonth = "Outubro";
        break;
      case "11":
        currentMonth = "Novembro";
        break;
      case "12":
        currentMonth = "Dezembro";
        break;
      default:
        break;
    }
    return currentMonth;
  }, []);

  let heightComments;
  switch (data?.comments?.length) {
    case 1:
      heightComments = "150px";
      break;
    case 2:
      heightComments = "300px";
      break;
    case 3:
      heightComments = "400px";
      break;
    default:
      break;
  }

  const handleSeeMore = useCallback(() => {
    setSeeMore(!seeMore);
  }, [seeMore]);

  async function handleContact() {
    try {
      setLoading(true);

      await api.post("central/request-new-sale-contact", {
        process_id: data?.id,
      });

      addToast({
        type: "success",
        title: "Sucesso",
        description: "Sua solicitação de contato foi enviada!",
      });
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro",
        description: "Não foi possível concluir a sua solicitação de contato!",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <CardStats key={data?.id} style={{ margin: 0 }}>
        <p style={{ margin: 0 }}>como está o meu pedido?</p>
        {step.length > 0 && data?.step?.name !== "requirement" && (
          <Bars>
            {step.length > 0 &&
              step.map((item) => (
                <Bar style={{ background: `${item.color}` }} />
              ))}
            {countDelayOnConclusion && (
              <Bar style={{ background: "var(--cinza-30)" }} />
            )}
          </Bars>
        )}
        <Prediction>
          <CalendarTodayOutlinedIcon
            style={
              data?.step?.name === "requirement" || countDelayOnConclusion
                ? { color: "var(--atencao-50)" }
                : { color: "var(--principal-50)" }
            }
          />
          <div>
            <p>Previsão para conclusão:</p>
            <p>
              <strong>
                {data?.step?.name === "requirement"
                  ? "Indeterminada"
                  : `Entre ${handleMonth(
                      firstMonth
                    )} de ${firstYear} e ${handleMonth(
                      secondMonth
                    )} de ${secondYear}`}
              </strong>
            </p>
          </div>
        </Prediction>
        {data?.post_code && data?.step?.name === "requirement" && (
          <>
            <CautionBox>
              <span>
                <WarningIcon />
                <p className="caution">Atenção</p>
              </span>
              <p className="caution-body">
                Devido à exigência, não é possível fornecer uma estimativa de
                conclusão
              </p>
            </CautionBox>
            {data &&
            !data.requirements.find((item) => item.show_to_client === true) ? (
              <div className="blocks">
                <hr />
                <StatsInfo
                  color="var(--erro-50)"
                  iconColor="var(--erro-50)"
                  bgColor="var(--erro-10)"
                >
                  <WarningIcon />
                  <p>Exigência identificada</p>
                </StatsInfo>
                <p>
                  Nossa equipe identificou um obstáculo e por isso entraremos em
                  contato para buscar a melhor resolução. Se preferir, pode
                  adiantar o contato abaixo.
                </p>
              </div>
            ) : (
              <>
                {data &&
                  data?.requirements?.map((requirement) => (
                    <div className="blocks">
                      <hr />
                      {requirement?.solution?.category?.name !==
                      "WAITING_ANOTHER_PROCESS" ? (
                        <StatsInfo
                          color="var(--erro-50)"
                          iconColor="var(--erro-50)"
                          bgColor="var(--erro-10)"
                        >
                          <WarningIcon />
                          <p>Exigência identificada</p>
                        </StatsInfo>
                      ) : (
                        <StatsInfo
                          color="var(--atencao-50)"
                          iconColor="var(--atencao-50)"
                          bgColor="var(--atencao-10)"
                        >
                          <WarningIcon />
                          <p>Aguardando procedimento</p>
                        </StatsInfo>
                      )}
                      <strong>{requirement?.reason_title}</strong>
                      <p>{requirement?.reason_description}</p>
                      {requirement?.solution?.category?.name ===
                        "INFO_DOCUMENT" &&
                        requirement?.document &&
                        requirement?.document?.name ===
                          "Procuração assinada" && (
                          <ButtonBlock
                            onClick={() => {
                              addRequirement({
                                ...requirement?.document,
                                open: true,
                                proxy_form_path: data?.proxy_form_path,
                                proxy_path: data?.proxy_path,
                              });
                              action();
                            }}
                          >
                            {data?.proxy_path ? (
                              <span>Edite e baixe sua procuração aqui</span>
                            ) : (
                              <span className="edit">
                                Preencha sua procuração aqui
                              </span>
                            )}
                            <ChevronRightOutlinedIcon />
                          </ButtonBlock>
                        )}
                      {requirement.solution.category.name === "NEW_SALE" && (
                        <ButtonBlock
                          loading={loading}
                          onClick={() => handleContact()}
                        >
                          {loading ? (
                            <RotateLeftIcon className="icon-spin" />
                          ) : (
                            <>
                              Solicitar contato do consultor
                              <ChevronRightOutlinedIcon />
                            </>
                          )}
                        </ButtonBlock>
                      )}
                      {requirement?.solution?.category?.name ===
                        "INFO_DOCUMENT" &&
                        requirement?.document &&
                        requirement?.document?.name !==
                          "Procuração assinada" && (
                          <ButtonBlock
                            onClick={() => {
                              addRequirement({
                                ...requirement?.document,
                                open: true,
                                proxy_form_path: data?.proxy_form_path,
                                proxy_path: data?.proxy_path,
                              });
                              action();
                            }}
                          >
                            Saiba sobre o formato e como emitir
                            <ChevronRightOutlinedIcon />
                          </ButtonBlock>
                        )}
                    </div>
                  ))}
              </>
            )}
          </>
        )}
        {countDelayOnConclusion && (
          <div className="blocks" style={{ marginTop: "16px" }}>
            {data?.access_password ? (
              <p>
                Infelizmente a Conservatória está levando mais tempo que o
                esperado para concluir o seu pedido. Isso acontece, porque não
                temos o controle de todas as etapas em Portugal e fazemos nossas
                estimativas baseadas no histórico de nossos clientes.
                <br />
                Mas não se preocupe, assim que a Conservatória tiver
                atualizações sobre o seu pedido, nós iremos te avisar. Caso seu
                pedido já tenha a senha de acompanhamento, pode utilizá-la para
                acompanhar o andamento em Portugal
              </p>
            ) : (
              <p>
                Infelizmente a Conservatória está levando mais tempo que o
                esperado para concluir o seu pedido. Isso acontece, porque não
                temos o controle de todas as etapas em Portugal e fazemos nossas
                estimativas baseadas no histórico de nossos clientes.
                <br />
                Mas não se preocupe, assim que a Conservatória tiver
                atualizações sobre o seu pedido, nós iremos te avisar.
              </p>
            )}
          </div>
        )}
      </CardStats>
      {data?.step?.name === "requirement" && (
        <CardService>
          <p>Quer saber mais?</p>
          <Contact>
            <a
              style={{ cursor: "pointer" }}
              href="/registro-de-atividades"
              rel="noreferrer"
            >
              <AlertInfo />
              <p>Acompanhe o histórico do caso</p>
            </a>
            <a
              style={{ cursor: "pointer" }}
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5521990257203&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Meu%20pedido%20est%C3%A1%20com%20uma%20exig%C3%AAncia%20identificada%2C%20poderia%20me%20ajudar%3F"
              rel="noreferrer"
            >
              <RedChat />
              <p>Fale com um especialista agora</p>
            </a>
          </Contact>
        </CardService>
      )}
      {!data?.access_password &&
        countDaysPostCode > 0 &&
        countDaysPostCode <= 90 && (
          <>
            <hr
              style={{
                height: "1px",
                background: "var(--cinza-30)",
                border: "0",
                margin: "16px 0",
              }}
            />
            <CardStats>
              <p style={{ marginBottom: "4px" }}>Rastreamento</p>
              <p className="followUp">
                Acompanhe a chegada do seu pedido na conservatória em Portugal.
              </p>
              <Input
                id={`user-${data?.id}-cod`}
                label="Clique para copiar o código de rastreio:"
                defaultValue={data?.post_code}
              />
              <Button
                bgColor="var(--principal-40)"
                target="_blank"
                href="https://www.dhl.com/br-pt/home/tracking.html"
                rel="no-referrer"
              >
                Acompanhar rastreamento
                <ExternalLink />
              </Button>
            </CardStats>
            {data?.type !== "Transcrição de Casamento" && (
              <>
                <hr
                  style={{
                    height: "1px",
                    background: "var(--cinza-30)",
                    border: "0",
                    margin: "32px 0 16px",
                  }}
                />
                <CardStats>
                  <p style={{ marginBottom: "4px" }}>senha de acesso</p>
                  <p className="followUp">
                    Sua senha ainda não está disponível
                  </p>
                </CardStats>
              </>
            )}
          </>
        )}
      {data?.access_password && (
        <>
          <hr
            style={{
              height: "1px",
              background: "var(--cinza-30)",
              border: "0",
              margin: "32px 0 16px",
            }}
          />
          <CardStats>
            <p style={{ marginBottom: "4px" }}>senha de acesso</p>
            <p className="followUp">
              Para verificar o andamento da sua cidadania, copie a senha abaixo
              e insira no campo que solicita sua senha de acesso, clicando no
              botão abaixo.
            </p>
            <Input
              id={`user-${data?.id}-password`}
              label="Clique para copiar a senha de acesso:"
              defaultValue={data?.access_password}
            />
            <Button
              bgColor="var(--principal-40)"
              target="_blank"
              href="https://meu.registo.justica.gov.pt/Pedidos/Consultar-estado-do-processo-de-nacionalidade"
              rel="no-referrer"
            >
              Usar senha de acesso
              <ExternalLink />
            </Button>
          </CardStats>
        </>
      )}
      {data?.comments?.length > 0 && (
        <>
          <hr
            style={{
              height: "1px",
              background: "var(--cinza-30)",
              border: "0",
              margin: "32px 0 16px",
            }}
          />
          <CardStats active={seeMore}>
            <p style={{ margin: 0 }}>Histórico</p>
            <Historic
              height={heightComments}
              className={`${seeMore ? "expand" : ""}`}
            >
              {data?.comments &&
                data?.comments
                  ?.slice(0, !seeMore ? 4 : data?.comments.length)
                  ?.map((item) => <ComponentHistoric data={item} />)}
            </Historic>
            {data?.comments?.length > 4 && (
              <button type="button" onClick={() => handleSeeMore()}>
                {seeMore
                  ? "Ver menos itens do histórico"
                  : "Ver mais itens do histórico"}
                <Chevron className={seeMore ? "rotate" : ""} />
              </button>
            )}
          </CardStats>
        </>
      )}
    </>
  );
}
