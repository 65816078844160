// @ts-nocheck
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { addDays, differenceInDays, format } from "date-fns";
import { useCallback } from "react";
import {
  CardPercent,
  CardStats,
  Delay,
  LinePercent,
  Prediction,
  StatsDispatch,
} from "./styles";

export default function CardDispatch({ data }) {
  const countDaysDispatch = differenceInDays(
    new Date(data?.post_prediction_date),
    new Date(data?.ready_to_post_date)
  );

  const totalPerProcess = parseInt(countDaysDispatch / 2);

  let startSendDay = new Date(data?.ready_to_post_date);
  let endSendDay = addDays(new Date(data?.ready_to_post_date), totalPerProcess);

  let startDispatchDay = endSendDay;
  let endDispatchDay = new Date(data?.post_prediction_date);

  let sendPercent = 0;
  let dispatchPercent = 0;

  if (new Date() - startSendDay > 0 && data?.step?.name !== "blocked_post") {
    sendPercent = parseInt(
      (Math.abs(new Date() - startSendDay) / (endSendDay - startSendDay)) * 100
    );

    if (sendPercent > 100 || !sendPercent) {
      sendPercent = 100;
    }

    if (sendPercent >= 100) {
      dispatchPercent = parseInt(
        (Math.abs(new Date() - startDispatchDay) /
          (endDispatchDay - startDispatchDay)) *
          100
      );
    }
  }

  const handleColor = useCallback((info) => {
    if (info > 100) {
      return "var(--atencao-50)";
    } else if (info > 0 && info <= 100) {
      return "var(--principal-50)";
    } else {
      return "var(--cinza-50)";
    }
  }, []);

  return (
    <>
      <CardStats style={{ marginBottom: 0 }}>
        <p>despacho</p>
        <Prediction>
          <CalendarTodayOutlinedIcon
            style={
              data?.step?.name === "blocked_post"
                ? { color: "var(--erro-50)" }
                : { color: "var(--principal-50)" }
            }
          />
          <p>
            Previsão de envio para portugal:
            <strong>
              {data?.step?.name === "blocked_post"
                ? " Indefinido"
                : format(new Date(data?.post_prediction_date), " dd/MM/yyyy")}
            </strong>
          </p>
        </Prediction>
      </CardStats>
      <StatsDispatch>
        <p>Preparação para envio</p>
        <p className="analysisText">
          Juntar todos os documentos; preenchimento e anexação do requerimento;
          pagamento de taxa da conservatória.
        </p>
        <CardPercent color={handleColor(sendPercent)}>
          <span className="bar">
            <LinePercent
              size={sendPercent > 100 ? 100 : sendPercent}
              color={handleColor(sendPercent)}
            />
          </span>
          <span className="percent">
            {sendPercent > 100 ? 80 : sendPercent}%
          </span>
        </CardPercent>
      </StatsDispatch>
      <StatsDispatch>
        <p>Despacho dos documentos</p>
        <p className="analysisText">
          Preparação do malote; transferência para operador logístico; despacho
          do malote.
        </p>
        <CardPercent color={handleColor(dispatchPercent)}>
          <span className="bar">
            <LinePercent
              size={dispatchPercent > 100 ? 80 : dispatchPercent}
              color={handleColor(dispatchPercent)}
            />
          </span>
          <span className="percent">
            {dispatchPercent > 100 ? 80 : dispatchPercent}%
          </span>
        </CardPercent>
        {data &&
          !!data?.reanalysis_date &&
          new Date(endDispatchDay) - new Date() < 0 && (
            <Delay color="var(--atencao-50)">
              <div>
                <AccessTimeIcon />
                <p>O despacho atrasou um pouco</p>
              </div>
              <p>
                O operador logístico apresentou um novo prazo para coleta do seu
                malote.
              </p>
            </Delay>
          )}
      </StatsDispatch>
    </>
  );
}
