// @ts-nocheck
import { useRef, useState, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

import { Container, Content } from "./styles";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { Input, Checkbox } from "../../components/Form";
import Button from "../../components/Button";

import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo-white.png";

export function Login() {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [revealPassword, setRevealPassword] = useState(false);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const email = data?.email.replace(/ /g, "");
        const password = data?.password.replace(/ /g, "");

        const schema = Yup.object().shape({
          // email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email,
          password,
        });

        history.pushState("/");
      } catch (err) {
        const validationErrors = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          await formRef.current?.setErrors(validationErrors);
        }

        if (err.response?.status === 400) {
          addToast({
            type: "error",
            title: "Erro na autenticação",
            description: "Usuário ou senha inválidos",
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [signIn]
  );

  return (
    <Container>
      <img
        className="mobile"
        src={logoWhite}
        alt="Cidadania Já"
        style={{ maxWidth: "184px" }}
      />
      <Content>
        <img
          className="desktop"
          src={logo}
          alt="Cidadania Já"
          style={{ maxWidth: "184px" }}
        />
        <h3>Central do Cliente</h3>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            type="text"
            label="E-mail"
            icon={<MailOutlineIcon />}
            name="email"
            placeholder="seuemail@email.com"
            onChange={handleChange}
          />
          <Input
            type={revealPassword ? "text" : "password"}
            label="Senha"
            icon={<LockOpenIcon />}
            name="password"
            placeholder="Sua senha"
            onChange={handleChange}
            iconPassword
            changePassword={() => setRevealPassword(!revealPassword)}
            visible={revealPassword}
          />
          <Checkbox label="Permanecer logado" />
          <Button
            type="submit"
            btType="var(--principal-40)"
            color="var(--cinza-100)"
            label="Entrar"
            margin="1.5rem 0 0 0"
            full
            padding="1rem 0"
            loading={loading}
          />
          <span className="resetPassword">
            <p>Esqueceu a senha?</p>
            <Link to="/recuperar-senha">Clique aqui</Link>
          </span>
        </Form>
      </Content>
    </Container>
  );
}
