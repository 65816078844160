// @ts-nocheck
import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "@unform/core";

import { Container, Label, Content } from "./styles"

export function MaskInput({ name, label, icon, required, disabled, ...rest }) {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [realValue, setRealValue] = useState(defaultValue || "");

    let mask = "";
    let formatChars = { "9": "[0-9]", a: "[A-Z, a-z]", "*": "[A-Z, a-z, 0-9]" };

    switch (rest.type) {
        case "telefone":
          const rawTelefone = realValue.replace(/[^\d]+/g, "");
          if (rawTelefone.length > 10) {
            mask = "(99) 99999-9999";
          } else {
            mask = "(99) 9999-9999?";
          }
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        case "cpf":
          mask = "999.999.999-99";
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        case "cnpj":
          mask = "99.999.999/9999-99";
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        case "cep":
          mask = "99999-999";
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        case "cvc":
          mask = "999";
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        case "card":
          mask = "9999.9999.9999.9999";
          formatChars = { "9": "[0-9]", "?": "[0-9]" };
          break;
        default:
            break;
    }

    function handleMask(event) {
        const { value } = event.target;
        if (rest.onChange !== undefined) {
            event.target.value = value;
            rest.onChange(event);
        }
        return setRealValue(value);
    }

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
            setValue(ref, value) {
                ref.setInputValue(value);
            },
            clearValue(ref) {
                ref.setInputValue("");
            },
        });
    }, [fieldName, registerField]);

    return (
        <Container className="customContainerInputMask">
            {label &&
                <Label disabled={disabled} htmlFor={fieldName}>{label}</Label>
            }
            <Content icon={icon} className="customInputMask" isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
                {icon &&
                    <span>{icon}</span>
                }
                <ReactInputMask
                    id={fieldName}
                    name={fieldName}
                    maskChar=""
                    mask={mask}
                    formatChars={formatChars}
                    onChange={(e) => handleMask(e)}
                    value={realValue}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}
                />
            </Content>
            {error && <span className="error">{error}</span>}
        </Container>
    );
}