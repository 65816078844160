import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: auto;

  @media screen and (max-width: 950px) {
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  padding: 40px 0 99px;

  p {
    &.infos {
      font-size: 12px;
      line-height: 20px;
      color: var(--cinza-80);
      letter-spacing: 0.2px;
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 1280px) {
    padding: 40px 40px 99px;
  }

  @media screen and (max-width: 950px) {
    padding: 20px 16px 32px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;

  img {
    width: max-content;
  }

  p {
    font-size: 19px;
    line-height: 28px;
    color: var(--cinza-80);
  }

  @media screen and (max-width: 950px) {
    gap: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + div {
    margin-top: 40px;
  }

  > p {
    font-size: 12px;
    line-height: 20px;
    color: var(--cinza-80);
    letter-spacing: 0.2px;

    &.title {
      font-size: 15px;
      line-height: 24px;
      font-weight: 700;
      color: var(--principal-50);
    }

    &.service {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 950px) {
    gap: 20px;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;

  & + div {
    padding: 16px 0;
    border-top: 1px solid var(--cinza-30);
  }

  &:last-child {
    padding-bottom: 0;
  }

  > p {
    font-size: 12px;
    line-height: 20px;
    color: var(--cinza-80);
    letter-spacing: 0.2px;

    &.title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
      color: var(--cinza-100);

      div {
        width: 13px;
        height: 13px;
        border: 1px solid var(--cinza-80);
      }
    }
  }
`;
