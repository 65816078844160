import styled from "styled-components";

import Background from "../../assets/login/background.png";

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(${Background}) no-repeat right bottom, var(--gradiente-verde-claro);

  img {
    &.desktop {
      margin-bottom: 5rem;
    }

    &.mobile {
      position: absolute;
      top: 50px;
      left: 16px;
    }
  }
  
  @media (max-width: 1280px) {
    background-size: cover;
  }

  @media screen and (min-width: 951px) {
    img.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 950px) {
    justify-content: center;
    background-position: top;
    background-size: contain;

    img.desktop {
      display: none;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  width: 40%;
  height: 100%;
  /* position: absolute; */
  /* max-width: 24.5rem; */
  /* box-shadow: var(--shadow-baixo-grande);
  border-radius: 0.5rem; */
  /* padding: 1.5rem 1rem 2rem; */

  p.description {
    display: flex;
    text-align: center;
    font-size: var(--font-17);
    line-height: 1.75rem;
    width: 100%;
    max-width: 25.75rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  form {
    width: 100%;
    max-width: 25.75rem;

    input::placeholder{
      font-size: 1.063rem;
    }

    span.resetPassword {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.25rem;
      font-size: var(--font-17);
      line-height: 1.5rem;
      color: var(--principal-50);
      margin-top: 1.5rem;

      a {
        text-decoration: none;
        color: var(--principal-50);
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 950px) {
    width: 95%;
    height: auto;
    position: absolute;
    margin: 0 auto;
    padding: 24px 16px 32px;
    border-radius: 8px;
    box-shadow: var(--shadow-baixo-grande);
    top: 180px;

    form {
      p.description {
        font-size: 17px;
        line-height: 28px;
        max-width: 296px;
      }

      span.resetPassword {
        flex-direction: column;
        font-size: 17px;
        line-height: 28px;
      }
    }

    button {
      font-size: 21px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 767px) {
    form {
      max-width: 100%;
    }
  }
`;

export const WhiteBox = styled.div`
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--white);
  width: 100%;
  max-width: 600px;
  max-height: 95%;
  padding: 1rem 1.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-baixo-grande);
  display: ${({ open }) => open ? "flex" : "none"};
  opacity: ${({ visible }) => visible ? "1" : "0"};

  @media screen and (max-width: 950px) {
    width: 95%;
  }
`;

export const BoxContent = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  /* &::-webkit-scrollbar {
    width: 0;
  } */

  h4 {
    font-size: var(--font-21);
    line-height: 1.75rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: var(--cinza-100);
  }

  p {
    &.body {
      font-size: var(--font-17);
      line-height: 1.75rem;
      color: var(--cinza-90);
      text-align: center;

      span {
        color: var(--principal-50);
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 950px) {
    h4 {
      font-size: 21px;
      line-height: 28px;
    }

    p {
      &.body {
        font-size: 17px;
        line-height: 28px;
        margin-top: 4px;
      }
    }
  }
`;

export const BoxImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;

  &.video {
    height: 17.5rem;
    background: var(--cinza-20);

    img {
      position: absolute;
      cursor: pointer;
    }
  }
`;

export const CloseButton = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  font-size: var(--font-15);
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--principal-50);
  top: 1rem;
  right: 1.5rem;
  z-index: 10;
  cursor: pointer;

  svg {
    color: var(--principal-40);
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  background: transparent;
  width: 100%;
  padding-bottom: 1px;

  &.marginButton {
    margin-top: 2rem;
  }
`;

export const ButtonCard = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor ? [bgColor] : "transparent"};
  border-radius: 0.5rem;
  font-size: var(--font-17);
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 0;
  border: ${({ bgColor }) => bgColor ? "none" : "1px solid var(--neutro-30);"};
  color: ${({ bgColor }) => bgColor ? "var(--cinza-100)" : "var(--neutro-30)"};
  width: 100%;
  outline: none;

  &.midButton {
    border: none;
  }

  svg {
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    line-height: 28px;
  }
`;
